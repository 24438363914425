// import { useCallback } from "react";
// import { AxiosContext } from '../App';
import axiosInstance from '../axiosConfig';
import { 
  address, 
  createSolanaRpc, 
  // mainnet 
} from '@solana/web3.js';
import {
  Endian,
  getBase58Encoder,
} from "@solana/codecs";
import { getProgramDerivedAddress } from '@solana/addresses'; // getAddressEncoder, 
import { Buffer } from 'buffer';
import bs58 from 'bs58';

const rpc = createSolanaRpc('https://solana-rpc.publicnode.com');
// const axiosInstance = useContext(AxiosContext);

function unpackMetadataAccount(data) {
  if (data[0] !== 4) {
    throw new Error("Invalid metadata account data");
  }

  let i = 1;
  
  // Extract source account (update authority)
  const sourceAccount = bs58.encode(data.slice(i, i + 32));
  i += 32;
  
  // Extract mint account
  const mintAccount = bs58.encode(data.slice(i, i + 32));
  i += 32;
  
  // Extract name
  const nameLen = data.readUInt32LE(i);
  i += 4;
  const name = Buffer.from(data.slice(i, i + nameLen)).toString().replace(/\0/g, '');
  i += nameLen;
  
  // Extract symbol
  const symbolLen = data.readUInt32LE(i);
  i += 4;
  const symbol = Buffer.from(data.slice(i, i + symbolLen)).toString().replace(/\0/g, '');
  i += symbolLen;
  
  // Extract URI
  const uriLen = data.readUInt32LE(i);
  i += 4;
  const uri = Buffer.from(data.slice(i, i + uriLen)).toString().replace(/\0/g, '');
  i += uriLen;
  
  // Extract fee
  const fee = data.readInt16LE(i);
  i += 2;
  
  // Check for creators
  const hasCreator = data[i];
  i += 1;
  
  let creators = [];
  let verified = [];
  let share = [];
  
  if (hasCreator) {
    const creatorLen = data.readUInt32LE(i);
    i += 4;
    
    for (let j = 0; j < creatorLen; j++) {
      const creator = bs58.encode(data.slice(i, i + 32));
      creators.push(creator);
      i += 32;
      
      verified.push(!!data[i]);
      i += 1;
      
      share.push(data[i]);
      i += 1;
    }
  }
  
  const primarySaleHappened = !!data[i];
  i += 1;
  
  const isMutable = !!data[i];
  
  const metadata = {
    "update_authority": sourceAccount,
    "mint": mintAccount,
    "data": {
      "name": name,
      "symbol": symbol,
      "uri": uri,
      "seller_fee_basis_points": fee,
      "creators": creators,
      "verified": verified,
      "share": share,
    },
    "primary_sale_happened": primarySaleHappened,
    "is_mutable": isMutable,
  };
  
  return metadata;
}

async function fetchExternalMetadata(uri) {
  try {
    const response = await fetch(uri);
    if (!response.ok) {
      throw new Error(`Failed to fetch external metadata: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching external metadata:', error);
    return null;
  }
}

export const fetchWalletAccountInfo = async (walletAddress) => {
  if (!walletAddress) return;

  // setLoading(true);

  try {
    const options = {
      // options here
      encoding: 'jsonParsed'
    }
    
    const getAccountInfoResponse = await axiosInstance.post('/v2/get_account_info', {
      pubkey: walletAddress
    }).catch(err => {
      console.error(`Error getting account info`, err.response?.data || err.message);
    })

    if (getAccountInfoResponse?.status === 200) {
      console.log(getAccountInfoResponse.data)
      // setWalletAccountInfo(getAccountInfoResponse.data)
      return getAccountInfoResponse.data;
    };

    // Get account info
    const accountInfo = await rpc.getAccountInfo(walletAddress, options).send();

    // Extract the base64 data
    // if (accountInfo && accountInfo.value && accountInfo.value.data) {
    //   const [base64Data, encoding] = accountInfo.value.data;

    //   if (encoding === 'base64') {
    //     // Decode the base64 string to a Buffer
    //     const decodedData = Buffer.from(base64Data, 'base64');
    //     const jsonMetadata = unpackMetadataAccount(decodedData)
    //     console.log(jsonMetadata)
    //     return jsonMetadata;
    //   }
    // }

    // return accountInfo
    // Decode Base64 data
    // const buffer = Buffer.from(accountInfo.value.data[0], 'base64');
    
    console.log(accountInfo)
    
    if (accountInfo) {
      const saveAccountInfoResponse = await axiosInstance.post('/v2/save_account_info', {
        pubkey: walletAddress,  // Assuming you have the pubkey from the request
        slot: Number(accountInfo.context.slot),  
        data: JSON.stringify(accountInfo.value.data),  
        executable: accountInfo.value.executable,  
        lamports: Number(accountInfo.value.lamports),  
        owner: accountInfo.value.owner,  
        rent_epoch: Number(accountInfo.value.rentEpoch),  
        space: Number(accountInfo.value.space)  
      }).catch(err => {
        console.error(`Error saving account info`, err.response?.data || err.message);
      })
    }

    // setWalletAccountInfo(accountInfo.value)
    return accountInfo.value;
  
  } catch (error) {
    console.log(error)
  } finally {
    // setLoading(false);
  }
};

const fetchAccountInfo = async (address) => {
  // setLoading(true);
  try {
    const options = {
      // options here
      encoding: 'jsonParsed'
    }

    // Get account info
    const accountInfo = await rpc.getAccountInfo(address, options).send();

    // Extract the base64 data
    if (accountInfo && accountInfo.value && accountInfo.value.data) {
      const [base64Data, encoding] = accountInfo.value.data;

      if (encoding === 'base64') {
        // Decode the base64 string to a Buffer
        const decodedData = Buffer.from(base64Data, 'base64');
        const jsonMetadata = unpackMetadataAccount(decodedData)
        console.log(jsonMetadata)
        return jsonMetadata;
      }
    }
    // return accountInfo
    // Decode Base64 data
    // const buffer = Buffer.from(accountInfo.value.data[0], 'base64');

    return undefined;
  
  } catch (error) {
    console.log(error)
  } finally {
    // setLoading(false);
  }
};

export const fetchTokenMetadata = async (mintAddress) => {
  try {
    const response = await fetch(`/api/get_token_metadata/${mintAddress}`);
    const result = await response.json();
    if (result.success) {
      return result.data.metadata;
    } else {
      console.warn(`API error fetching token metadata: ${result.error}`);
    }
      // } else {
      //   setError(result.error);
      // }
    const PROGRAM_ID = address('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');
    const MD_CONFIG_SEED = "metadata";
    const base58Encoder = getBase58Encoder({ endian: Endian.Big });

    const [configPda] = await getProgramDerivedAddress({
      programAddress: PROGRAM_ID,
      seeds: [
        MD_CONFIG_SEED,
        base58Encoder.encode('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
        base58Encoder.encode(mintAddress),
      ]
    });
    console.log(configPda)
    const accountInfo = await fetchAccountInfo(configPda)
    if (!accountInfo) {
      return null;
    }

    // Get external metadata if URI exists
    let externalMetadata = null;
    if (accountInfo.data.uri) {
      externalMetadata = await fetchExternalMetadata(accountInfo.data.uri);
    }

    const metadata = {
      ...accountInfo,
      external_metadata: externalMetadata,
    };

    const postResponse = await axiosInstance.post('/v2/save_token_metadata', {
      mint_address: mintAddress,
      token_metadata: metadata,
    }).catch(err => {
      console.error(`Error saving metadata:`, err.response?.data || err.message);
    });
    
    if (postResponse && postResponse.status !== 204) {
      console.warn("Unexpected response from save_token_metadata:", postResponse.data);
    }

    return metadata

  } catch (error) {
    console.error(`Error fetching metadata for mint ${mintAddress}:`, error);
    return null;
  }
};

const fetchTokenPrice = async (mintAddress) => {
  try {
    // First, check if the price is available in the cache
    const cachedPriceResponse = await axiosInstance.post('/v2/get_token_price', {
      mintAddress,
    });
  
    if (cachedPriceResponse.status === 200 && cachedPriceResponse.data.usdc_price) {
      // If price exists in cache, return it
      return cachedPriceResponse.data.usdc_price;
    } else {
      // If no cached price, call the Jupiter API
      const priceResponse = await fetch(
        `https://api.jup.ag/price/v2?ids=${mintAddress}`
      );
      const priceData = await priceResponse.json();
  
      // Check if we received a valid response from Jupiter API
      if (priceData && priceData.data) {
        // const tokenPrice = priceData.data[0].price;
        const tokenPrice = priceData?.data?.[mintAddress]?.price || 0;
  
        // Save the fetched price to the database for future use
        await axiosInstance.post('/v2/save_token_price', {
          mintAddress,
          usdcPrice: tokenPrice,
        });
  
        // Return the price
        return tokenPrice;
      } else {
        throw new Error('Failed to fetch price from Jupiter API');
      }
    }
  } catch (error) {
    console.error('Error fetching token price:', error);
    return null;
  }
};

export const fetchHoldings = async (walletAddress) => {
  if (!walletAddress) return;

  // setLoading(true);
  try {
    const programId = {
      // options here
      programId: 'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'
    }

    const options = {
      // options here
        encoding: 'jsonParsed'
      }

      // Get holdings
      const holdings = await rpc.getTokenAccountsByOwner(walletAddress, programId, options).send();
      console.log(holdings)

      if (holdings === 0) {
        return;
      }

      const filteredTokens = holdings.value.filter(token => 
      token.account.data.parsed.info.tokenAmount.amount > 0
    );

    const fetchAllMetadata = async () => {
      // const metadataPromises = filteredTokens.map(async token => {
      //   const mintAddress = token.account.data.parsed.info.mint;
      //   const metadata = await fetchTokenMetadata(mintAddress);


      //   return { ...token, metadata };
      // });
      const metadataPromises = filteredTokens.map(async token => {
        const mintAddress = token.account.data.parsed.info.mint;
        const [metadata, price] = await Promise.all([
          fetchTokenMetadata(mintAddress),
          fetchTokenPrice(mintAddress)
        ]);

        return { ...token, metadata, price };
      });
    
      const tokensWithMetadata = await Promise.all(metadataPromises);
      console.log(tokensWithMetadata)
      return tokensWithMetadata
      // setTokens(tokensWithMetadata);
      // setChartData(prepareChartData())
    };

    const completeHoldings = fetchAllMetadata();
    return completeHoldings
    // setTokens(filteredTokens)

  } catch (error) {
    console.error('Error fetching holdings:', error);
  } finally {
    // setLoading(false);
  }
};

export const fetchTransactions = async (walletAddress, beforeSignature, transactions, cachedTransactions, hasMore) => {
  if (!walletAddress) return;

  let more = hasMore
  let b4sig = beforeSignature
  let updatedCachedTransactions = null
  let updatedTransactions = null
  
  // setLoading(true);
  try {
    // get signature with largest blocktime (most recent) from backend
    // consider adding block_time to transaction accounts schema as well
    // query on-chain with backend signature 
    // if no new on-chain transactions
    // load backend cached transations


    // BIGQUERY TEST
    // const response = await fetch(`/api/transactions/${walletAddress}`);
    // const result = await response.json();
    // console.log(result)

    // if (result.success) {
    //   return result.data.metadata;
    // } else {
    //   console.warn(`API error fetching token metadata: ${result.error}`);
    // }
    
    // Consider preliminary getSignaturesForAddress using most recently cached tx sig as before

    // Configure options for getSignaturesForAddress
    const options = {
      limit: 10,
      ...(beforeSignature && { before: beforeSignature })
    };

    // Get signatures
    const signatures = await rpc.getSignaturesForAddress(walletAddress, options).send();
    console.log(signatures)

    // check first returned signature (most recent on-chain tx sig for address) to cache
    // if exist in cache make future paginated request to backend as they should be stored
    // fallback to rpc json api if they're not
    
    if (signatures.length < 10) {
      // before you setHasMore to false grab the cached data from backend
      // clean approach to resending the same transactions 
      // would be sending only the blocktime from last rpc transaction
      // then only requesting cached transactions if blocktimes are less than the oldest rpc transaction
      // i.e. does the database have any older transactions not supplied by rpc
      // if so, fetch
      console.log("Signature length is ", signatures.length)
      console.log("Checking backend for cached transactions.")
      // const lastTxSignature = signatures ? signatures[signatures.length - 1].signature : transactions[transactions.length - 1]?.signature
      const lastTxBlocktime = signatures ? signatures[signatures.length - 1]?.blockTime : transactions[transactions.length - 1]?.blockTime
      // testing backend lastTxBlocktime undefined handling
      // const lastTxBlocktime = undefined
      const cacheTxCheckResponse = await axiosInstance.post('/v2/check_cached_transactions', {
        wallet_address: walletAddress,
        block_time: Number(lastTxBlocktime)
      }).catch(err => {
        console.error(`Error checking cached transactions`, err.response?.data || err.message);
      })


      if (cacheTxCheckResponse && cacheTxCheckResponse.status === 200) {
       const cacheTxResponse = await axiosInstance.post('/v2/get_cached_transactions', {
         wallet_address: walletAddress,
         block_time: Number(lastTxBlocktime)
         // last_tx_signature: lastTxSignature
       }).catch(err => {
         console.error(`Error getting transactions`, err.response?.data || err.message);
       })
       
       const result = await cacheTxResponse.data
       if (result.success) {
         // setCachedTransactions(prev => [...prev, ...result.transactions]);
         updatedCachedTransactions = [...cachedTransactions, ...result.transactions];
         // Iterate through result.transactions and add only those with unique signatures
         // const newTransactions = result.transactions.filter(tx => 
         //   !transactions.some(existingTx => existingTx.signature === tx.signature)
         // );
 
         // console.log(newTransactions)
 
         // Update state with new transactions
         // setTransactions(prev => [...prev, ...newTransactions]);
       }
       if (cacheTxResponse && cacheTxResponse.status !== 200) {
         console.warn("Unexpected response from get_cached_transations", cacheTxResponse.data)
       }
      }
      // setHasMore(false);
      more = false
    }

    if (signatures.length > 0) {
      // Store the last signature for pagination
      // setBeforeSignature(signatures[signatures.length - 1].signature);
      b4sig = signatures[signatures.length - 1].signature
      // Map signatures to transaction data
      const transactionData = signatures.map(sig => ({
        signature: sig.signature,
        blockTime: Number(sig.blockTime),
        slot: Number(sig.slot),
        // err: sig.err,
        err: sig.err ? JSON.stringify(sig.err, (_, v) => (typeof v === "bigint" ? v.toString() : v)) : null,
        confirmationStatus: sig.confirmationStatus,
        memo: sig.memo
      }));
      console.log(transactionData)
      // send transactionData to backend mariadb table for future requests
      const transactionResponse = await axiosInstance.post('/v2/save_transactions', {
        transactions: transactionData,
        // send the walletAddress to add to transaction_accounts on the backend per tx
        wallet_address: walletAddress
      }).catch(err => {
        console.error(`Error saving transactions:`, err.response?.data || err.message);
      });
      
      if (transactionResponse && transactionResponse.status !== 201) {
        console.warn("Unexpected response from save_transactions:", transactionResponse.data);
      }


      // setTransactions(prev => [...prev, ...transactionData]);
      updatedTransactions = [...transactions, ...transactionData]
    }
    
    const finalCached = updatedCachedTransactions ? updatedCachedTransactions : cachedTransactions
    const finalTxs = updatedTransactions ? updatedTransactions : transactions
    
    // const returnArray = [ b4sig, finalTxs, finalCached, more ]
    // console.log(returnArray)
    
    // consider matching all variables with destructured variables to use shorthand object property notation and remove keys
    const returnObject = {
      b4sig: b4sig,
      txs: finalTxs,
      cachedtxs: finalCached,
      more: more
    };
    console.log(returnObject)

    return returnObject
  } catch (error) {
    console.error('Error fetching transactions:', error);
  } finally {
    // setLoading(false);
  }
};
