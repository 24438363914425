import React, { useState, useContext } from 'react';
import { AxiosContext } from '../App';
import { useAudioPlayer } from "../AudioPlayerContext";
// import { useEditor, EditorContent } from '@tiptap/react';
// import StarterKit from '@tiptap/starter-kit';
import styled from 'styled-components';
import { MoonLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../ToastContext';
import {
    RTEditor
  } from '../components';

// const EditorContainer = styled.div`
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 20px;
// `;

// const TitleInput = styled.input`
//   width: 100%;
//   font-size: 24px;
//   margin-bottom: 20px;
//   padding: 10px;
// `;

const DraftsContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const TitleInput = styled.input`
  width: 97.25%;
  padding: 10px;
  font-size: 24px;
  margin-bottom: 20px;
  background-color: #000;
  color: #FFFFFF;
  // border: 1px solid #404040;
  border: 1px solid transparent;
  border-radius: 5px;

  &:focus {
    outline: none;
    border-color: #965EFF;
  }

  @media (max-width: 768px) {
    width: 93.5%;
  }
`;

const SaveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center; /* Center items vertically */
  background-color: #965EFF;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  // border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: ${props => props.$isAudioPlayerVisible ? '70px' : '0'};

  &:hover {
    background-color: #7B4FCC;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

// const StyledEditorContent = styled(EditorContent)`
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   min-height: 300px;
//   padding: 10px;
// `;

// const Button = styled.button`
//   background-color: #4a90e2;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   font-size: 16px;
//   cursor: pointer;
//   margin-top: 20px;

//   &:hover {
//     background-color: #357ae8;
//   }
// `;

const DraftsPage = () => {
  const axiosInstance = useContext(AxiosContext);
  const { isAudioPlayerVisible } = useAudioPlayer();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  // const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { showToast } = useToast();

  const redirectToHome = () => {
    navigate('/', { replace: true }); // Navigate to login page
    window.location.reload(); // Reload the page after navigation
  };

  const handleSave = async () => {
    
    console.log(title)
    console.log(content)
    if (!title.trim() || !content.trim()) {
        // setError('Please enter both title and content');
        showToast("Please enter both title and content", "danger");
        return;
    }
  
    setIsSaving(true);
    // setError(null);
    try {
      const response = await axiosInstance.post('/v2/save_article', {
        title,
        content,
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error('Failed to save draft');
      }

      // const data = await response.data;
      console.log('Draft saved successfully:', response.data);
      // You might want to show a success message or redirect the user
    } catch (error) {
      console.error('Error saving draft:', error);
      // Handle error (show error message to user)
    } finally {
        setIsSaving(false);
        redirectToHome();
    }
  };

  // showToast("Please log in to like posts", "info");


  return (
    <DraftsContainer>
      {/* {error && <ErrorMessage>{error}</ErrorMessage>} */}
      <TitleInput
        type="text"
        placeholder="Enter article title..."
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <RTEditor onUpdate={setContent} />
      <SaveButton $isAudioPlayerVisible={isAudioPlayerVisible} onClick={handleSave} disabled={isSaving}>
      {isSaving ? (
        <div style={{ paddingLeft: '35px', paddingRight: '35px' }}>
          <MoonLoader color="#ffffff" loading={isSaving} size={15} />
        </div>
      ) : (
        'Save Article'
      )}
      </SaveButton>
    </DraftsContainer>
  );
};

export default DraftsPage;