// Header.js
import React, { useState, useEffect } from 'react';
import { usePosts } from '../../PostsContext';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  // background-color: #000000;
  background-color: ${props => props.$sticky ? 'rgba(0,0,0,0.5)' : '#000000'};
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  color: white;
  padding: 1rem;
  text-align: center;
  position: sticky;
  top: ${props => props.$sticky ? '0' : '-100px'};
  transition: top 0.3s;
  z-index: 100;
  display: flex;
  justify-content: center;
`;

const LogoLink = styled(Link)`
  // margin-left: -20px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

function Header() {
  const [sticky, setSticky] = useState(false);
  const { refreshPosts } = usePosts();

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.pageYOffset > window.innerHeight * 0.2);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleResetAndNavigate = () => {
    refreshPosts();
    // If not at home, navigate to home
    // Scroll to top
    window.scrollTo(0, 0);
  };

  return (
    <HeaderContainer $sticky={sticky}>
      <LogoLink to="/" onClick={handleResetAndNavigate}>
        <img src="https://www.iiicoast.com/backend-static/upload/apple-touch-icon-v4.gif" alt="Logo" width="35px" />
      </LogoLink>
    </HeaderContainer>
  );
}

export default Header;