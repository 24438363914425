import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { AuthContext } from './AuthContext'; // Import if needed
import { useFAB } from './FABContext'; // Import if needed
import useLongPolling from './hooks/useLongPolling'; // Import your custom hook
import axios from 'axios';
import axiosInstance from './axiosConfig';

const PostsContext = createContext();

export const PostsProvider = ({ children }) => {
  // Move all the state variables related to posts here
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [missingPostIds, setMissingPostIds] = useState([]);
  const [isFetchingMissing, setIsFetchingMissing] = useState(false);
  const [lastFetchedTid, setLastFetchedTid] = useState(null);
  const [lastFetchedAid, setLastFetchedAid] = useState(null);
  const [lastFetchedPostId, setLastFetchedPostId] = useState(null);
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const [isNewPosts, setIsNewPosts] = useState(false);

  // Import necessary contexts or hooks
  const { isAuthenticated, loading } = useContext(AuthContext);
  const { isOpen } = useFAB();
  const { unreadCount, notifications, recentTid, recentAid } = useLongPolling();

  // ______   ______     ______     ______   ______        ______     ______     ______     ______     __  __    
  // /\  == \ /\  __ \   /\  ___\   /\__  _\ /\  ___\      /\  __ \   /\  == \   /\  == \   /\  __ \   /\ \_\ \   
  // \ \  _-/ \ \ \/\ \  \ \___  \  \/_/\ \/ \ \___  \     \ \  __ \  \ \  __<   \ \  __<   \ \  __ \  \ \____ \  
  //  \ \_\    \ \_____\  \/\_____\    \ \_\  \/\_____\     \ \_\ \_\  \ \_\ \_\  \ \_\ \_\  \ \_\ \_\  \/\_____\ 
  //   \/_/     \/_____/   \/_____/     \/_/   \/_____/      \/_/\/_/   \/_/ /_/   \/_/ /_/   \/_/\/_/   \/_____/ 
  // Function to fetch paginated posts from API
  // starts setting global loading state returning BarLoader while fetching data
  // setTimeout creates an appealing delay while loading more posts
  // const endpoint selected based on authentication status
  // both endpoints return serialized JSON containing one page of posts
  // First conditional checks for authenticated users whom don't follow anyone (new users)
  // If new user, fetch from all-posts
  // Finally, posts array updated with fetched posts
  // First conditional, prevents duplicate posts from being added
  // Second, attaches new posts if no old posts (probably redundant given final return)
  // Third, returns old posts if no new posts 
  // (may also be redundant if adding blank newPosts wouldn't affect posts array)
  // Once posts array updates, set HasMore truthy if endpoint returned newPosts
  // Increment page
  // set loading false render feed with fetched data

  const fetchPosts = useCallback(async () => {
    setIsLoading(true);
    setTimeout(async () => {
      try {
        const endpoint = isAuthenticated ? 'v2/get-user-feed' : 'api/get-posts';
        console.log('Fetching from ' + endpoint + ': page', page);
        let response = await axios.get(`/${endpoint}/${page}`);
        let newPosts = response.data;

        // If freshPosts is empty and the user is authenticated, retry with the unauthenticated endpoint
        if (isAuthenticated && (!newPosts || newPosts.length === 0)) {
          console.log("No posts found for user, retrying with public feed...");
          response = await axios.get(`/api/get-posts/${page}`);
          newPosts = response.data;
        }

        // Check if there are existing posts
        setPosts(prevPosts => {
          // Ensure newPosts is not empty
          if (newPosts === prevPosts) {
            // If there are no new posts, return the current posts
            return prevPosts;
          }
          // Ensure prevPosts is not empty
          if (prevPosts.length === 0) {
            // If there are no existing posts, just add all new posts
            return [...prevPosts, ...newPosts];
          }
          // Ensure newPosts is not empty
          if (newPosts.length === 0) {
            // If there are no new posts, return the current posts
            return prevPosts;
          }
          // Return the updated posts
          return [...prevPosts, ...newPosts];
        });

        // Pagination state update
        setHasMore(newPosts.length > 0);
        setPage(prevPage => prevPage + 1);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setIsLoading(false);
      }
    }, 500); // 0.5-second delay before loading more posts
  }, [page, setPosts, isAuthenticated]);

  // Function to refresh posts with first page (primarily for reloading homepage on navigate)
  // Primary use cases: Pull-to-refresh on infinitescroll, refresh on header logo click, refresh on home sidebar click
  const refreshPosts = async () => {
    if (!isAuthenticated) {
      console.log('User is not authenticated. Skipping refresh.');
      return;
    }
    setIsRefreshing(true);

    setTimeout(async () => {
      try {
        const endpoint = isAuthenticated ? 'v2/get-user-feed' : 'api/get-posts';
        const response = await axios.get(`/${endpoint}/1`);
        const freshPosts = response.data;
        console.log('Fetched fresh posts:', freshPosts);

        setPosts(freshPosts); // Replace old posts with the latest from page 1
        setPage(2); // Reset to page 2 for the next load
        setHasMore(freshPosts.length > 0);
      } catch (error) {
        console.error('Error refreshing posts:', error);
      } finally {
        setIsRefreshing(false);
      }
    }, 250); // 0.5-second delay before loading more posts
  };

  const fetchPostRange = async (lastPostId, isArticle) => {
    try {
      const response = await axios.get(`/v2/post-range-update/${lastPostId}/${isArticle}`);
      const { post_ids, recent_tid } = response.data;
      setMissingPostIds(post_ids);
      return recent_tid;
    } catch (error) {
      console.error('Error fetching post range:', error);
      return null;
    }
  };

  // __   __     ______     __     __        ______   ______     ______     ______  
  // /\ "-.\ \   /\  ___\   /\ \  _ \ \      /\  == \ /\  __ \   /\  ___\   /\__  _\ 
  // \ \ \-.  \  \ \  __\   \ \ \/ ".\ \     \ \  _-/ \ \ \/\ \  \ \___  \  \/_/\ \/ 
  //  \ \_\\"\_\  \ \_____\  \ \__/".~\_\     \ \_\    \ \_____\  \/\_____\    \ \_\ 
  //   \/_/ \/_/   \/_____/   \/_/   \/_/      \/_/     \/_____/   \/_____/     \/_/ 
  // Function to retrieve new posts from backend with recent_tid (from long-polling)

  const getRecentPost = async (postId) => {
    try {
      // Update to GET to align with best practices. 
      // const response = await axios.get('/v2/get_recent_post', {
      //   params: {
      //     recent_tid: recent_tid
      //   }
      // });
      const response = await axios.post('/v2/get_recent_post', {
        recent_tid: postId,
      });

      if (response.status === 200) {
        // Fetch the new post
        if (response.data) {
          // Add the new post to the top of the list 
          console.log(response.data)
          setPosts(prevPosts => [
            {
              ...response.data,
              new: true
              // animate: true // Add an animate class to trigger the animation
            },
            ...prevPosts
          ]);
          
          // Trim the excess posts to 10
          setPosts(prevPosts => prevPosts.slice(0, 10));
          
          // Reset the page to 2
          setPage(2);
        }
        // setIsLoading(false);
  
        // Smooth scroll to the top
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
  
        console.log('Post retrieved successfully');
      }
      // const freshPosts = response.data;
      // console.log('Fetched fresh posts:', freshPosts);
// 
      // setPosts(freshPosts); // Replace old posts with the latest from page 1
      // setPage(2); // Reset to page 2 for the next load
      // setHasMore(freshPosts.length > 0);
    } catch (error) {
      console.error('Error getting recent post:', error);
    } finally {
      // setIsRefreshing(false);
      console.log('Get recent post finished');
    }
  };

  const getRecentArticle = async (articleId) => {
    try {
      const response = await axios.get(`/v2/get-recent-article/${articleId}`);
      const newArticle = response.data;
      

      setPosts(prevPosts => {
        // Check if the new article's timestamp is more recent than the first post
        if (prevPosts.length === 0 || new Date(newArticle.timestamp) > new Date(prevPosts[0].timestamp)) {
          // Add the new article to the top of the list
          const updatedPosts = [newArticle, ...prevPosts];
          
          // Trim the posts list to 10
          return updatedPosts.slice(0, 10);
        }
  
        // If the new article is not newer, return the previous posts unchanged
        return prevPosts;
      });

      // setPosts(prevPosts => {
      //   if (prevPosts.length === 0 || new Date(newArticle.timestamp) > new Date(prevPosts[0].timestamp)) {
      //     return [newArticle, ...prevPosts];
      //   }
      //   return prevPosts;
      // });
    } catch (error) {
      console.error('Error fetching recent article:', error);
    }
  };

  const handlePostSubmit = async (postData) => {
    try {
      // setIsLoading(true);
      const response = await axiosInstance.post('/v2/post_tweet', {
        tweet: postData,
      });
  
      if (response.status === 204) {
        // Fetch the new post
        const newPostResponse = await axiosInstance.get('/v2/get-user-post');
        if (newPostResponse.data) {
          // Add the new post to the top of the list 
          setPosts(prevPosts => [
            {
              ...newPostResponse.data,
              new: true
              // animate: true // Add an animate class to trigger the animation
            },
            ...prevPosts
          ]);
          
          // Trim the excess posts to 10
          setPosts(prevPosts => prevPosts.slice(0, 10));
          
          // Reset the page to 2
          setPage(2);
        }
        // setIsLoading(false);
        
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0; // For older browsers

        // // Smooth scroll to the top
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });
  
        console.log('Post submitted successfully');
      }
    } catch (error) {
      // setIsLoading(false);
      console.error('Error submitting post:', error);
    }
  };

  // __  __     ______     ______     ______     ______   ______   ______     ______     ______   ______    
  // /\ \/\ \   /\  ___\   /\  ___\   /\  ___\   /\  ___\ /\  ___\ /\  ___\   /\  ___\   /\__  _\ /\  ___\   
  // \ \ \_\ \  \ \___  \  \ \  __\   \ \  __\   \ \  __\ \ \  __\ \ \  __\   \ \ \____  \/_/\ \/ \ \___  \  
  //  \ \_____\  \/\_____\  \ \_____\  \ \_____\  \ \_\    \ \_\    \ \_____\  \ \_____\    \ \_\  \/\_____\ 
  //   \/_____/   \/_____/   \/_____/   \/_____/   \/_/     \/_/     \/_____/   \/_____/     \/_/   \/_____/ 

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user is scrolled to the top
      const isAtTop = window.scrollY === 0;
      setScrolledToTop(isAtTop);
    };
  
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // This useEffect ensures authentication changes fetch the correct feed (all-posts vs. user-feed)
  useEffect(() => {
    const authConsoleText = "Current Authentication Status: "
    console.log(authConsoleText + isAuthenticated)
    setPage(1);
    setPosts([]);
  }, [isAuthenticated]); // Re-fetch when authentication status changes

  // If no posts and user authenticated load first page of posts
  useEffect(() => {
    if (posts.length === 0 && !loading) {
      // console.log('Initial load: fetching first page of posts');
      fetchPosts();
    }
  }, [posts.length, fetchPosts, loading]);

  // Move the useEffects here
  useEffect(() => {
    const handlePostUpdates = async () => {
      let isArticle = 0
      if (posts && posts.length > 0) {
        // const lastPost = posts[0];
        // const lastPostId = lastItem.post ? lastItem.id : 0;
        const lastTidPost = posts.find(post => post.post);

        // if (lastPost && lastPost.post && recentTid > lastPost.id && lastFetchedTid !== recentTid) {
        if (lastTidPost && lastTidPost.post && recentTid > lastTidPost.id && lastFetchedTid !== recentTid) {
          // Logic for posts
          setLastFetchedTid(recentTid);
          const fetchedRecentTid = await fetchPostRange(lastTidPost.id, isArticle);

          if (fetchedRecentTid && scrolledToTop && !isOpen && !isFetchingMissing) {
            setIsFetchingMissing(true);
            if (missingPostIds.length > 0) {
              const postId = missingPostIds[0];
              if (lastFetchedPostId !== postId) {
                setLastFetchedPostId(postId);
                await getRecentPost(postId);
                setMissingPostIds(prev => prev.slice(1));
              }
            }
            setIsFetchingMissing(false);
          } else if (!scrolledToTop) {
            setIsNewPosts(true);
          }
        }

        // Handle article updates
        if (recentAid > lastFetchedAid && lastFetchedAid !== recentAid) { // recentAid > lastFetchedAid
          setLastFetchedAid(recentAid);
          // implement scroll logic here as well?
          await getRecentArticle(recentAid);
        }
      }
    };
    handlePostUpdates();
  }, [recentTid, recentAid, posts, scrolledToTop, missingPostIds, isFetchingMissing, lastFetchedTid, lastFetchedAid, lastFetchedPostId, isOpen]);

  useEffect(() => {
    if (scrolledToTop && missingPostIds.length > 0 && !isOpen && !isFetchingMissing) {
      const fetchNextMissingPost = async () => {
        setIsFetchingMissing(true);
        const postId = missingPostIds[0];
        if (lastFetchedPostId !== postId) {
          setLastFetchedPostId(postId);
          await getRecentPost(postId);
          setMissingPostIds(prev => prev.slice(1));
        }
        setIsFetchingMissing(false);
      };

      fetchNextMissingPost();
    }
  }, [scrolledToTop, missingPostIds, isFetchingMissing, lastFetchedPostId, isOpen]);

  const value = {
    unreadCount,
    notifications,
    posts,
    setPosts,
    page,
    setPage,
    hasMore,
    setHasMore,
    isLoading,
    setIsLoading,
    isRefreshing,
    setIsRefreshing,
    missingPostIds,
    setMissingPostIds,
    isFetchingMissing,
    setIsFetchingMissing,
    lastFetchedTid,
    setLastFetchedTid,
    lastFetchedAid,
    setLastFetchedAid,
    lastFetchedPostId,
    setLastFetchedPostId,
    scrolledToTop,
    setScrolledToTop,
    isNewPosts,
    setIsNewPosts,
    fetchPosts,
    refreshPosts,
    fetchPostRange,
    getRecentPost,
    getRecentArticle,
    handlePostSubmit,
  };

  return <PostsContext.Provider value={value}>{children}</PostsContext.Provider>;
};

export const usePosts = () => {
  const context = useContext(PostsContext);
  if (context === undefined) {
    throw new Error('usePosts must be used within a PostsProvider');
  }
  return context;
};