import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AxiosContext } from '../../App';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import {
  SpinnerContainer, 
} from '../../styles/shared/SharedStyles';

const BASE_URL = 'https://www.iiicoast.com';

const ProfilePicture = styled.img`
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const WhoToFollowContainer = styled.div`
  padding: 20px;
  color: #fff;
`;

// const Container = styled.div`
//   padding: 20px;
// `;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 0 20px 0;
  width: 100%;
`;

const SideContainer = styled.div`
  padding: 0 20px 0;
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
`;

const Card = styled.div`
  padding: 20px;
  background: #070707;
  border-radius: 20px;
  // border: 1px solid #777;

  @media (max-width: 768px) {
    padding: 0;
    background: #000;
  }
`;

const UserContainer = styled.div`
  // border-bottom: 1px solid #222;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

const UserLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  img {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

const FollowButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FollowButton = styled.button`
  margin-top: 5px;
  margin-right: 5px;
  padding: 7px 14px 7px;
  border: 1px #272727 solid;
  border-radius: 15px;
  background: none;
  color: #888888;
  cursor: pointer;
  
`

const FollowButtonText = styled.span`
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.025em;
`

const Button = styled(Link)`
  padding: 0.75rem;
  background: linear-gradient(to right, rgb(13, 104, 210), rgb(40, 133, 242), rgb(43, 91, 255));
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;
  text-decoration: none;

  &:hover {
    background-position: right center;
  }
`;

// // Function to generate `srcSet`
// const generateSrcSet = (baseUrl, uid) => `
//   ${baseUrl}/backend-static/upload/${uid}_thumbnail.jpg 35w,
//   ${baseUrl}/backend-static/upload/${uid}_medium.jpg 150w,
//   ${baseUrl}/backend-static/upload/${uid}_large.jpg 300w
// `;

// // Helper Function
// const getProfilePictureData = (user) => {
//   const isDefaultImage = !user.profile_picture_url || user.profile_picture_url === 'default';
//   return {
//     src: isDefaultImage
//       ? `${BASE_URL}/backend-static/upload/user_thumbnail.jpg`
//       : !user.profile_picture_url.startsWith('http')
//         ? `${BASE_URL}${user.profile_picture_url}`
//         : user.profile_picture_url,
//     srcSet: isDefaultImage
//       ? `
//         ${BASE_URL}/backend-static/upload/user_thumbnail.jpg 35w,
//         ${BASE_URL}/backend-static/upload/user_medium.jpg 150w,
//         ${BASE_URL}/backend-static/upload/user_large.jpg 300w
//       `
//       : `
//         ${BASE_URL}/backend-static/upload/${user.uid}_thumbnail.jpg 35w,
//         ${BASE_URL}/backend-static/upload/${user.uid}_medium.jpg 150w,
//         ${BASE_URL}/backend-static/upload/${user.uid}_large.jpg 300w
//       `,
//   };
// };

function Recommended() {
  const [recommendedUsers, setRecommendedUsers] = useState([]);
  // const [following, setFollowing] = useState();
  const [followStatus, setFollowStatus] = useState({});
  const axiosInstance = useContext(AxiosContext);
  // let user;
  // const isHackerNewsPost = !user?.pid === 359;

  

  // const profileLink = isHackerNewsPost 
  //   ? '/profile/Hacker%20News'
  //   : `/profile/${encodeURIComponent(!user?.username)}`;

  // // Determine profile picture URL and srcSet
  // const isDefaultImage = !user?.profile_picture_url === '/backend-static/upload/user_thumbnail.jpg';

  // const profilePicData = {
  //   src: isDefaultImage
  //     ? `${BASE_URL}/backend-static/upload/user_thumbnail.jpg`
  //     : !user?.profile_picture_url.startsWith('http')
  //       ? !user?.profile_picture_url
  //       : `${BASE_URL}${!user?.profile_picture_url}`,
  //   srcSet: isDefaultImage
  //     ? `
  //       ${BASE_URL}/backend-static/upload/user_thumbnail.jpg 35w,
  //       ${BASE_URL}/backend-static/upload/user_medium.jpg 150w,
  //       ${BASE_URL}/backend-static/upload/user_large.jpg 300w
  //     `
  //     : generateSrcSet(BASE_URL, user?.uid)
  // };

  const getProfilePictureData = (user) => {
    const isDefaultImage = !user.profile_picture_url || user.profile_picture_url === 'default';
    return {
      src: isDefaultImage
        ? `${BASE_URL}/backend-static/upload/user_thumbnail.jpg`
        : !user.profile_picture_url.startsWith('http')
          ? `${BASE_URL}${user.profile_picture_url}`
          : user.profile_picture_url,
      srcSet: isDefaultImage
        ? `
          ${BASE_URL}/backend-static/upload/user_thumbnail.jpg 35w,
          ${BASE_URL}/backend-static/upload/user_medium.jpg 150w,
          ${BASE_URL}/backend-static/upload/user_large.jpg 300w
        `
        : `
          ${BASE_URL}/backend-static/upload/${user.uid}_thumbnail.jpg 35w,
          ${BASE_URL}/backend-static/upload/${user.uid}_medium.jpg 150w,
          ${BASE_URL}/backend-static/upload/${user.uid}_large.jpg 300w
        `,
    };
  };


  const fetchRecommendedUsers = () => {
    axios.get('/v2/get-recommended-users')
      .then(response => {
        setRecommendedUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching recommended users:', error);
      });
  };

  const handleFollow = (user_uid) => {
    axiosInstance.post(`/v2/follow/${user_uid}`)
      .then(response => {
        setFollowStatus(prevState => ({
          ...prevState,
          [user_uid]: true,  // Mark this user as followed
        }));
        // setFollowing(true); // Update the UI
        // showToast(`Followed ${user.username}`, "success");
      })
      .catch(err => {
        console.error('Error following user:', err);
      });
  };

  const handleUnfollow = (user_uid) => {
    axiosInstance.post(`/v2/unfollow/${user_uid}`)
      .then(response => {
        setFollowStatus(prevState => ({
          ...prevState,
          [user_uid]: false,  // Mark this user as unfollowed
        }));
        // setFollowing(false); // Update the UI
        // showToast(`Unfollowed ${user.username}`, "warning");
      })
      .catch(err => {
        console.error('Error unfollowing user:', err);
      });
  };

  useEffect(() => {
    fetchRecommendedUsers();
  }, []);

  // Optionally, initialize the follow statuses if you have initial data
  useEffect(() => {
    const initialStatus = recommendedUsers.reduce((acc, user) => {
      acc[user.uid] = user.following;  // Initialize from your data (e.g., 'true' or 'false')
      return acc;
    }, {});
    setFollowStatus(initialStatus);
  }, [recommendedUsers]);

  if (!recommendedUsers) {
    return (
      <SpinnerContainer>
        <BarLoader color="#888888" loading={!recommendedUsers} />
      </SpinnerContainer>
    );
  }

  return (
    <WhoToFollowContainer>
      <Card>
        <SideContainer>
          <h3>Who To Follow</h3>
        </SideContainer>
        <SearchContainer>
          <SideContainer>
            {recommendedUsers
              .filter((user) => user) // Filter out null or undefined users
              .map((user) => {
                const profilePicData = getProfilePictureData(user);

                return (
                  <UserContainer key={user.uid}>
                    <UserLink to={`/profile/${user.username}`}>
                      <ProfilePicture
                        src={profilePicData.src}
                        srcSet={profilePicData.srcSet}
                        sizes="(max-width: 600px) 35px, (max-width: 1200px) 150px, 300px"
                        alt={user.username}
                      />
                      {user.username}
                    </UserLink>
                    <FollowButtonContainer>
                      {user.following !== 'same_user' && (
                        followStatus[user.uid] ? (
                          <FollowButton onClick={() => handleUnfollow(user.uid)}>
                            <FollowButtonText>Unfollow</FollowButtonText>
                          </FollowButton>
                        ) : (
                          <FollowButton onClick={() => handleFollow(user.uid)}>
                            <FollowButtonText>Follow</FollowButtonText>
                          </FollowButton>
                        )
                      )}
                    </FollowButtonContainer>
                  </UserContainer>
                );
            })}
          </SideContainer>
        </SearchContainer>
        <ButtonContainer>
          <Button to='/'>Continue</Button>
        </ButtonContainer>
      </Card>
    </WhoToFollowContainer>
  );
}

export default Recommended;