import React, { useState, useEffect, useCallback } from 'react'; //, useRef
import styled, { keyframes } from 'styled-components'; // , css
import { FaPlus, FaCheck, FaPenFancy } from 'react-icons/fa'; // , FaChevronUp
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { useAudioPlayer } from "../../AudioPlayerContext";
import { usePosts } from '../../PostsContext';
import { useFAB } from '../../FABContext';
//  import { useToast } from '../../ToastContext';

// Add a debounce function to limit API calls
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(180deg); }
`;

const ButtonContainer = styled.div`
  position: fixed;
  // bottom: 30px;
  bottom: ${props => props.$isAudioPlayerVisible ? '80px' : '30px'};
  right: 30px;
  z-index: 1000;
  transition: bottom 0.3s ease-in-out;
`;

const Button = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  // background-color: #198754;
  background-color: ${props => props.$isHomeWithNewPosts ? '#007bff' : '#198754'};
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: all 0.3s;
  animation: ${rotate} 0.3s linear;
  z-index: 1002;

  &:hover {
    background-color: ${props => props.$isHomeWithNewPosts ? '#4da3ff' : '#5EAB87'};
    // background-color: #5EAB87; // 4da3ff
  }
`;

const DraftsButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4b62cc;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  bottom: 80px;
  right: 10px;
  transition: all 0.3s;
  opacity: 0;
  // opacity: 1;
  transform: translateY(20px);

  animation: ${({ isOpen }) =>
    isOpen ? 'slideOut 0.3s forwards' : 'slideIn 0.3s forwards'};

  &:hover {
    background-color: #8d9aff;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(20px);
    }
  }
`;

// const TooltipPersistent = styled.span`
//   visibility: visible;
//   right: 80px;
//   font-size: 1rem;
//   background-color: #070707;
//   color: #333;
//   text-align: center;
//   border-radius: 6px;
//   padding: 5px 10px;
//   position: absolute;
//   z-index: 1;
//   white-space: nowrap;
//   margin-left: -60px;
//   opacity: 1;
//   transition: opacity 0.3s;

//   ${DraftsButton}:hover & {
//     visibility: visible;
//     opacity: 1;
//   }

//   ${Button}:hover & {
//     visibility: visible;
//     opacity: 1;
//     right: 80px;
//     font-size: 1rem;
//   }
// `;

const Tooltip = styled.span`
  visibility: hidden;
  right: 60px;
  background-color: #070707;
  color: #333;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;

  ${DraftsButton}:hover & {
    visibility: visible;
    opacity: 1;
  }

  ${Button}:hover & {
    visibility: visible;
    opacity: 1;
    right: 80px;
    font-size: 1rem;
  }
`;

// Consider adding responsive width to TextArea
// const TextArea = styled.textarea`
//   position: fixed;
//   top: 100px;
//   left: 50%;
//   width: 300px;
//   height: 100px;
//   padding: 10px;
//   border-radius: 5px;
//   background-color: #212121;
//   color: white;
//   border: 1px solid #444;
//   resize: none;
//   transition: all 0.3s;
//   opacity: ${props => props.$isOpen ? 1 : 0};
//   transform: ${props => props.$isOpen ? 'translateX(-50%)' : 'translateX(-50%) translateY(20px)'};
//   pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
//   z-index: 1001;
//   font-size: 16px
// `;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 999;
`;

// Updated: Input styled like a search bar
const InputField = styled.input`
  position: fixed;
  top: 100px;
  left: 50%;
  width: 250px;
  padding: 10px;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  border: 1px solid #444;
  transition: all 0.3s;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transform: ${props => props.$isOpen ? 'translateX(-50%)' : 'translateX(-50%) translateY(20px)'};
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 1001;
  font-size: 16px;
`;

// Updated: Input styled like a search bar
const Input = styled.input`
  position: fixed;
  top: 100px;
  left: 50%;
  width: 250px;
  padding: 10px;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  border: 1px solid #444;
  transition: all 0.3s;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transform: ${props => props.$isOpen ? 'translateX(-50%)' : 'translateX(-50%) translateY(20px)'};
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 1001;
  font-size: 16px;
`;

// Dropdown list for predictive search
const PredictiveList = styled.ul`
  position: fixed;
  top: 150px;
  left: 50%;
  width: 250px;
  border-radius: 10px;
  background-color: #101010;
  color: white;
  border: 1px solid #111;
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateX(-50%);
  z-index: 1001;
  display: ${props => props.$isOpen ? 'block' : 'none'};
`;

const ListItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #070707;
  }
`;

const CHARACTER_LIMIT = 280;

const FloatingActionButton = ({ onSubmit, newPostClick, scrollClick }) => { // , showDraftsButton = true, showTextArea = true, placeholder = "What's happening?", tt_hover = "Write Post", tt_confirm = "Submit Post"
  // const [isOpen, setIsOpen] = useState(false);
  // const [content, setContent] = useState('');
  // const textAreaRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [enterPressed, setEnterPressed] = useState(false);
  const { isOpen, setIsOpen, content, setContent, textAreaRef } = useFAB();
  const { isNewPosts, scrolledToTop } = usePosts();
  // const { showToast } = useToast();
  const { isAudioPlayerVisible } = useAudioPlayer();
  const { pathname } = useLocation();
  const isHomeRoute = pathname === '/';
  const isThreadRoute = pathname.startsWith('/thread');
  const navigate = useNavigate();

  // Fetch usernames based on search query (mock function for now)
  const fetchUsers = async (query) => {
    // Replace with actual API call to /get-users
    const response = await fetch(`/v2/get-users?query=${query}`);
    const users = await response.json();
    setUserList(users);
  };

  const debouncedFetchUsers = debounce(fetchUsers, 300);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedFetchUsers(e.target.value);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setSearchQuery(user); // Show the selected username
    setUserList([]); // Hide the dropdown after selection
  };

  // Decide the onSubmit and placeholder based on the current route
  const routeSpecificConfig = () => {
    if (matchPath('/thread/:id', pathname)) {
      return {
        toolTipHover: 'Reply?',
        toolTipConfirm: 'Post Reply',
        placeholder: "Reply to Post?",
        showDraftsButton: false,
      };
    }

    switch (pathname) {
      case '/messaging':
        return {
          // onSubmit: () => {/* submit logic for messaging */},
          toolTipHover: 'Search Users',
          toolTipConfirm: 'Start Conversation',
          placeholder: 'Search for users or create conversation...',
          showDraftsButton: false,
        };
      case '/music':
        return {
          // onSubmit: () => {/* submit logic for music upload */},
          toolTipHover: 'Upload Music',
          toolTipConfirm: 'Confirm Upload',
          placeholder: 'Upload a song...',
          showDraftsButton: false,
        };
      default:
        return {
          // onSubmit: (content) => {/* default submit logic */},
          toolTipHover: 'Write Post',
          toolTipConfirm: 'Submit Post',
          placeholder: "What's happening?",
          showDraftsButton: true,
        };
    }
  };
  // placeholder: routePlaceholder
  const { showDraftsButton: routeShowDraftsButton, toolTipHover: routeToolTipHover, toolTipConfirm: routeToolTipConfirm } = routeSpecificConfig()

  const handleToggle = useCallback(() => {
    console.log('handleToggle called');
    console.log('isOpen:', isOpen);
    console.log('content:', content.trim());

    if (!scrolledToTop && isHomeRoute) {
      scrollClick();
      if (isNewPosts) {
        newPostClick();
      }
      // return;
    }

    // if (isNewPosts && isHomeRoute) {
    //   console.log('New post click detected');
    //   newPostClick(); // Scroll to new posts and update state
    //   return; // Prevent further processing since this is a specific case
    // }


    if (pathname === '/messaging') {
      if (isOpen && selectedUser) {
        onSubmit(selectedUser); // Trigger new conversation creation
        setSearchQuery('');
        setSelectedUser(null);
      }
      setIsOpen(!isOpen);
    }

    if (isOpen && content.trim()) {
      if (content.length <= CHARACTER_LIMIT) {
        console.log('Submitting content:', content);
        onSubmit(content);
        setContent('');
      } else {
        setContent('');
        // showToast("Posts must be under 280 characters.", "danger");
      }
    }
    setIsOpen(!isOpen);
    //   console.log('Submitting content:', content);
    //   onSubmit(content);
    //   setContent('');

    //   // Close the virtual keyboard on mobile devices
    //   // if (textAreaRef.current) {
    //   //   console.log('Blurring textarea');
    //   //   textAreaRef.current.blur(); // Remove focus from the textarea
    //   // }
    // }
    // setIsOpen(!isOpen);
  }, [content, setContent, isOpen, setIsOpen, onSubmit, pathname, selectedUser, isHomeRoute, isNewPosts, scrolledToTop, newPostClick, scrollClick]); //, showToast

  useEffect(() => {
    if (enterPressed) {
      handleToggle();
      setEnterPressed(false);
    }
  }, [content, enterPressed, handleToggle]);

  useEffect(() => {
    if (!isOpen && textAreaRef.current) {
      // console.log('Blurring textarea in useEffect');
      textAreaRef.current.blur();
    }
  }, [isOpen, textAreaRef]);

  // Function to handle keydown events
  const handleKeyDown = (e) => {
    const isEnterKey = e.key === 'Enter' || e.keyCode === 13 || e.code === 'Enter';
    console.log('Key pressed:', e.key);
    if (isEnterKey) { //   && !e.shiftKey removed shift key check for proper iOS behaviour. If necessary for desktop, will need a mobile check here.
      e.preventDefault(); // Prevents the default behavior of adding a new line
      console.log('Enter key pressed without Shift');
      // Set flag to true to trigger useEffect
      setEnterPressed(true);
    }
  };

  // useEffect(() => {
  //   if (isOpen && textAreaRef.current) {
  //     textAreaRef.current.focus();
  //     console.log('Textarea focused');
  //   }
  // }, [isOpen]);

  // useEffect to manage event listener
  useEffect(() => {
    const textArea = textAreaRef.current; // Store the current ref value

    if (textArea) {
      // console.log('Adding keydown event listener');
      textArea.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (textArea) {
        // console.log('Removing keydown event listener');
        textArea.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [isOpen, textAreaRef]);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      console.log('Backdrop clicked');
      setIsOpen(false);
    }
  };

  const handleDraftsClick = () => {
    navigate('/drafts');
  };

  // Conditional Rendering based on Route
  const renderPopupContent = () => {
    // if (!isOpen) return null;
    console.log(pathname)
    if (matchPath('/thread/:id', pathname)) {
      return (
        <></>
        // <TextArea
        //   ref={textAreaRef}
        //   $isOpen={isOpen}
        //   value={content}
        //   onChange={(e) => setContent(e.target.value)}
        //   placeholder={routePlaceholder}
        // />
      );
    }

    switch (pathname) {
      case '/':
      case '/thread':
        // Render Text Area for posts and replies
        return (
          <>
          </>
          // <TextArea
          //   ref={textAreaRef}
          //   $isOpen={isOpen}
          //   value={content}
          //   onChange={(e) => setContent(e.target.value)}
          //   placeholder={routePlaceholder}
          // />
        );
      case '/messaging':
        // Render Input for starting a new conversation
        return (
          <>
          <Input 
            ref={textAreaRef}
            $isOpen={isOpen}
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search users"
          />
          <PredictiveList $isOpen={isOpen && userList.length > 0}>
            {userList.map((user, index) => (
              <ListItem key={index} onClick={() => handleUserClick(user)}>
                {user}
              </ListItem>
            ))}
          </PredictiveList>
          </>
        );
      case '/music':
        // Render File Upload for song uploads
        return (
          <InputField //FileUploadInput
            $isOpen={isOpen}
            type="file"
            accept="audio/*"
            onChange={(e) => {
              // Handle file upload
              onSubmit(e.target.files[0]);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Backdrop $isOpen={isOpen && (!isHomeRoute && !isThreadRoute)} onClick={handleBackdropClick} />
      <ButtonContainer $isAudioPlayerVisible={isAudioPlayerVisible}>
        {isOpen && renderPopupContent()}
        {isOpen && routeShowDraftsButton && (
          <DraftsButton $isOpen={isOpen} onClick={handleDraftsClick}>
            <FaPenFancy />
            <Tooltip>Write Article</Tooltip>
          </DraftsButton>
        )}
        <Button $isHomeWithNewPosts={(isNewPosts) && isHomeRoute} onClick={handleToggle}> {/* || !scrolledToTop */}
        {/*{isHomeRoute && (isNewPosts) ? ( // || !scrolledToTop
          <>
            <FaChevronUp />
            {isNewPosts ? (
              <TooltipPersistent>Scroll to New Posts</TooltipPersistent>
            ) : (
              <Tooltip>Scroll Up</Tooltip>
            )}
          </>
        ) : */}
        {isOpen ? (
          <>
            <FaCheck />
            <Tooltip>{routeToolTipConfirm}</Tooltip>
          </>
        ) : (
          <>
            <FaPlus />
            <Tooltip>{routeToolTipHover}</Tooltip>
          </>
        )}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default FloatingActionButton;
