// src/serviceWorkerRegistration.js

// Service Worker registration code for Push Notifications
export function register() {

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    });
  }
}

export function listenForMessages(onNavigate) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'navigate') {
        const { url } = event.data;
        console.log("Navigating to " + url)
        onNavigate(url);
      }
    });
    // navigator.serviceWorker.addEventListener('message', function(event) {
    //   if (event.data && event.data.action === 'clearNotifications') {
    //     navigator.serviceWorker.getNotifications().then(notifications => {
    //       notifications.forEach(notification => {
    //         notification.close();
    //       });
    //     });
    //   }
    // });
  }
}