import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const PortfolioPieChart = ({ chartData }) => {
  // const chartData = prepareChartData();
  console.log(chartData)
  if (!chartData) return;

  const data = {
    labels: chartData.map(item => item.label),
    datasets: [
      {
        data: chartData.map(item => item.value),
        backgroundColor: chartData.map(item => item.color),
        hoverOffset: 4,
        borderWidth: 0, // No border for slices
      },
    ],
  };

  const options = {
    cutout: '70%', // Make it a hollow pie chart
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const label = tooltipItem.label;
            const value = tooltipItem.raw;
            return `${label}: $${value.toFixed(2)}`;
          },
        },
      },
    },
  };

  return <Doughnut data={data} options={options} />;
};

export default PortfolioPieChart;
