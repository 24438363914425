import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
// import { FaPlus } from "react-icons/fa";
import FloatingActionButton from '../components/shared/FloatingActionButton';
import { PageHeader, TabContainer, Tab, SongList, SongItem, SongThumbnail, ThumbnailImage, SongInfo, SongTitle, SongArtist, PlaylistCover, PlaylistInfo, PlaylistTitle, PlaylistDescription, PlaylistStats } from '../styles/music/MusicStyles';
import { useAudioPlayer } from "../AudioPlayerContext";

const PlaylistPageContainer = styled.div`
  padding: 0 20px 0;
  overflow-y: hidden;
  color: #ffffff;
`;

const PlaylistHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PlaylistPage = () => {
  const { pid } = useParams();
  const [playlist, setPlaylist] = useState(null);
  const [songs, setSongs] = useState([]);
  const { playSong } = useAudioPlayer();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlaylistData = async () => {
      try {
        const response = await axios.get(`/api/playlist/${pid}`);
        setPlaylist(response.data.playlist);
        setSongs(response.data.songs);
      } catch (error) {
        console.error('Error fetching playlist data:', error);
      }
    };
  
    fetchPlaylistData();
  }, [pid]);

  const handleSongsTabClick = () => {
    navigate('/music');
  };

  const handlePlaySong = (song) => {
    playSong(song, songs);
  };

  if (!playlist) return <div></div>;

  return (
    <PlaylistPageContainer>
        <PageHeader>
          <TabContainer>
          <Tab 
            onClick={handleSongsTabClick}
            $active={false}
          >
            Songs
          </Tab>
          <Tab 
            $active={true}
          >
            Playlist
          </Tab>
          </TabContainer>
          {/* <PlusButton>
            <FaPlus />
            <PlusButtonLabel>Add music</PlusButtonLabel>
          </PlusButton> */}
        </PageHeader>
      <PlaylistHeader>
        <PlaylistCover src={playlist.thumbnail_url} alt={playlist.name} />
        <PlaylistInfo>
          <PlaylistTitle>{playlist.name}</PlaylistTitle>
          <PlaylistDescription>{playlist.description}</PlaylistDescription>
          <PlaylistStats>{songs.length} songs</PlaylistStats>
        </PlaylistInfo>
      </PlaylistHeader>
      <SongList>
        {songs.map((song) => (
          <SongItem key={song.sid} onClick={() => handlePlaySong(song)}>
            <SongThumbnail>
              <ThumbnailImage src={song.thumbnail_url} alt={`${song.title} thumbnail`} />
            </SongThumbnail>
            <SongInfo>
              <SongTitle>{song.title}</SongTitle>
              <SongArtist>{song.artist}</SongArtist>
            </SongInfo>
          </SongItem>
        ))}
      </SongList>
      <FloatingActionButton showDraftsButton={false} showTextArea={false} tt_hover="Upload Song" tt_confirm="Confirm Upload"/>
    </PlaylistPageContainer>
  );
};

export default PlaylistPage;