import React, { useState, useEffect, useCallback } from 'react'; // useRef
import axios from 'axios';
import styled from 'styled-components';
import Post from '../components/feed/Post'; // Import the new Post component
// import Recommended from '../components/auth/Recommended'; // Import the new Post component
import { FaSearch } from 'react-icons/fa'; //, FaCog
import { Link, useLocation } from 'react-router-dom';

const ExploreContainer = styled.div`
  // padding: 20px;
`;

const WhiteText = styled.h3`
  color: #ffffff;
`;

const WhiteTextSmall = styled.h4`
  color: #ffffff;
`;

const Container = styled.div`
  padding: 20px;
`;

const ProfilePicture = styled.img`
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const ExploreHeader = styled.h2`
  color: #ffffff;
  padding: 20px;
`;

const SearchCard = styled.div`
  background-color: #070707;
  margin: 15px;
  // border: 5px solid #070707;
  border-radius: 20px;
  padding: 12px;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  background-color: #000;
  font-size: 16px;
  flex: 1;
  color: #fff;
  padding: 8px 12px;
  border-radius: 25px;
  border: none;
  margin-right: 8px;
  outline: none;
`;

const SearchButton = styled.button`
  display: flex;
  // padding: 8px 16px;
  padding: 8px;
  // background-color: #007bff;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: linear-gradient(to right, rgb(13, 104, 210), rgb(63, 111, 275), rgb(43, 91, 255));
  color: #fff;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;
  
  &:hover {
    // background-color: #0056b3;
    background-position: right center;
  }

  svg {
  // padding-right: 2px;
  padding: 2px;
  }
`;

const UserContainer = styled.div`
  border-bottom: 1px solid #222;
  padding: 12px 0;
`;

const UserLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  img {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

const PostListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 15px;  // Tighter layout on mobile
  }
`;

// const MutedText = styled.p`
//   color: #777;
// `

const InputContainer = styled.div`
  background-color: #000;
  display: flex;
  white-space: nowrap; /* Prevent wrapping */
  align-items: center;
  margin: 10px;
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #212121;
`;

const ExplorePage = () => {
  const [searchResults, setSearchResults] = useState({
    users: [],
    posts: []
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [recommendedUsers, setRecommendedUsers] = useState([]);

  const location = useLocation();

  // const searchQueryRef = useRef(searchQuery); // Ref to keep the latest unreadCount
  // searchQueryRef.current = searchQuery; // Update ref with the latest unreadCount
  
  const handleSearch = useCallback((queryOverride = null) => {
    const queryToUse = queryOverride || searchQuery;

    axios.get(`/api/search?query=${encodeURIComponent(queryToUse)}`)
      .then(response => {
        setSearchResults({
          users: response.data.users,
          posts: response.data.posts
        });
      })
      .catch(error => {
        console.error('Error searching:', error);
      });
  }, [searchQuery]);

  // Handle search query from URL parameters
  useEffect(() => {
    console.log("useEffect triggered for search refresh")
    console.log(location.hash)
    if (searchQuery !== location.hash) {
      console.log("Updating search query and calling handleSearch()")
      // const params = new URLSearchParams(location.search);
      // console.log(params)
      // const searchParam = params.get('search');
      const searchParam = location.hash
      if (searchParam) {        
        setSearchQuery(searchParam);
        // Perform search automatically
        // console.log(searchQueryRef.current)
        // handleSearch(searchQueryRef.current);
        handleSearch(searchParam)
      }
    }
  }, [location.hash, searchQuery, handleSearch]);

  // Handle custom event for updating search when already on explore page
  useEffect(() => {
    const handleUpdateSearch = (event) => {
      const { query } = event.detail;
      setSearchQuery(query);
      handleSearch(query);
    };

    window.addEventListener('updateExploreSearch', handleUpdateSearch);
    return () => {
      window.removeEventListener('updateExploreSearch', handleUpdateSearch);
    };
  }, [handleSearch]);

  useEffect(() => {
    fetchTrendingPosts();
    fetchRecommendedUsers();
  }, []);

  const fetchTrendingPosts = () => {
    axios.get('/api/get-trending-posts')
      .then(response => {
        setTrendingPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching trending posts:', error);
      });
  };

  const fetchRecommendedUsers = () => {
    axios.get('/api/get-recommended-users')
      .then(response => {
        setRecommendedUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching recommended users:', error);
      });
  };

  

  return (
    <ExploreContainer>
      <SearchCard>
        <ExploreHeader>Explore</ExploreHeader>
        <SearchContainer>
          <InputContainer>
            <SearchInput 
              type="text" 
              placeholder="Search..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            <SearchButton onClick={() => handleSearch()}>
              <FaSearch />
            </SearchButton>
          </InputContainer>
        </SearchContainer>
      
      {(searchResults.users.length > 0 || searchResults.posts.length > 0) && 
      <Container>
        <WhiteText>Search Results</WhiteText>
        {searchResults.users.length > 0 && <WhiteTextSmall>Users</WhiteTextSmall>}
        {searchResults.users.length > 0 ? (
          searchResults.users.map(user => (
            <UserContainer key={user.uid}>
              <UserLink to={`/profile/${user.username}`}>
                <ProfilePicture src={`https://www.iiicoast.com/${user.profile_picture_url}`} alt={user.username} />
                {user.username}
              </UserLink>
            </UserContainer>
          ))
        ) : (
          <>
          {/*<MutedText>No users found.</MutedText>*/}
          </>
        )}
        {searchResults.posts.length > 0 && <WhiteTextSmall>Posts</WhiteTextSmall>}
        <PostListContainer>
        {searchResults.posts.length > 0 ? (
          
          searchResults.posts.map(post => (
            <Post key={post.id} post={post} />
          ))
          
        ) : (
          <>
          {/*<MutedText>No posts found.</MutedText>*/}
          </>
        )}
        </PostListContainer>
      </Container>
      }
      </SearchCard>
      <SearchCard>
        <ExploreHeader>Trending</ExploreHeader>
        <SearchContainer>
          <PostListContainer>
            {trendingPosts.map(post => (
              <Post key={post.id} post={post} />
            ))}
          </PostListContainer>
        </SearchContainer>
      </SearchCard>
      {/* <SearchCard>
        <Recommended />
      </SearchCard> */}
      <SearchCard>
        <ExploreHeader>Recommended</ExploreHeader>
        <SearchContainer>
          <Container>
            {recommendedUsers.map(user => (
              <UserContainer key={user.uid}>
                <UserLink to={`/profile/${user.username}`}>
                  <ProfilePicture src={`https://www.iiicoast.com/${user.profile_picture_url}`} alt={user.username} />
                  {user.username}
                </UserLink>
              </UserContainer>
            ))}
          </Container>
        </SearchContainer>
      </SearchCard>
    </ExploreContainer>
  );
};

export default ExplorePage;
