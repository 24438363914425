import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaPlay, FaPause, FaTimes } from "react-icons/fa";

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const AudioPlayerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 1000;
  animation: ${props => props.$isVisible ? slideUp : slideDown} 0.3s ease-in-out;
  transform: translateY(${props => props.$isVisible ? '0' : '100%'});
  
`;

const SongInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.$sidebarMinimized ? '60px' : '260px'};
  transition: margin-left 0.3s ease;
  margin-right: -100px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
`;

const SongDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const SongTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #fff;
`;

const SongArtist = styled.span`
  font-size: 12px;
  color: #ccc;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-left: -80px;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-left: 80px;
  }
`;

const PlayPauseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const ProgressBar = styled.input`
  width: 300px;
  margin: 0 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #888;
  font-size: 18px;
  cursor: pointer;
`;

const AudioPlayer = ({ sidebarMinimized, currentSong, isPlaying, progress, togglePlayPause, handleProgressChange, closePlayer, isVisible }) => {
  if (!currentSong) return null;

  // Add this check to ensure progress is a valid number
  const validProgress = isNaN(progress) ? 0 : progress;

  return (
    <AudioPlayerContainer $isVisible={isVisible}>
      <SongInfo $sidebarMinimized={sidebarMinimized}>
        <Thumbnail src={currentSong.thumbnail_url} alt={`${currentSong.title} thumbnail`} />
        <SongDetails>
          <SongTitle>{currentSong.title}</SongTitle>
          <SongArtist>{currentSong.artist}</SongArtist>
        </SongDetails>
      </SongInfo>
      <Controls>
        <PlayPauseButton onClick={togglePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </PlayPauseButton>
        <ProgressBar
          type="range"
          min="0"
          max="100"
          value={validProgress}
          onChange={handleProgressChange}
        />
      </Controls>
      <CloseButton onClick={closePlayer}>
        <FaTimes />
      </CloseButton>
    </AudioPlayerContainer>
  );
};

export default AudioPlayer;