import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { BarLoader } from 'react-spinners';
import { motion } from 'framer-motion';
import {
  SpinnerContainer, 
} from '../../styles/shared/SharedStyles';

const ArticleCard = styled.div`
  background-color: #070707;
  margin: 15px;
  border-radius: 8px;
  padding: 20px;
  color: #999;

  @media (max-width: 768px) {
    margin: 5px;
  }
`;

const ArticleTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
`;

const ArticleImage = styled.img`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const ArticleContent = styled(motion.div)`
  // font-size: 16px;
  // line-height: 1.6;
`;

const ViewArticle = () => {
  const { articleId } = useParams();
  const location = useLocation();
  // const [originalPost, setOriginalPost] = useState(location.state?.post || null);
  const [originalPost] = useState(location.state?.post || null);
  const [articleContent, setArticleContent] = useState('');
  const [articleImageUrl, setArticleImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchArticleDetails = async () => {
      try {
        // Add a delay of 1 second (1000 milliseconds)
        await new Promise(resolve => setTimeout(resolve, 325));

        const response = await axios.get(`https://www.iiicoast.com/api/v2/view-article/${articleId}`);
        setArticleContent(response.data.content);
        setArticleImageUrl(response.data.article_image_url);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching article details:', error);
        setIsLoading(false);
      }
    };

    fetchArticleDetails();
  }, [articleId]);

  return (
    <ArticleCard>
      <ArticleTitle>{originalPost?.title}</ArticleTitle>
      {isLoading ? (
        <SpinnerContainer>
            <BarLoader color="#888888" loading={isLoading} />
        </SpinnerContainer>
      ) : (
        <>
          {articleImageUrl && <ArticleImage src={articleImageUrl} alt="Article image" />}
          <ArticleContent
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            dangerouslySetInnerHTML={{ __html: articleContent }}
          />
        </>
      )}
    </ArticleCard>
  );
};

export default ViewArticle;