import React, { useEffect, useContext, useState } from 'react';
import { AxiosContext } from '../App';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaAngleRight } from "react-icons/fa";
import FloatingActionButton from '../components/shared/FloatingActionButton';

const Container = styled.div`
  max-width: 800px;
  // margin: 0 auto;
  margin: 15px;
  padding: 20px;
  border-radius: 8px;
  background-color: #070707;

  @media (max-width: 768px) {
    background-color: #000;
    margin: 0;
  }
`;

const Header = styled.h2`
  text-align: left;
  color: #fff;
  margin-bottom: 5px;
`;

const ConversationList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConversationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #212121;
  cursor: pointer;

  &:hover {
    background-color: #212121;
  }
`;

const ProfilePic = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

const ConversationInfo = styled.div`
  flex-grow: 1;
`;

const Username = styled.h3`
  margin: 0;
  font-size: 16px;
  color: #fff;
`;

const RecentMessage = styled.p`
  margin: 5px 0 0;
  width: 200px;
  font-size: 14px;
  color: #888;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    width: 150px;
  }
`;

const Timestamp = styled.span`
  font-size: 12px;
  color: #999;
  margin-right: 10px;
`;

const MessagesPage = () => {
    const axiosInstance = useContext(AxiosContext);
    const [conversations, setConversations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await axiosInstance.get('/v2/get-user-messages');
                setConversations(response.data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();
    }, [axiosInstance]);

    const handleConversationClick = (convo) => {
        navigate(`/conversation/${convo.conversation_id}`, { state: { convo } });
    };

    const handleCFABClick = async (searchQuery) => {
      try {
        const response = await axiosInstance.post(`/v2/new_conversation/${searchQuery}`);
        
        if (response.data.cid) {
          // Navigate to the conversation page if cid exists
          navigate(`/conversation/${response.data.cid}`, { state: { convo: response.data } });
        } else {
          console.error("No conversation ID returned");
        }
      } catch (error) {
        if (error.response) {
          // Handle errors based on response status
          console.error(error.response.data.error || "An error occurred");
        } else {
          console.error("Error creating conversation:", error);
        }
      }
    };

    return (
        <Container>
            <Header>Messages</Header>
            <ConversationList>
                {conversations.map((conv) => (
                    <ConversationItem key={conv.conversation_id} onClick={() => handleConversationClick(conv)}>
                        {conv.other_prof_pic_url && (
                            <ProfilePic 
                                src={`https://www.iiicoast.com/backend-static/${conv.other_prof_pic_url}`} 
                                alt={conv.other_username} 
                            />
                        )}
                        <ConversationInfo>
                            <Username>{conv.other_username}</Username>
                            <RecentMessage>{conv.recent_message}</RecentMessage>
                        </ConversationInfo>
                        <Timestamp>{conv.timestamp}</Timestamp>
                        <FaAngleRight size={20} color="#999" />
                    </ConversationItem>
                ))}
            </ConversationList>
            <FloatingActionButton onSubmit={handleCFABClick}/>
        </Container>
    );
};

export default MessagesPage;