// PlaceholderPost.js
import React from 'react';
import styled from 'styled-components';
import { useFAB } from '../FABContext';
import CharacterCounter from './CharacterCounter';
import { FaInfoCircle } from 'react-icons/fa';
// import { motion, AnimatePresence } from 'framer-motion';

const PostContainer = styled.div`
  padding: ${props => props.$isOpen ? '16px' : '0'};
  border-bottom: 1px solid #212121;
  background-color: #111111;
  border-radius: 10px;
  color: white;
  margin-bottom: ${props => props.$isOpen ? '15px' : '0'};
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  max-height: ${props => props.$isOpen ? '112px' : '0'};
  opacity: ${props => props.$isOpen ? 1 : 0};
  position: relative;
  padding-bottom: ${props => {
    if (!props.$isOpen) return '0';
    if (props.$isOverLimit) return '70px';
    return '20px';
  }};
`;

// Need a little more padding-bottom when !isOverLimit and isOpen, additional variable?
// Changing 0 to 20px on existing padding-bottom applies when closed as well shifting layout

const TextArea = styled.textarea`
  width: 100%;
  min-height: 80px;
  background-color: #111111;
  color: white;
  font-family: 'Arial', sans-serif;
  border: none;
  resize: none;
  font-size: 16px;
  &:focus {
    outline: none;
  }
`;

const InfoPopup = styled.div`
  background-color: #031633;
  border: 1px solid #052e6b;
  color: #6ea8fe;
  padding: 10px;
  // margin-top: 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`;

const Icon = styled.span`
  margin-right: 10px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
`;

const CHARACTER_LIMIT = 280;

const PlaceholderPost = () => {
  const { isOpen, content, setContent, textAreaRef } = useFAB();
  const isOverLimit = content.length > CHARACTER_LIMIT;

  return (
    // <AnimatePresence>
      // {isOpen && (
        <PostContainer
          $isOpen={isOpen}
          $isOverLimit={isOverLimit}
          // initial={{ height: 0, opacity: 0 }}
          // animate={{ height: 'auto', opacity: 1 }}
          // exit={{ height: 0, opacity: 0 }}
          // transition={{
          //   type: "spring",
          //   stiffness: 500,
          //   damping: 40,
          // }}
        >
          <TextArea
            ref={textAreaRef}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="What's happening?"
            autoFocus
          />
          {isOpen && isOverLimit && (
            <InfoPopup>
              <Icon>
                <FaInfoCircle />
              </Icon>
              Posts are limited to 280 characters. If you'd like to write longer posts use articles.
            </InfoPopup>
          )}
          {isOpen && <CharacterCounter count={content.length} limit={CHARACTER_LIMIT} />}
        </PostContainer>
      // )}
    // </AnimatePresence>
  );
};

export default PlaceholderPost;