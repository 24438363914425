// components/TutorialSection.jsx
import React, { useState, useRef } from 'react';
import { 
  FaUserFriends, 
  FaUserEdit, 
  FaNewspaper, 
  FaBell, 
  FaArrowRight,
  FaArrowLeft 
} from 'react-icons/fa';
import styled from 'styled-components';

const tutorialItems = [
  {
    icon: FaUserFriends,
    label: "Follow 5 accounts",
    gradient: "linear-gradient(135deg, #DD47D0, #A38FFE, #3F59D0)",
    progress: "5 left" // This would be dynamic in real implementation
  },
  {
    icon: FaUserEdit,
    label: "Complete your profile",
    gradient: "linear-gradient(45deg, #73E1E6, #06BECE, #004AA9)",
    progress: "DONE"
  },
  {
    icon: FaNewspaper,
    label: "Follow 3 news sources",
    gradient: "linear-gradient(135deg, #FEC500, #F5B740, #F1212D)",
    progress: "2 left"
  },
  {
    icon: FaBell,
    label: "Turn on notifications",
    gradient: "linear-gradient(45deg, #C2124B, #E775C1, #B62EBD)",
    progress: "TODO"
  },
  {
    icon: FaArrowRight,
    label: "Learn More",
    gradient: "none",
    progress: null
  }
];

const Container = styled.div`
  padding: 10px;
  background: #070707;
  margin: 20px 0;
  color: #ffffff;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Header = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #ffffff;
`;

const ButtonLabel = styled.span`
  margin-top: 8px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
`;

const ScrollContainer = styled.div`
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    z-index: 1;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &:hover {
    .scroll-button-left {
      opacity: 1;
    }
    .scroll-button-right {
      opacity: 1;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  gap: 16px;
  transform: translateX(-${props => props.offset}px);
  touch-action: pan-y pinch-zoom; // Better touch handling
`;

const TutorialButton = styled.div`
  margin-bottom: 5px;
  width: 156px;
  height: 96px;
  border-radius: 12px;
  background: ${props => props.gradient};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;

  svg {
    font-size: 24px;
    color: white;
  }
`;

const Progress = styled.div`
  position: absolute;
  bottom: 8px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  background: ${props => props.isDone ? '#00ba7c' : 'rgba(0, 0, 0, 0.5)'};
  color: white;
`;

// Update ScrollButton to match dark theme
const ScrollButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.direction === 'right' ? 'right: 0;' : 'left: 0;'}
  background: #070707;
  border: 1px solid #333;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  color: #ffffff;
  opacity: 0;
  transition: opacity 0.2s ease;

  &:hover {
    background: #1a1a1a;
  }
`;

const TutorialSection = () => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const containerRef = useRef(null);
  const isMobile = window.innerWidth <= 768;
  const itemsPerView = isMobile ? 2 : 3;
  const maxIndex = tutorialItems.length - itemsPerView;

  // Minimum swipe distance (in px) to trigger scroll
  const minSwipeDistance = 50;

  const handleScroll = (direction) => {
    if (direction === 'right' && scrollIndex < maxIndex) {
      setScrollIndex(scrollIndex + itemsPerView);
    } else if (direction === 'left' && scrollIndex > 0) {
      setScrollIndex(scrollIndex - itemsPerView);
    }
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleScroll('right');
    } else if (isRightSwipe) {
      handleScroll('left');
    }
  };

  return (
    <Container>
      <Header>Let's get started!</Header>
      <ScrollContainer 
        ref={containerRef}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <ButtonsWrapper offset={scrollIndex * 172}>
          {tutorialItems.map((item, index) => (
            <div key={index}>
              <TutorialButton gradient={item.gradient}>
                <item.icon />
                {item.progress && (
                  <Progress isDone={item.progress === 'DONE'}>
                    {item.progress}
                  </Progress>
                )}
              </TutorialButton>
              <ButtonLabel>{item.label}</ButtonLabel>
            </div>
          ))}
        </ButtonsWrapper>
        {scrollIndex > 0 && (
          <ScrollButton 
            direction="left" 
            onClick={() => handleScroll('left')}
            className="scroll-button-left"
          >
            <FaArrowLeft />
          </ScrollButton>
        )}
        {scrollIndex < maxIndex && (
          <ScrollButton 
            direction="right" 
            onClick={() => handleScroll('right')}
            className="scroll-button-right"
          >
            <FaArrowRight />
          </ScrollButton>
        )}
      </ScrollContainer>
    </Container>
  );
};

export default TutorialSection;