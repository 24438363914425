import styled from "styled-components";

const PageContainer = styled.div`
  // padding: 1rem;
  // color: white;
  font-family: 'Poppins', sans-serif;
`;

const SectionBlock = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  background-color: #838484;
`;

const AboutBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 165px);
  width: 100%;
  background-color: #272727;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const SuperbNews = () => {
  return (
    <PageContainer>
      <AboutBlock>
        NEWS
      </AboutBlock>
      <SectionBlock>
        NEXT SECTION
      </SectionBlock>
    </PageContainer>
  );
};

export default SuperbNews;
