// SuperbSurfaces content.js

export const TESTIMONIAL_ONE = `
Wow! Excellent experience!! The guys were able to maneuver and fit our long countertop pieces into a narrow space without having to remove doors. They were super efficient, respectful and the installation was so smooth and fast! Countertops are gorgeous, super durable, we are so pleased!! Highly recommend!
`;

const TESTIMONIAL_ONE2 = `
We have high expectations when it comes to contractors and we require quality work — Michele exceeded those expectations in every way, showing impressive attention to detail, craftsmanship, and professionalism. Michele took the time to understand our requirements and the details of the job and then her team executed the job perfectly. She was communicative about scheduling, responsive via text/phone, and flexible about working around our schedule. Plus, she has an amazing attitude with a great sense of humor and is an absolute pleasure to work with.
`;

const TESTIMONIAL_TWO = `
Their name says it all, simply superb! Michele was great to work with. The quartzite slab she helped us find is beautiful, stunning, and the installers did a really great job of seaming and fitting all the stone so the counter tops look fantastic! From day one Michele became more than a salesperson for us; she became our advocate and expert consultant as well as a good friend. She went out of her way again and again to ensure that we got one of the most beautiful countertops that we have ever seen! She was with us every step of the way. It’s so obvious that she loves what she does and she is so very good at it! She is committed to delivering the best. The total experience with Superb Surfaces was top notch! The constant communication and follow up that Michele gives is second to none. Thanks Michele and Superb Surfaces! The Ericksons
`;

const TESTIMONIAL_THREE = `
The people at Superb Surfaces are great. Julia, Carla, and Gary are great to work with and listen closely to your needs and also know where to direct you if you don’t know what you need. I highly recommend working with them on any of your remodeling needs.
`;

const TESTIMONIAL_FOUR = `
Wow! Excellent experience!! The guys were able to maneuver and fit our long countertop pieces into a narrow space without having to remove doors. They were super efficient, respectful and the installation was so smooth and fast! Countertops are gorgeous, super durable, we are so pleased!! Highly recommend!
`;

const TESTIMONIAL_FIVE = `
We had an amazing experience with superb services! Installation was quick and seamless. We would recommend them to anyone.
`;

export const HOME_BLURB_ONE = `
Although Superb Surfaces is a new company, we are the merging of 2 former companies drawing on over 30 years of experience. We saw a need in the industry that was lacking quality products, great lead times, reasonable prices with genuinely outstanding customer service. Our “hands-on” owners take pride in being available by email or cell phone seven days a week.
Trusting Superb Surfaces with an opportunity to earn your business will enable you to see why so many major suppliers highly endorse us, and some have given us some exclusive programs. Our very experienced staff are passionate about getting your project done right, on schedule, and exceed your expectations – that is our mission!
`;

export const HOME_BLURB_TWO = `
Our Robotic high-tech equipment enables us to perform more efficiently and produce a more precise product.
Education is vital as we have opened our doors to the Kitchen and Bath college students for product training as well as demonstrate one of the most advanced machines available in our industry.
This allows us to make the safety and eco-friendly practices a top priority while purchasing full truckloads and Importing directly from overseas assures you will get high quality at the best price.
`;

export const HOME_BLURB_THREE = `
We are of a select few fabricators that fabricate and install ALL products:
– Laminate, Solid Surface, Granite, and Natural stones, Quartz, Sintered / Porcelain
In a very short time, Superb Surfaces has grown to service clients/projects throughout the Chicagoland area as well as:
Denver, Texas, California, Las Vegas, New Orleans, North / South Carolina, Florida.
We look forward to speaking or meeting with you to discuss your project, large or small, and we know you will NOT be disappointed!
`;

export const PROJECTS_SUBTITLE = `
Starting from a small vanity top and ending in big multi-residential high-rises, every project is a custom approach. Combining customer feedback and previous experience has brought us compelling results.
`;

export const PROJECTS = [
  {
    title: "PURE WHITE QUARTZ PROJECT IN SKOKIE",
    image: "https://www.iiicoast.com/backend-static/upload/kitchen-3689932_1280.jpg",
    stone_type: "Quartz",
    dimension: 42,
    color: "Pure White"
  },
  {
    title: "LIEM BLACK PORCELAIN PROJECT IN CHICAGO",
    image: "https://www.iiicoast.com/backend-static/upload/Depositphotos_49110413_ds.jpg",
    stone_type: "Porcelain",
    dimension: 35,
    color: "Liem Black"
  },
  {
    title: "MARQUINA MIDNIGHT QUARTZ IN LISLE",
    image: "https://www.iiicoast.com/backend-static/upload/Depositphotos_65501599_ds_Easy-Resize.com_.jpg",
    stone_type: "Quartz", // originally says Lisle on superbstone.com
    dimension: 53,
    color: "Marquina Midnight"
  }
]

export const TESTIMONIALS = [
  {
    content: TESTIMONIAL_ONE2,
    author: "Daniel Huttner"
  },
  {
    content: TESTIMONIAL_TWO,
    author: "Tyler Erickson"
  },
  {
    content: TESTIMONIAL_THREE,
    author: "Rick Mearse"
  },
  {
    content: TESTIMONIAL_FOUR,
    author: "Sarah Padrones"
  },
  {
    content: TESTIMONIAL_FIVE,
    author: "Catherine Rice"
  }
]

// pictures/videos for homepage
//
// https://www.iiicoast.com/backend-static/upload/yellow_qota.png
// https://www.iiicoast.com/backend-static/upload/google-review-icon.png
// https://www.iiicoast.com/backend-static/upload/background-quote.png
//
// background-video src url
// https://www.iiicoast.com/backend-static/video/background-video.mp4

// stones background image url
// https://www.iiicoast.com/backend-static/upload/peak_mountainbg.png

// how does it work background image url
// https://www.iiicoast.com/backend-static/upload/preview.jpg

