// CharacterCounter.js
import React from 'react';
import styled from 'styled-components';

const CounterContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
`;

const CircleProgress = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

const CircleBackground = styled.circle`
  fill: none;
  stroke: #333;
  stroke-width: 2;
`;

const CircleFill = styled.circle`
  fill: none;
  stroke: ${props => props.$isOverLimit ? '#ff4136' : '#0074d9'};
  stroke-width: 2;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;
`;

const CountText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: ${props => props.$isOverLimit ? '#ff4136' : '#0074d9'};
`;

const CharacterCounter = ({ count, limit }) => {
  const isOverLimit = count > limit;
  const percentage = Math.min(count / limit, 1);
  const circumference = 2 * Math.PI * 14;
  const strokeDashoffset = circumference * (1 - percentage);

  return (
    <CounterContainer>
      <CircleProgress viewBox="0 0 32 32">
        <CircleBackground cx="16" cy="16" r="14" />
        <CircleFill
          cx="16"
          cy="16"
          r="14"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          $isOverLimit={isOverLimit}
        />
      </CircleProgress>
      {isOverLimit && (
        <CountText $isOverLimit={isOverLimit}>-{count - limit}</CountText>
      )}
    </CounterContainer>
  );
};

export default CharacterCounter;