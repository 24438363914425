// ProfileStyles.js
import styled from 'styled-components';

export const ProfileCard = styled.div`
  background-color: #070707;
  margin: 15px;
  // border: 5px solid #070707;
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional: adds a shadow for a lifted card effect

  @media (max-width: 768px) {
    margin: 5px;
  }
`;

export const ProfileContainer = styled.div`
  padding: 20px;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const CoverPhoto = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`;

export const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: -90px;
  border: 5px solid #070707; // Adjust the border color as needed
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  // z-index: 1; /* Ensure profile picture is on top */

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
    margin-top: -70px;
  }
`;

export const ProfileHeader = styled.h3`
  margin-top: 15px;
  color: #fff; // Adjust the color as needed
`;

export const BioText = styled.p`
  color: #999; // Adjust the color as needed
`;