import { useState } from "react";
import styled from "styled-components";
import { FaHeadset, FaMapMarkedAlt, FaPlayCircle, FaArrowAltCircleRight } from "react-icons/fa";
import BusinessLocationMap from "../components/BusinessLocationMap";

const PageContainer = styled.div`
  // padding: 1rem;
  // color: white;
  font-family: 'Poppins', sans-serif;
`;

const Container = styled.div`
  // padding: 1rem;
  color: white;
`;

const MaxWidthContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
`;

const MaxWidthContentContainer = styled.div`
  width: 100%;
  height: 100%;
  // margin: 3rem;
  max-width: 1300px;
  display: flex;
  // flex-direction: column;
  justify-content: left;
  // align-items: left;
  color: white;
`;


const Header = styled.div`
  height: 100px;
  width: 100%;
  // position: fixed;
  // top: 0px;
  // background-color: #121212;
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

const SubHeader = styled.div`
  height: 70px;
  width: 100%;
  position: sticky;
  top: 0px;
  background-color: #3a3a3a;
  display: flex;
  // gap: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  z-index: 3;
`;

const SectionBlock = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  background-color: #838484;
`;

const ContactBlock = styled.div`
  // margin-top: 200px;
  height: 550px;
  width: 100%;
  background-color: #838484;
`;

const ContactContainer = styled.div`
  height: 100%;
  display: flex;
  // mobile media query column direction
`;

const ContactForm = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1a1a;
`;

const ContactMap = styled.div`
  width: 50%;
  height: 100%;
  background-color: yellow;
`;

// https://www.iiicoast.com/backend-static/upload/yellow_qota.png
// https://www.iiicoast.com/backend-static/upload/google-review-icon.png
// https://www.iiicoast.com/backend-static/upload/background-quote.png

const TestimonialsBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 400px);
  width: 100%;
  background-color: #fff;
  background-image: url(https://www.iiicoast.com/backend-static/upload/background-quote.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 25%;
  border-top: 2px solid rgba(0,0,0,0.1);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuoteContainer = styled.div`
  max-width: 650px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const QuoteText = styled.div`
  font-size: 20px;
  margin-bottom: 25px;
`;

const QuoteImg = styled.img`
  height: 50px;
  width: 50px;
  margin: auto;
  margin-bottom: 10px;
`;

const QuoteAuthor = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const QuoteGoogleImg = styled.img`
  height: 100px;
  width: 100px;
  margin: auto;
`;

const MoreInfoBlock = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  // background-color: #838484;
  background-image: url(https://www.iiicoast.com/backend-static/upload/preview.jpg);
  background-position: center;
  // background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
  font-weight: 700;

  /* Dark overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 1;
  }

  /* Ensures text is above overlay */
  > * {
    position: relative;
    z-index: 2;
  }
`;

const MainFooter = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  background-color: #838484;
`;

const SubFooter = styled.div`
  // margin-top: 200px;
  height: 70px;
  width: 100%;
  background-color: #838484;
`;

const InfoBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 400px);
  width: 100%;
  background-color: #fff;
  color: #444;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  white-space: pre-line;
`;

const ProjectsBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 400px);
  width: 100%;
  background-color: #838484;
`;

const ProjectsHeader = styled.div`
  // margin-top: 200px;
  height: 350px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-weight: 700;
`;

const ProjectsSubTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  max-width: 1300px;
`;

const IntroSectionBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 170px);
  width: 100%;
  background-color: dark-red;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Dark overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 0; /* Below content but above video */
  }
`;

const IntroBlurb = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 50px;
`;

const IntroTitle = styled.div`
  margin-top: -70px;
  font-size: 74px;
  font-weight: 500;
  line-height: 84px;
  letter-spacing: -2px;
  z-index: 2;
  // white-space: nowrap;
`;

const IntroSubTitle = styled.div`
  margin-top: 10px;
  width: 804px;
  font-size: 44px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -2px;
  z-index: 2;
`;

const IntroButton = styled.div`
  height: auto;
  width: fit-content;
  background-color: #fff;
  text-transform: uppercase;
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 27px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 15px;
  padding: 9px 38px;
  margin-top: 20px;
  z-index: 2;
  color: black;

  svg {
    margin-top: -1px;
    margin-left: 3px;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const StoneSectionBlock = styled.div`
  position: relative;
  // margin-top: 200px;
  //height: 450px;
  height: calc(100vh - 120px);
  width: 100%;
  background-color: #272727;
  background-image: url(https://www.iiicoast.com/backend-static/upload/peak_mountainbg.png);
  background-position: bottom;
  // wide screen media query
  // background-position-y: 150%;
  // background-position-x: center;
  background-repeat: no-repeat;
  // background-repeat: repeat-x;
  
  background-size: 100% auto;

  display: flex;
  justify-content: center;

  /* Gradient Overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, white 100%);
    z-index: 1;
  }
`;

const StoneSectionContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  height: 100%;
  padding 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StoneText = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const StoneTitle = styled.div`
  font-size: 30px;
  color: black;
  font-weight: 700;
`;

const StoneSubTitle = styled.div`
  color: #ffd61f;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  svg {
    margin-left: 3px;
    margin-top: -1px;
  }
`;

const StoneVisual = styled.div`
  padding: 10px;
`;

const HeaderLogo = styled.img`
  // width: 200px;
  padding-left: 17px;
  height: 100px;
`;

const HeaderContactSection = styled.div`
  // padding: 10px;
  display: flex;
  gap: 30px;
  height: 100%;
  padding-right: 30px;
`;

const HeaderContact = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  svg {
    font-size: 32px;
    color: #d6b31c;
  }
`;

const HeaderContactText = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;

const NavSection = styled.div`
  display: flex;
  gap: 25px;
  height: 100%;
  align-items: center;
  padding-left: 30px;
`;

const NavItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const NavItemActive = styled.div`
  border-bottom: 3px solid #ffd61f;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
`;

const NavButton = styled.div`
  height: 100%;
  background-color: #ffd61f;
  width: 200px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
`;

// background-video src url
// https://www.iiicoast.com/backend-static/video/background-video.mp4

// stones background image url
// https://www.iiicoast.com/backend-static/upload/peak_mountainbg.png

// how does it work background image url
// https://www.iiicoast.com/backend-static/upload/preview.jpg

const SectionText = styled.div`
  z-index: 2;
`;

const InfoText = styled.div`
  max-width: 1300px;
  margin-bottom: 15px;
  z-index: 2;
`;

const YellowButton = styled.div`
  height: auto;
  width: fit-content;
  background-color: #ffd61f;
  text-transform: uppercase;
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 27px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 15px;
  padding: 9px 38px;
  margin-top: 20px;
  z-index: 2;
  color: black;
  cursor: pointer;
`;

const SuperbPage = () => {
  const quoteOne = `
Wow! Excellent experience!! The guys were able to maneuver and fit our long countertop pieces into a narrow space without having to remove doors. They were super efficient, respectful and the installation was so smooth and fast! Countertops are gorgeous, super durable, we are so pleased!! Highly recommend!
  `;
  const paragraphOne = `
    Although Superb Surfaces is a new company, we are the merging of 2 former companies drawing on over 30 years of experience. We saw a need in the industry that was lacking quality products, great lead times, reasonable prices with genuinely outstanding customer service. Our “hands-on” owners take pride in being available by email or cell phone seven days a week.
Trusting Superb Surfaces with an opportunity to earn your business will enable you to see why so many major suppliers highly endorse us, and some have given us some exclusive programs. Our very experienced staff are passionate about getting your project done right, on schedule, and exceed your expectations – that is our mission!
    `;

  const paragraphTwo = `
  Our Robotic high-tech equipment enables us to perform more efficiently and produce a more precise product.
Education is vital as we have opened our doors to the Kitchen and Bath college students for product training as well as demonstrate one of the most advanced machines available in our industry.
This allows us to make the safety and eco-friendly practices a top priority while purchasing full truckloads and Importing directly from overseas assures you will get high quality at the best price.
    `;

  const paragraphThree = `
  We are of a select few fabricators that fabricate and install ALL products:
– Laminate, Solid Surface, Granite, and Natural stones, Quartz, Sintered / Porcelain
In a very short time, Superb Surfaces has grown to service clients/projects throughout the Chicagoland area as well as:
Denver, Texas, California, Las Vegas, New Orleans, North / South Carolina, Florida.
We look forward to speaking or meeting with you to discuss your project, large or small, and we know you will NOT be disappointed!
  `;

  return (
    <PageContainer>
      <Header>
        <MaxWidthContainer>
        <HeaderLogo src="https://www.iiicoast.com/backend-static/upload/logo_retina.png" />
        <HeaderContactSection>
          <HeaderContact>
            <FaHeadset />
            <HeaderContactText>
              <span>
                630-475-8644
              </span>
              <span>
                info@superbsurfaces.com
              </span>
            </HeaderContactText>
          </HeaderContact>
          <HeaderContact>
            <FaMapMarkedAlt />
            <HeaderContactText>
              <span>
                1160 Greenleaf Ave
              </span>
              <span>
                Elk Grove Village, IL 60007
              </span>
            </HeaderContactText>
          </HeaderContact>
        </HeaderContactSection>
        </MaxWidthContainer>
      </Header>
      <SubHeader>
        <MaxWidthContainer>
        <NavSection>
          <NavItemActive>Home</NavItemActive>
          <NavItem>About Us</NavItem>
          <NavItem>Materials</NavItem>
          <NavItem>Services</NavItem>
          <NavItem>Projects</NavItem>
          <NavItem>How It Works</NavItem>
          {/* Blog (dropdown) - FAQ - Contact */}
        </NavSection>
        <NavButton>
          Free Consultation
        </NavButton>
        </MaxWidthContainer>
      </SubHeader>
      <IntroSectionBlock>
        <VideoBackground autoPlay loop muted playsInline>
          <source src="https://www.iiicoast.com/backend-static/video/background-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </VideoBackground>
        <MaxWidthContentContainer>
          <IntroBlurb>
            <IntroTitle>Here we can!</IntroTitle>
            <IntroSubTitle>
              Design countertops with robotic technology,
              <br></br>
              any top, any where.
            </IntroSubTitle>
            <IntroButton>
              Play Video
              <FaPlayCircle />
            </IntroButton>
          </IntroBlurb>
        </MaxWidthContentContainer>
      </IntroSectionBlock>
      <StoneSectionBlock>
        <StoneSectionContainer>
          <StoneText>
            <StoneTitle>
              See Our Products
            </StoneTitle>
            <StoneSubTitle>
              See All Stones 
              <FaArrowAltCircleRight />
            </StoneSubTitle>
          </StoneText>
          <StoneVisual>
            Stone Images Here
          </StoneVisual>
        </StoneSectionContainer>
      </StoneSectionBlock>
      <ProjectsHeader>
        <SectionText>Projects</SectionText>
        <ProjectsSubTitle>
          Starting from a small vanity top and ending in big multi-residential high-rises, every project is a custom approach. Combining customer feedback and previous experience has brought us compelling results.
        </ProjectsSubTitle>
      </ProjectsHeader>
      <ProjectsBlock>
        <SectionText>Projects Component Here</SectionText>
      </ProjectsBlock>
      <InfoBlock>
        <InfoText>{paragraphOne}</InfoText>
        <InfoText>{paragraphTwo}</InfoText>
        <InfoText>{paragraphThree}</InfoText>
      </InfoBlock>
      <TestimonialsBlock>
        <QuoteContainer>
          <QuoteGoogleImg src="https://www.iiicoast.com/backend-static/upload/google-review-icon.png" />
          <QuoteText>{quoteOne}</QuoteText>
          <QuoteImg src="https://www.iiicoast.com/backend-static/upload/yellow_qota.png" />
          <QuoteAuthor>Sarah Padrones</QuoteAuthor>
        </QuoteContainer>  
      </TestimonialsBlock>
      <MoreInfoBlock>
        <SectionText>How does it Work?</SectionText>
        <YellowButton>See All Steps</YellowButton>
      </MoreInfoBlock>
      <ContactBlock>
        <ContactContainer>
          <ContactForm>
            {/*<FormHeader>Contact Us</FormHeader>
            <ContactRow></ContactRow>*/}
            Contact Form
          </ContactForm>
          <ContactMap>
            <BusinessLocationMap />
          </ContactMap>
        </ContactContainer>
      </ContactBlock>
      <MainFooter>
        <SectionText>Main Footer</SectionText>
      </MainFooter>
      <SubFooter>
        <SectionText>SubFooter</SectionText>
      </SubFooter>
    </PageContainer>
  );
};

export default SuperbPage;
