import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Styled Components
const TokensContainer = styled.div`
  padding: 1rem 0 0;
  // background: #121212;
  color: #e0e0e0;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TokenGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  //gap: 1rem;
  margin-top: 1rem;
`;

const TokenCard = styled.div`
  background: #070707;
  // border-radius: 12px;
  border: 1px solid #222;
  padding: 1rem;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  }
`;

const TokenHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const TokenImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background: #2a2a2a;
`;

const TokenInfo = styled.div`
  margin-left: 1rem;
`;

const TokenName = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: #ffffff;
`;

const TokenSymbol = styled.p`
  font-size: 0.8rem;
  color: #9e9e9e;
  margin: 0.2rem 0 0 0;
`;

const TokenBalance = styled.div`
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 1.2rem;
  color: #00bcd4;
`;

const NoTokensMessage = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: #9e9e9e;
  font-size: 1.1rem;
`;

const TokensHeading = styled.h2`
  padding-left: 10px;
  font-size: 1.4rem;
  color: #ffffff;
  margin-bottom: 1rem;
`;

// Main Component
const TokenHoldings = ({ tokens, loading }) => {
  const navigate = useNavigate();

  if (loading) {
    return <TokensContainer>Loading your tokens...</TokensContainer>;
  }

  const handleTokenHeaderClick = (token) => {
    // console.log(token)
    if (token.metadata.mint) {
      navigate(`/crypto/token/${token.metadata.mint}`);
    }
  };

  return (
    <TokensContainer>
  <TokensHeading>Token Holdings</TokensHeading>
    {tokens && tokens.length > 0 ? (
      <TokenGrid>
        {tokens.map((token, index) => {
          const amount = token.account.data.parsed.info.tokenAmount.uiAmount;
          const metadata = token.metadata?.external_metadata || {};
          const fallbackName = token.metadata?.data.name || 'Unknown Token'; //  || 
          const fallbackSymbol = token.metadata?.data.symbol.toUpperCase() || ''; // token.metadata?.symbol || 
        
          return (
            <TokenCard key={index}>
              <TokenHeader onClick={() => handleTokenHeaderClick(token)}>
                <TokenImage 
                  src={metadata.image || 'https://placehold.co/50x50?text=?'}
                  alt={metadata.name || fallbackName}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'https://placehold.co/50x50?text=?';
                  }}
                />
                <TokenInfo>
                  <TokenName>{metadata.name || fallbackName}</TokenName>
                  <TokenSymbol>{metadata.symbol || fallbackSymbol}</TokenSymbol>
                </TokenInfo>
              </TokenHeader>
              <TokenBalance>
                {amount.toLocaleString()} {metadata.symbol || fallbackSymbol}
              </TokenBalance>
            </TokenCard>
          );
        })}
      </TokenGrid>
    ) : (
      <NoTokensMessage>No tokens found in your wallet</NoTokensMessage>
    )}
</TokensContainer>
  );
};

export default TokenHoldings;
