import React, { useState, useEffect } from 'react'; //, useRef
import axios from 'axios';
// import useWindowSize from '../hooks/useWindowSize';
import styled from 'styled-components';// , { keyframes }
// import { Link } from 'react-router-dom';
import FloatingActionButton from '../components/shared/FloatingActionButton';
import { PageHeader, TabContainer, Tab, SongList, SongItem, SongThumbnail, ThumbnailImage, SongInfo, SongTitle, SongArtist, Section, PlaylistGrid, PlaylistItem, PlaylistThumbnail, PlaylistName, SongCount } from '../styles/music/MusicStyles';
import { useAudioPlayer } from "../AudioPlayerContext";
// import { FaPlus } from "react-icons/fa";// , FaPlay, FaPause 

const MusicContainer = styled.div`
  padding: 0 20px 0;
  color: #ffffff;
  overflow-x: hidden;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const MusicPage = () => {
  const [activeTab, setActiveTab] = useState('songs');
  const [individualSongs, setIndividualSongs] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const { playSong } = useAudioPlayer();

  useEffect(() => {
    fetchMusicData();
  }, []);

  const fetchMusicData = async () => {
    try {
      const response = await axios.get('/api/get-music-data');
      setIndividualSongs(response.data.individual_songs);
      setPlaylists(response.data.playlists);
    } catch (error) {
      console.error('Error fetching music data:', error);
    }
  };

  return (
    <MusicContainer>
      <PageHeader>
        <TabContainer>
          <Tab 
            $active={activeTab === 'songs'} 
            onClick={() => setActiveTab('songs')}
          >
            Songs
          </Tab>
          <Tab 
            $active={activeTab === 'playlists'} 
            onClick={() => setActiveTab('playlists')}
          >
            Playlists
          </Tab>
        </TabContainer>
        {/* <PlusButton>
          <FaPlus />
          <PlusButtonLabel>Add music</PlusButtonLabel>
        </PlusButton> */}
      </PageHeader>
      <Section>
        {activeTab === 'songs' ? (
          <SongList>
            {individualSongs.map((song) => (
              <SongItem
                key={song.sid}
                onClick={() => playSong(song, individualSongs)}
              >
                <SongThumbnail>
                  <ThumbnailImage src={song.thumbnail_url} alt={`${song.title} thumbnail`} />
                </SongThumbnail>
                <SongInfo>
                  <SongTitle>{song.title}</SongTitle>
                  <SongArtist>{song.artist}</SongArtist>
                </SongInfo>
              </SongItem>
            ))}
          </SongList>
        ) : (
          <PlaylistGrid>
            {playlists.map((playlist) => (
              <PlaylistItem key={playlist.pid} to={`/music/playlist/${playlist.pid}`}>
                <PlaylistThumbnail src={playlist.thumbnail_url} alt={playlist.name} />
                <PlaylistName>{playlist.name}</PlaylistName>
                <SongCount>{playlist.song_count} songs</SongCount>
              </PlaylistItem>
            ))}
          </PlaylistGrid>
        )}
      </Section>
      <FloatingActionButton showDraftsButton={false} showTextArea={false} tt_hover="Upload Music" tt_confirm="Confirm Upload"/>
    </MusicContainer>
  );
};

export default MusicPage;