import { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";


// Manually set the marker icon
const markerIcon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
  iconSize: [25, 41], // Default Leaflet marker size
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
  shadowSize: [41, 41],
});


const BusinessLocationMap = () => {
  useEffect(() => {
    // Initialize the map
    const map = L.map("map", {attributionControl: false}).setView([42.0039, -87.9693], 13); // Set to business coordinates

    // // Add OpenStreetMap tiles (Free alternative to Google Maps)
    // L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    //   attribution: "© OpenStreetMap contributors",
    // }).addTo(map);

    // Use a grayscale tile layer
    L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png", {
      attribution: "© <a href='https://carto.com/'>Carto</a> contributors",
    }).addTo(map);

    // Add marker for business location
    L.marker([42.0039, -87.9693], { icon: markerIcon })
      .addTo(map)
      .bindPopup("Superb Surfaces & Design")
      .openPopup();

    // Cleanup function
    return () => {
      map.remove();
    };
  }, []);

  return <div id="map" style={{ width: "100%", height: "100%", zIndex: "2" }}></div>;
};

export default BusinessLocationMap;
