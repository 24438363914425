// import React, { useState } from "react";
// import styled, { css, keyframes } from "styled-components";
// import { TESTIMONIALS } from "../../data/superb/content";
// import { ChevronLeft, ChevronRight } from "lucide-react";

// const Container = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   width: 100%;
//   max-width: 800px;
//   margin: auto;
//   padding: 2rem;
// `;

// const SlideTrack = styled.div`
//   display: flex;
//   transition: transform 0.5s ease-in-out;
// `;

// const TestimonialCard = styled.div`
//   flex: 0 0 100%;
//   opacity: ${props => (props.active ? 1 : 0.5)};
//   transform: scale(${props => (props.active ? 1 : 0.95)});
//   transition: opacity 0.5s ease, transform 0.5s ease;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 1rem;
//   text-align: center;
// `;

// const SourceImage = styled.img`
//   width: 40px;
//   margin-bottom: 1rem;
// `;

// const QuoteIcon = styled.img`
//   width: 30px;
//   margin-top: 1rem;
// `;

// const Content = styled.p`
//   font-size: 1.1rem;
//   color: #474747;
//   background: transparent;
// `;

// const Author = styled.p`
//   font-weight: bold;
//   color: #000;
//   margin-top: 0.5rem;
// `;

// const ChevronButton = styled.button`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   background: none;
//   border: none;
//   color: #000;
//   cursor: pointer;
//   z-index: 1;

//   ${props =>
//     props.left &&
//     css`
//       left: 0;
//     `}
//   ${props =>
//     props.right &&
//     css`
//       right: 0;
//     `}
// `;

// function TestimonialsCarousel() {
//   const [index, setIndex] = useState(0);

//   const handlePrev = () => {
//     setIndex(prev => (prev === 0 ? TESTIMONIALS.length - 1 : prev - 1));
//   };

//   const handleNext = () => {
//     setIndex(prev => (prev === TESTIMONIALS.length - 1 ? 0 : prev + 1));
//   };

//   const getSlideStyle = i => {
//     const offset = i - index;
//     return {
//       transform: `translateX(${offset * 100}%)`,
//     };
//   };

//   return (
//     <Container>
//       <ChevronButton left onClick={handlePrev}>
//         <ChevronLeft size={32} />
//       </ChevronButton>

//       <SlideTrack>
//         {TESTIMONIALS.map((testimonial, i) => (
//           <TestimonialCard key={i} style={getSlideStyle(i)} active={i === index}>
//             <SourceImage
//               src="https://www.iiicoast.com/backend-static/upload/google-review-icon.png"
//               alt="Google Review"
//             />
//             <Content>"{testimonial.content}"</Content>
//             <QuoteIcon
//               src="https://www.iiicoast.com/backend-static/upload/yellow_qota.png"
//               alt="Quote Icon"
//             />
//             <Author>{testimonial.author}</Author>
//           </TestimonialCard>
//         ))}
//       </SlideTrack>

//       <ChevronButton right onClick={handleNext}>
//         <ChevronRight size={32} />
//       </ChevronButton>
//     </Container>
//   );
// }

// export default TestimonialsCarousel;

// import React, { useState } from "react";
// import styled, { css } from "styled-components";
// import { TESTIMONIALS } from "../../data/superb/content";
// import { ChevronLeft, ChevronRight } from "lucide-react";

// const Container = styled.div`
//   position: relative;
//   width: 100%;
//   max-width: 800px;
//   margin: auto;
//   overflow: hidden;
//   padding: 2rem;
// `;

// const CarouselWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
// `;

// const Slides = styled.div`
//   display: flex;
//   transition: transform 0.5s ease;
// `;

// const Slide = styled.div`
//   flex: 0 0 100%;
//   max-width: 100%;
//   text-align: center;
//   padding: 1rem;
//   transform: scale(${props => (props.active ? 1 : 0.9)});
//   opacity: ${props => (props.active ? 1 : 0.5)};
//   transition: transform 0.5s ease, opacity 0.5s ease;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const SourceImage = styled.img`
//   width: 40px;
//   margin-bottom: 1rem;
// `;

// const QuoteIcon = styled.img`
//   width: 30px;
//   margin-top: 1rem;
// `;

// const Content = styled.p`
//   font-size: 1.1rem;
//   color: #474747;
//   background: transparent;
// `;

// const Author = styled.p`
//   font-weight: bold;
//   color: #000;
//   margin-top: 0.5rem;
// `;

// const ChevronButton = styled.button`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   background: none;
//   border: none;
//   cursor: pointer;
//   z-index: 10;
//   padding: 1rem;

//   ${props =>
//     props.left &&
//     css`
//       left: 0;
//     `}

//   ${props =>
//     props.right &&
//     css`
//       right: 0;
//     `}
// `;

// function TestimonialsCarousel() {
//   const [index, setIndex] = useState(0);
//   const total = TESTIMONIALS.length;

//   const prevIndex = (index - 1 + total) % total;
//   const nextIndex = (index + 1) % total;

//   const handlePrev = () => {
//     setIndex(prev => (prev - 1 + total) % total);
//   };

//   const handleNext = () => {
//     setIndex(prev => (prev + 1) % total);
//   };

//   const visibleSlides = [prevIndex, index, nextIndex];

//   return (
//     <Container>
//       <ChevronButton left onClick={handlePrev}>
//         <ChevronLeft size={32} />
//       </ChevronButton>

//       <CarouselWrapper>
//         <Slides>
//           {visibleSlides.map((i, slideIdx) => (
//             <Slide key={i} active={i === index}>
//               <SourceImage
//                 src="https://www.iiicoast.com/backend-static/upload/google-review-icon.png"
//                 alt="Google Review"
//               />
//               <Content>"{TESTIMONIALS[i].content}"</Content>
//               <QuoteIcon
//                 src="https://www.iiicoast.com/backend-static/upload/yellow_qota.png"
//                 alt="Quote Icon"
//               />
//               <Author>{TESTIMONIALS[i].author}</Author>
//             </Slide>
//           ))}
//         </Slides>
//       </CarouselWrapper>

//       <ChevronButton right onClick={handleNext}>
//         <ChevronRight size={32} />
//       </ChevronButton>
//     </Container>
//   );
// }

// export default TestimonialsCarousel;

import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import useEmblaCarousel from "embla-carousel-react";
import { TESTIMONIALS } from "../../data/superb/content";
import { ChevronLeft, ChevronRight } from "lucide-react";

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  position: relative;
`;

const Container = styled.div`
  display: flex;
`;

const Slide = styled.div`
  position: relative;
  min-width: 70%;
  margin: 0 10px;
  opacity: ${props => (props.$active ? 1 : 0.5)};
  transform: scale(${props => (props.$active ? 1 : 0.95)});
  transition: all 0.4s ease;
  background: transparent;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SourceImage = styled.img`
  width: 40px;
  margin-bottom: 1rem;
`;

const QuoteIcon = styled.img`
  width: 30px;
  margin-top: 1rem;
`;

const Content = styled.p`
  font-size: 1.1rem;
  color: #474747;
`;

const Author = styled.p`
  font-weight: bold;
  color: #000;
  margin-top: 0.5rem;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  z-index: 2;
  cursor: pointer;

  ${props => props.$left && `left: 0;`}
  ${props => props.$right && `right: 0;`}
`;

const options = {
  startIndex: 1, // Starts on the second slide
  loop: false,   // Optional, already disabled
  align: 'center', // Optional: helps center the slide
  // containscroll: false,
}

function TestimonialsCarousel() {
  const [emblaRef, emblaApi] = useEmblaCarousel(options); // { loop: true }
  const [selectedIndex, setSelectedIndex] = useState(0)

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
  
    const onSelect = () => {
      const index = emblaApi.selectedScrollSnap();
      setSelectedIndex(index);
    };
  
    emblaApi.on('select', onSelect);
    onSelect(); // Initial trigger
  }, [emblaApi]);

  return (
    <Viewport>
      <NavButton $left onClick={scrollPrev}>
        <ChevronLeft size={32} />
      </NavButton>

      <div ref={emblaRef}>
        <Container>
          {TESTIMONIALS.map((t, i) => (
            <Slide key={i} $active={i === selectedIndex}>
              <SourceImage
                src="https://www.iiicoast.com/backend-static/upload/google-review-icon.png"
                alt="Google Review"
              />
              <Content>"{t.content}"</Content>
              <QuoteIcon
                src="https://www.iiicoast.com/backend-static/upload/yellow_qota.png"
                alt="Quote Icon"
              />
              <Author>{t.author}</Author>
            </Slide>
          ))}
        </Container>
      </div>

      <NavButton $right onClick={scrollNext}>
        <ChevronRight size={32} />
      </NavButton>
    </Viewport>
  );
}

export default TestimonialsCarousel;


// import React, { useState } from 'react';
// import styled, { css, keyframes } from 'styled-components';
// import { ChevronLeft, ChevronRight } from 'lucide-react';
// import { TESTIMONIALS } from '../../data/superb/content';

// const fadeIn = keyframes`
//   from { opacity: 0; transform: translateX(30px); }
//   to { opacity: 1; transform: translateX(0); }
// `;

// const CarouselContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   margin: 0 auto;
//   position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
//   background: #f6f6f6;
//   padding: 80px 0;
// `;

// const SlideWrapper = styled.div`
//   display: flex;
//   width: 70%;
//   transition: transform 0.6s ease;
// `;

// const Slide = styled.div`
//   min-width: 100%;
//   animation: ${fadeIn} 0.6s ease;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 40px;
// `;

// const Card = styled.div`
//   background: white;
//   padding: 30px;
//   border-radius: 16px;
//   box-shadow: 0 4px 12px rgba(0,0,0,0.1);
//   width: 100%;
//   max-width: 600px;
//   text-align: center;

//   h2 {
//     margin-bottom: 10px;
//     font-size: 1.4rem;
//     font-weight: 600;
//   }

//   p {
//     margin: 8px 0;
//     font-size: 1rem;
//   }

//   em {
//     color: #666;
//     font-size: 0.95rem;
//   }
// `;

// const NavButton = styled.button`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   background: rgba(0, 0, 0, 0.4);
//   color: white;
//   border: none;
//   border-radius: 50%;
//   padding: 10px;
//   z-index: 10;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   &:hover {
//     background: rgba(0, 0 ,0, 0.6);
//   }

//   ${props => props.left && css`left: 10px;`}
//   ${props => props.right && css`right: 10px;`}
// `;

// const TestimonialsCarousel = () => {
//   const [current, setCurrent] = useState(0);
//   const total = TESTIMONIALS.length;

//   const handlePrev = () => {
//     setCurrent((prev) => (prev === 0 ? total - 1 : prev - 1));
//   };

//   const handleNext = () => {
//     setCurrent((prev) => (prev === total - 1 ? 0 : prev + 1));
//   };

//   return (
//     <CarouselContainer>
//       <NavButton left onClick={handlePrev}><ChevronLeft /></NavButton>
//       <SlideWrapper style={{ transform: `translateX(-${current * 100}%)` }}>
//         {TESTIMONIALS.map((testimonial, index) => (
//           <Slide key={index} style={{
//             opacity: index === current ? 1 : 0.5,
//             transform: index === current ? 'scale(1)' : 'scale(0.95)',
//             transition: 'all 0.6s ease'
//           }}>
//             <Card>
//               <p>"{testimonial.content}"</p>
//               <h2>{testimonial.author}</h2>
//             </Card>
//           </Slide>
//         ))}
//       </SlideWrapper>
//       <NavButton right onClick={handleNext}><ChevronRight /></NavButton>
//     </CarouselContainer>
//   );
// };

// export default TestimonialsCarousel;


