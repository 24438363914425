import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { PROJECTS } from "../../data/superb/content";

// // Dummy data
// const PROJECTS = [
//   {
//     title: 'Modern Kitchen',
//     image: '/images/kitchen.jpg',
//     stone_type: 'Granite',
//     dimension: 120,
//     color: 'White'
//   },
//   // Add more project objects here...
// ]; //

const fadeIn = keyframes`
  from { opacity: 0; transform: translateX(30px); }
  to { opacity: 1; transform: translateX(0); }
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  // max-width: 1000px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // margin-bottom: 100px;
`;

const SlideWrapper = styled.div`
  display: flex;
  height: 70%;
  width: 70%;
  // margin-bottom: 100px;
  transition: transform 0.6s ease;
`;

const Slide = styled.div`
  min-width: 100%;
  margin-bottom: 100px;
  position: relative;
  animation: ${fadeIn} 0.6s ease;
`;

const BackgroundImage = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  // height: 400px;
  height: calc(100vh - 400px);
  filter: brightness(0.6);
`;

const Card = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  width: 80%;
  max-width: 500px;
  // border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
`;

const Button = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #222;
  color: #fff;
  border: none;
  // border-radius: 8px;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.4);
  color: white;
  border: none;
  border-radius: 50%;
  // padding: 10px 10.5px;
  padding: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(0, 0 ,0, 0.6);
  }

  ${props => props.$left && css`
    left: 10px;
  `}

  ${props => props.$right && css`
    right: 10px;
  `}
`;

const ProjectsCarousel = () => {
  const [current, setCurrent] = useState(0);
  const total = PROJECTS.length;

  const handlePrev = () => {
    setCurrent((prev) => (prev === 0 ? total - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrent((prev) => (prev === total - 1 ? 0 : prev + 1));
  };

  return (
    <CarouselContainer>
      <NavButton $left={true} onClick={handlePrev}><ChevronLeft /></NavButton>
      <SlideWrapper style={{ transform: `translateX(-${current * 100}%)` }}>
        {PROJECTS.map((project, index) => (
          <Slide key={index} style={{
            opacity: index === current ? 1 : 0.5,
            transform: index === current
              ? 'scale(1)'
              : 'scale(0.9)',
            transition: 'all 0.6s ease'
          }}>
            <BackgroundImage src={project.image} />
            <Card>
              <h2>{project.title}</h2>
              <p>Stone Type: {project.stone_type}</p>
              <p>Dimensions: {project.dimension} sq ft</p>
              <p>Color: {project.color}</p>
              <Button>View Project</Button>
            </Card>
          </Slide>
        ))}
      </SlideWrapper>
      <NavButton $right={true} onClick={handleNext}><ChevronRight /></NavButton>
    </CarouselContainer>
  );
};

export default ProjectsCarousel;

