import { useState } from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  // padding: 1rem;
  color: white;
`;

const Header = styled.div`
  height: 200px;
  width: 100%;
  // position: fixed;
  // top: 0px;
  background-color: #121212;
  display: flex;
  justify-content: space-between;
`;

const SubHeader = styled.div`
  height: 100px;
  width: 100%;
  position: sticky;
  top: 0px;
  background-color: #3a3a3a;
`;

const SectionBlock = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  background-color: #838484;
`;

const HeaderLogo = styled.img`
  width: 300px;
  height: 150px;
`;

const HeaderContactSection = styled.div`
  padding: 10px;
  display: flex;
`;

const SuperbPage = () => {
  
  return (
    <>
      <Header>
        <HeaderLogo src="https://www.iiicoast.com/backend-static/upload/logo_retina.png" />
        <HeaderContactSection>
          <p>
            Contact 1
          </p>
          <p>
            Contact 2
          </p>
        </HeaderContactSection>
      </Header>
      <SubHeader />
      <SectionBlock>
        <p>Hi from SectionBlock</p>
      </SectionBlock>
      <SectionBlock>
        <p>Hi from SectionBlock</p>
      </SectionBlock>
      <SectionBlock>
        <p>Hi from SectionBlock</p>
      </SectionBlock>
      <PageContainer>
        <p>Hello From SuperbPage</p>
      </PageContainer>
    </>
  );
};

export default SuperbPage;
