// FABContext.js
import React, { createContext, useEffect, useContext, useState, useRef } from 'react';

const FABContext = createContext();

export const FABProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState('');
  const textAreaRef = useRef(null);

  useEffect(() => {
      if (isOpen && textAreaRef.current) {
        textAreaRef.current.focus();
        console.log('Textarea focused');
      }
  }, [isOpen]);
  
  return (
    <FABContext.Provider value={{ isOpen, setIsOpen, content, setContent, textAreaRef }}>
      {children}
    </FABContext.Provider>
  );
};

export const useFAB = () => {
  const context = useContext(FABContext);
  if (!context) {
    throw new Error('useFAB must be used within a FABProvider');
  }
  return context;
};