import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { AxiosContext } from '../../App';
// import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import styled, { css } from 'styled-components';
import { FaHeart, FaComment, FaExternalLinkAlt } from 'react-icons/fa'; // , FaNewspaper, FaReadme
import { AuthContext } from '../../AuthContext';
import { useToast } from '../../ToastContext';
// import LikeCounter from '../LikeCounter';
import AnimatedCounter from '../AnimatedCounter';
// import OdometerCount from '../OdometerCount';
// import AnimatedNumber from '../AnimatedNumber';
// import Odometer from '../Odometer';
import { debounce } from "lodash";
// import ProfileCard from './ProfileCard';
// import CashtagLink from '../CashtagLink';
import PostContent from './PostContent';

const BASE_URL = 'https://www.iiicoast.com';

const PostContainer = styled.div`
  background-color: #070707;
  border-radius: 8px;
  padding: 16px;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border: 2px solid transparent;

  ${props => props.$isChainPost && css`
    padding-left: 48px;
    opacity: 0.9;
    
    &:hover {
      opacity: 1;
    }
  `}

  &:hover {
    // box-shadow: 0 14px 28px rgba(28, 28, 28, 0.25), 0 10px 10px rgba(28, 28, 28, 0.22);
    border: 2px solid #111; // Adjust the border color as needed
    cursor: pointer;
  }

  @media (max-width: 768px) {
    box-shadow: none;           // Flatter UI
    // border-bottom: 1px solid #111; // Subtle separation
    &:hover {
    // box-shadow: 0 14px 28px rgba(28, 28, 28, 0.25), 0 10px 10px rgba(28, 28, 28, 0.22);
    // border-bottom: 1px solid #transparent; 
    }
  }
`;

const PostHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  margin-bottom: 6px;
  margin-top: 16px;
  align-items: start;
`;

const ProfilePicture = styled.img`
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UsernameDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
`;

const Username = styled.h3`
  font-size: 0.975rem;
  margin: 0;
  color: #ffffff;
`;

const ActivityUsername = styled.h3`
  font-size: 0.975rem;
  margin: 0;
  color: #ffffff;
  padding-right: 4px;
  line-height: 1.5;
`;

const PostDate = styled.span`
  padding-left: 5px;
  font-size: 0.9rem;
  color: #657786;
`;

const PostDateActivity = styled.span`
  // padding-left: 5px;
  font-size: 0.9rem;
  color: #657786;

  @media (max-width: 768px) {
    display: none;
  }
`;

// const PostContent = styled.p`
//   margin: 0;
//   color: #fff;
//   font-size: 1rem;
//   line-height: 1.5;
//   word-break: break-word;
// `;

const PostFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #657786;
`;

const PostActions = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: #657786;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #ff304f;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  ${props => props.$isLiked && `
    color: #ff304f;
    &:hover {
      color: #ff304f;
    }
  `}
`;

const ActivityContainer = styled.div`
  display: flex;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 0;
  }
`

const ActivityNameContainer = styled.div`
  display: flex;
  align-items: center;
`
const MobileActivityDate = styled.div`
  display: none;
  // padding-left: 5px;
  font-size: 0.9rem;
  color: #657786;

  @media (max-width: 768px) {
    display: inline;
  }
`
const LikeCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // gap: 2px;
  gap: 4px;
  // height: 20px;
  // font-size: 24px;
`;

const StyledAnimatedCounter = styled(AnimatedCounter)`
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
`;

function Post({ post, onLike, isReply = false, isChainPost = false }) { 
  const axiosInstance = useContext(AxiosContext);
  const { isAuthenticated } = useContext(AuthContext);
  const { showToast } = useToast();

  const navigate = useNavigate();

  const [likeCount, setLikeCount] = useState(post.like_count || 0);
  const [isLiked, setIsLiked] = useState(false);
  const isHackerNewsPost = post.pid === 359;

  const profileLink = isHackerNewsPost 
    ? '/profile/Hacker%20News'
    : `/profile/${encodeURIComponent(post.username)}`;

  // const profilePicUrl = post.profile_picture_url.startsWith('http') 
  //   ? post.profile_picture_url 
  //   : `${BASE_URL}${post.profile_picture_url}`;
  
    // Function to generate srcSet based on user ID
  const generateSrcSet = (baseUrl, userId) => `
    ${baseUrl}/backend-static/upload/${userId}_thumbnail.jpg 35w,
    ${baseUrl}/backend-static/upload/${userId}_medium.jpg 150w,
    ${baseUrl}/backend-static/upload/${userId}_large.jpg 300w
  `;

  // Determine profile picture URL and srcSet
  const isDefaultImage = post.profile_picture_url === '/backend-static/upload/user_thumbnail.jpg';

  const profilePicData = post.profile_picture_url ? {
    src: isDefaultImage
      ? `${BASE_URL}/backend-static/upload/user_thumbnail.jpg`
      : post.profile_picture_url?.startsWith('http')
        ? post.profile_picture_url
        : `${BASE_URL}${post.profile_picture_url}`,
    srcSet: isDefaultImage
      ? `
        ${BASE_URL}/backend-static/upload/user_thumbnail.jpg 35w,
        ${BASE_URL}/backend-static/upload/user_medium.jpg 150w,
        ${BASE_URL}/backend-static/upload/user_large.jpg 300w
      `
      : generateSrcSet(BASE_URL, post.pid)
  } : {src: 'blank', srcSet: 'blank'}
  
    // const handleLike = async () => {
    //   if (!isAuthenticated) {
    //     showToast("Please log in to like posts", "info");
    //     return;
    //   }

    //   if (isLiked) return; // Prevent multiple likes

    //   setIsLiked(true);

    //   try {
    //     // const userString = localStorage.getItem('user');
    //     // if (!userString) {
    //     //   console.error('User not logged in');
    //     //   return;
    //     // }
  
    //     // const user = JSON.parse(userString);
    //     let response;
    //     if (isReply) {
    //       response = await axiosInstance.post('/v2/like_reply', {
    //         reply_id: post.id,
    //         tweet_id: post.tid,
    //         poster_id: post.pid,
    //         // user_id: user.uid,
    //         // username: user.username,
    //         // profile_picture_url: user.profile_picture_url
    //       });
    //     } else {
    //       response = await axiosInstance.post('/v2/like_post', {
    //         tweet_id: post.id,
    //         poster_id: post.pid,
    //         // user_id: user.uid,
    //         // username: user.username,
    //         // profile_picture_url: user.profile_picture_url
    //       });
    //     }
  
    //     if (response.status === 204) {
    //       setLikeCount(prevCount => prevCount + 1);
    //       if (onLike) onLike(post.id);
          
    //     }
    //   } catch (error) {
    //     console.error('Error liking post:', error);
    //     showToast("An error occurred while liking the post");
    //     setIsLiked(false); // Revert on error
    //   }
    // };

    const handleLike = () => {
      if (!isAuthenticated) {
        showToast("Please log in to like posts", "info");
        return;
      }
      
      setIsLiked(true);

      // Immediately update local state
      setLikeCount(prevCount => prevCount + 1);
      
      // Track this click
      const newClickCount = clickCounter.current + 1;
      clickCounter.current = newClickCount;
      
      // Log the accumulated clicks
      console.log(`Accumulated clicks: ${newClickCount}`);
      
      // Schedule sending to backend
      debouncedSendLikes(newClickCount);
    };
    
    // Create a ref to track clicks between renders
    const clickCounter = useRef(0);
    
    // Debounced function to send likes to backend
    // Memoizing the debounced function to avoid creating a new instance on each render
    const debouncedSendLikes = useMemo(
      () =>
        debounce(async (count) => {
          console.log(`Sending ${count} likes to backend`);
          try {
            const endpoint = isReply ? '/v2/like_reply' : '/v2/like_post';
            const tid = isReply ? post.parent_id : post.id;
            const response = await axiosInstance.post(endpoint, {
              tweet_id: tid,
              poster_id: post.pid,
              like_count: count,
              ...(isReply && { reply_id: post.id })
            });

            if (response.status === 204) {
              if (onLike) onLike(post.id);
            } else {
              throw new Error('Failed to send likes');
            }
          } catch (error) {
            console.error('Error sending likes:', error);
            showToast("An error occurred while processing likes");
            // Optionally revert the like count on error
            setLikeCount(prevCount => prevCount - count);
            setIsLiked(false);
          } finally {
            clickCounter.current = 0; // Reset counter after sending
          }
        }, 1000),
      [post.id, post.pid, post.parent_id, isReply, onLike, showToast, axiosInstance] // Dependencies list for memoization
    );

    // Clean up the debounced function when component unmounts
    useEffect(() => {
      return () => {
        debouncedSendLikes.cancel();
      };
    }, [debouncedSendLikes]);

    // const handleCommentClick = () => {
    //   if (post.post) { // && !isReply
    //     navigate(`/thread/${post.id}`, { state: { post } });
    //   }
    // };
    const handleCommentClick = () => {
      // const threadId = post.reply ? `reply_${post.id}` : post.id;
      // const currentScrollPosition = window.scrollY;
      // navigate(`/thread/${threadId}`, { 
      //   scrollPosition: currentScrollPosition 
      // });

      const threadId = post.reply ? `reply_${post.id}` : post.id;
      navigate(`/thread/${threadId}`, { state: { post } });
    };

    const handleArticleClick = () => {
      if (post.title) {
        navigate(`/article/${post.id}`, { state: { post } });
      }
    };

  return (
    <PostContainer $isChainPost={isChainPost} onClick={handleCommentClick}>
      <PostHeader>
        <Link to={profileLink }>
          <ProfilePicture 
            src={profilePicData.src}
            srcSet={profilePicData.srcSet}
            sizes="(max-width: 600px) 35px, (max-width: 1200px) 150px, 300px"
            alt={post.username} 
          />
        </Link>
        <PostInfo>
        {!post.activity ? (
          <UsernameDate>
            <Link to={profileLink }>
              <Username>{post.username}</Username>
            </Link>
            <PostDate>{post.date}</PostDate>
          </UsernameDate>
        ) : (
          // <PostDateActivity>{post.date}</PostDateActivity>
          <></>
        )}
          {/* <UsernameDate>
            <Link to={`/profile/${post.username}`}>
              <Username>{post.username}</Username>
            </Link>
            <PostDate>{post.date}</PostDate>
          </UsernameDate> */}
          {/* {post.post ? (
            <PostContent>{post.post}</PostContent>
          ) : (
            <PostContent>{post.title}</PostContent>
          )} */}
          {post.activity ? (
            <ActivityContainer>
              <ActivityNameContainer>
                <Link to={`/profile/${post.username}`}>
                  <ActivityUsername>{post.username}</ActivityUsername>
                </Link>
                <MobileActivityDate>{post.date}</MobileActivityDate>
              </ActivityNameContainer>
              <PostContent>{post.post}</PostContent>
              {/* <MediaPoster src={post.activity.poster_path} alt={post.activity.title} /> */}
            </ActivityContainer>
          ) : post.post ? (
            <PostContent>{post.post}</PostContent>
          ) : (
            <PostContent>{post.title}</PostContent>
          )}
          {post.activity ? (
          <PostDateActivity>{post.date}</PostDateActivity>
        ) : (
          <></>
        )}
        </PostInfo>
      </PostHeader>
      <PostFooter>
      <PostActions>
          {post.post ? ( // && !isReply
            <ActionButton onClick={handleCommentClick}>
              <FaComment /> {post.reply_count === 1 ? '1 Comment' : post.reply_count > 1 ? `${post.reply_count} Comments` : 'Comment'}
            </ActionButton>
          ) : post.title ? (
            <>
              <ActionButton>
                <FaComment /> Comment
              </ActionButton>
              <ActionButton onClick={handleArticleClick}>
                <FaExternalLinkAlt /> Read
              </ActionButton>
            </>
          ) : null}
          {likeCount !== null && (
            <ActionButton onClick={handleLike} $isLiked={isLiked}>
              <LikeCountContainer>
                <FaHeart /> 
                {/* Testing Odometer Animations Ranked Best to Worst Odemeter -> OdometerCounter -> AnimatedNumber */}
                <StyledAnimatedCounter 
                  value={likeCount}
                  duration={500}
                  size="1em"
                  color="inherit"
                />
                {/* <LikeCounter value={likeCount} /> */}
                {/* {likeCount} */}
                {/* <Odometer value={likeCount} /> */}
                {/* <OdometerCount value={likeCount} /> */}
                {/* <AnimatedNumber value={likeCount} /> */}
              </LikeCountContainer>
            </ActionButton>
          )}
        </PostActions>
      </PostFooter>
    </PostContainer>
  );
}

export default Post;
