import React, { useState, useEffect, useRef } from 'react';
// import { AxiosContext } from '../App';
import { useParams } from 'react-router-dom';
import TokenHoldings from '../components/TokenHoldings';
import PortfolioPieChart from '../components/PortfolioPieChart';
import Transaction from '../components/Transaction';
import { fetchWalletAccountInfo, fetchHoldings, fetchTransactions } from "../utils/solanaUtils";
import {
  PageContainer,
  FlexContainer,
  ChartContainer,
  WalletHeader,
  TransactionsHeader,
  WalletSection,
  MidSection,
  LeftMidSection,
  WalletPicture,
  AddressText,
  TransactionsContainer,
  LoadMoreButton,
  Muted
} from '../styles/CryptoStyles'; // Adjust imports accordingly

const WalletPage = () => {
  const { walletAddress } = useParams();
  const [walletAccountInfo, setWalletAccountInfo] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [cachedTransactions, setCachedTransactions] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [beforeSignature, setBeforeSignature] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  // const [displayButton, setDisplayButton] = useState(true);
  // const [initialized, setInitialized] = useState(false);
  // const axiosInstance = useContext(AxiosContext);

  const isInitialized = useRef(false)
  
  // publicnode rate-limit is 1200rqs/60s with 4min
  // const rpc = createSolanaRpc('https://solana-rpc.publicnode.com');
  // const addressEncoder = getAddressEncoder();

  // Create a Base58 codec that enforces a fixed size of 32 bytes
  // const get32BytesBase58Codec = () => fixCodecSize(getBase58Codec(), 32);

  // Encode & Decode Example
  // const base58Codec = getBase58Codec();

  // Create encoders for PDA derivation
  // const base58Encoder = getBase58Encoder({ endian: Endian.Big });
  
  const newTransactions = cachedTransactions?.filter(tx => 
    !transactions.some(existingTx => existingTx.signature === tx.signature)
  );
  
  const displayButton = hasMore || newTransactions?.length > 0
  
  const displayWalletSol = Number(walletAccountInfo?.lamports) / 1000000000
  
  // displayTokenCount? for one token edge case

  const SOL_PRICE = 138; // Hardcoded SOL price in USD

  // Fetch total value of tokens and SOL
  const calculateTotalPortfolioValue = () => {
    // if (!tokens) return;

    let totalTokenValue = 0;
    
    // Calculate the total value of tokens
    tokens.forEach((token) => {
      const tokenValue = token.account.data.parsed.info.tokenAmount.uiAmount * token.price;
      totalTokenValue += tokenValue;
    });
  
    // Calculate SOL value
    const solValue = (walletAccountInfo?.lamports / 1000000000) * SOL_PRICE;
  
    // Total portfolio value (tokens + SOL)
    const totalValue = totalTokenValue + solValue;
  
    return { totalValue, totalTokenValue, solValue };
  };

  const prepareChartData = () => {
    // if (!tokens) return;
    const { totalValue, totalTokenValue, solValue } = calculateTotalPortfolioValue();
  
    // Add a slice for tokens, SOL, and non-value tokens
    const chartData = [
      {
        label: 'SOL',
        value: solValue,
        color: '#7FB685', // GOLD b084fc
      },
      {
        label: 'Tokens',
        value: totalTokenValue,
        color: '#426A5A', // GREEN 4CAF50
      },
      {
        label: 'Non-Value Tokens',
        value: totalValue - totalTokenValue - solValue,
        color: '#e0e0e0', // LIGHT GRAY for non-value tokens
      }
    ];
  
    return chartData;
  };
  
  const getTransactions = async () => {
    const { b4sig, txs, cachedtxs, more } = await fetchTransactions(walletAddress, beforeSignature, transactions, cachedTransactions, hasMore)
    // if (result) {
    //   ({ b4sig, txs, cachedtxs, more } = result);
    // }
    setBeforeSignature(b4sig)
    setTransactions(txs)
    setCachedTransactions(cachedtxs)
    setHasMore(more)
  }
  
  const handleLoadMoreClick = async () => {
    
    if (hasMore) {
      // fetchTransactions();
      getTransactions();
      console.log("fetchTransactions() would run here")
    } else if (newTransactions) {
      // Iterate through result.transactions and add only those with unique signatures
      // Update state with new transactions
      setTransactions(prev => [...prev, ...newTransactions]);
      // setTransactions(prev => [...prev, ...cachedTransactions]);
      // setDisplayButton(false)
    } 
    // else {
    //   setDisplayButton(false)
    // }
  };

  // Initial fetch
  useEffect(() => {
    // setTransactions([]); // Clear transactions when wallet changes
    // setBeforeSignature(null); // Reset pagination
    // setHasMore(true); // Reset hasMore flag
    console.log("useEffect triggered")
    if (!isInitialized.current) {
      // setInitialized(true);
      isInitialized.current = true; // ✅ Set flag immediately
      // second conditional might not be needed if using initialized variable
      console.log("Not initialized moving to second initialization step")
      if (tokens.length === 0 && transactions.length === 0) {
          console.log("No tokens or transactions fetching...")
          // const fetchedAccountInfo = fetchWalletAccountInfo(walletAddress)
          // setWalletAccountInfo(fetchedAccountInfo);
          
          const getWalletInfo = async () => {
            // let b4sig, txs, cachedtxs, more;
            const data = await fetchWalletAccountInfo(walletAddress);
            setWalletAccountInfo(data); // Now setting actual data, not a Promise
            const holdings = await fetchHoldings(walletAddress);
            setTokens(holdings)
            // // const [ b4sig, txs, cachedtxs, more ] = await fetchTransactions(walletAddress, beforeSignature, transactions, cachedTransactions, hasMore)
            // const { b4sig, txs, cachedtxs, more } = await fetchTransactions(walletAddress, beforeSignature, transactions, cachedTransactions, hasMore)
            // // if (result) {
            // //   ({ b4sig, txs, cachedtxs, more } = result);
            // // }
            // setBeforeSignature(b4sig)
            // setTransactions(txs)
            // setCachedTransactions(cachedtxs)
            // setHasMore(more)
          };
          
          getWalletInfo();
          getTransactions();

          // const getWalletHoldings = async () => {
          //   const holdings = await fetchHoldings(walletAddress);
          //   setTokens(holdings)
          // };
          
          // getWalletHoldings();

          // fetchTransactions();
          // fetchHoldings();
      };
    };
    // fetchTransactions();
    // fetchHoldings();
  }, [tokens, transactions, fetchTransactions, fetchHoldings, loading, isInitialized]);

  // useEffect(() => {
  //   setTransactions([]); // Clear transactions when wallet changes
  //   setBeforeSignature(null); // Reset pagination
  //   setHasMore(true); // Reset hasMore flag

  //   fetchTransactions();
  //   fetchHoldings();
  // }, [walletAddress]);

  useEffect(() => {
    // console.log(chartData)
    setChartData(prepareChartData())
    console.log(tokens)
  }, [tokens])

  useEffect(() => {
    console.log(chartData)
  }, [chartData])
  
  // if (!tokens) return (<p>Loading</p>)
  return (
    <PageContainer>
      <WalletHeader>
        <WalletPicture src='https://www.iiicoast.com/backend-static/upload/user_medium.jpg' />
        <AddressText>{walletAddress}</AddressText>
      </WalletHeader>

      <MidSection>
      {/* <h1>Token Holdings</h1> */}
        <LeftMidSection>
          <TransactionsHeader>
            Portfolio Overview
          </TransactionsHeader>
          <FlexContainer>
            <ChartContainer>
              <PortfolioPieChart chartData={chartData}/>
            </ChartContainer>
            <div>
              <p><Muted>SOL Balance</Muted> {displayWalletSol}</p>
              <p><Muted>Token Balance</Muted> {tokens?.length} Tokens</p>
            </div>
          </FlexContainer>
        </LeftMidSection>
        <TokenHoldings tokens={tokens} loading={loading} />
      </MidSection>
      
      <WalletSection>
        <TransactionsHeader>
          Transactions
        </TransactionsHeader>
        <TransactionsContainer>
          {transactions?.length === 0 && !loading ? (
            <p>No transactions found for this wallet.</p>
          ) : (
            // transactions?.map(renderTransactionItem)
            transactions?.map((transaction) => (
              <Transaction key={transaction.signature} transaction={transaction} />
            ))
          )}
        </TransactionsContainer>
        {displayButton && (
          <LoadMoreButton 
            onClick={handleLoadMoreClick} 
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Load More'}
          </LoadMoreButton>
        )}
      </WalletSection>
     

    </PageContainer>
  );
};

export default WalletPage;
