// import React, { useState } from 'react';
import styled from 'styled-components';
import { FaAngleDown } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';

// const NavItemWrapper = styled.div`
//   position: relative;
//   padding: 10px 20px;
//   cursor: pointer;

//   &:hover > div {
//     display: block;
//   }
// `;

// const NavItemWrapper = styled.div`
//   position: relative;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   cursor: pointer;

//   &::before {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     width: 100%;
//     height: 3px;
//     background-color: #ffd61f;
//     transform: translateX(-50%) scaleX(0);
//     transform-origin: center;
//     transition: transform 0.3s ease;
//   }

//   &:hover::before {
//     transform: translateX(-50%) scaleX(1);
//   }

//   &:hover > div {
//     display: block;
//   }
// `;

const NavItemWrapper = styled(Link)`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: white;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 3px;
    background-color: #ffd61f;
    // transform: translateX(-50%) scaleX(0);
    transform: translateX(-50%) scaleX(${props => (props.$active ? 1 : 0)});
    transform-origin: center;
    transition: transform 0.3s ease;
  }

  &:hover::before {
    transform: translateX(-50%) scaleX(1);
  }

  &:hover > div {
    display: block;
  }
`;

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  // background: white;
  background-color: #3a3a3a;
  border: 1px solid #3a3a3a;
  min-width: 150px;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
  z-index: 10;
  color: #fff;
  transition: all 0.3s ease;

  a {
    display: block;
    padding: 10px 15px;
    color: #fff;
    text-decoration: none;

    &:hover {
      // background: #f7f7f7;
      color: #ffd61f;
      transition: all 0.3s ease;
    }
  }
`;

const routeMap = {
  'Home': '',
  'About Us': 'about',
  'Materials': 'materials',
  'Services': 'services',
  'Projects': 'projects',
  'How It Works': 'how-it-works',
  'Blog': 'news', // blog -> news for now
  'FAQ': 'faq',
  'Contact': 'contact',
  // Add more mappings as needed
};

const NavItem = ({ label, dropdownItems }) => {
  const location = useLocation();
  const routeSuffix = routeMap[label] // || label.toLowerCase().replace(/\s+/g, '-');
  const linkRoute = `/superb/${routeSuffix}`;
  const isActive = location.pathname === linkRoute;

  return (
    <NavItemWrapper to={linkRoute} $active={isActive}>
      {label}{dropdownItems && <FaAngleDown />}
      {dropdownItems && (
        <DropdownMenu>
          {dropdownItems.map((item, idx) => (
            <a key={idx} href={item.href}>{item.label}</a>
          ))}
        </DropdownMenu>
      )}
    </NavItemWrapper>
  );
};

export default NavItem;
