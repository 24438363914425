import axios from 'axios';

const isProduction = true

const baseURLVariable = isProduction ? 'https://www.iiicoast.com' : 'https://www.3000.quest' // 'http://34.44.137.145' 

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const axiosInstance = axios.create({
  baseURL: baseURLVariable,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  // const token = localStorage.getItem('access_token');
  const csrfToken = getCookie('csrf_access_token');

  // if (token) {
  //   config.headers['Authorization'] = `Bearer ${token}`;
  // }
  if (csrfToken) {
    config.headers['X-CSRF-TOKEN'] = csrfToken;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;
