import React from 'react'; // , { useState, useEffect, useCallback }
// import axios from 'axios';
import { usePosts } from '../../PostsContext';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from './Post';
import { BarLoader } from 'react-spinners';
import {
  SpinnerContainer, 
} from '../../styles/shared/SharedStyles';

const PostListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 15px;  // Tighter layout on mobile
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: #555;
`;

const Incoming = styled.div`
  animation: fadeInSlideIn 500ms ease-out;

  @keyframes fadeInSlideIn {
    from {
      opacity: 0;
      transform: translateY(-25px); /* Start from 50px to the right */
    }
    to {
      opacity: 1;
      transform: translateY(0); /* End at original position */
    }
  }

  animation-fill-mode: forwards; /* Keeps the element in its final state after animation */
`;

function PostList() {
  const { posts, setPosts, hasMore, fetchPosts, refreshPosts, isLoading } = usePosts(); // , page, setPage, setHasMore
  // const [page, setPage] = useState(1);
  // const [hasMore, setHasMore] = useState(true);
  

  // const fetchPosts = useCallback(async () => {
  //   console.log('Fetching posts...');
  //   console.log('Current page:', page);
  //   setIsLoading(true);
// 
  //   setTimeout(async () => {
  //     try {
  //       console.log('Fetching from API: page', page);
  //       const response = await axios.get(`https://www.iiicoast.com/api/get-posts/${page}`);
  //       const newPosts = response.data;
  //       console.log('Fetched posts:', newPosts);
  //       
  //       // setPosts(prevPosts => [...prevPosts, ...newPosts]);
// 
  //       // Check if there are existing posts
  //       setPosts(prevPosts => {
  //         // Ensure newPosts is not empty
  //         if (newPosts === prevPosts) {
  //           // If there are no new posts, return the current posts
  //           return prevPosts;
  //         }
// 
// 
  //         // Ensure prevPosts is not empty
  //         if (prevPosts.length === 0) {
  //           // If there are no existing posts, just add all new posts
  //           return [...prevPosts, ...newPosts];
  //         }
// 
  //         // // Get the last post in the current array
  //         // const lastPost = prevPosts[prevPosts.length - 1];
// 
  //         // Ensure newPosts is not empty
  //         if (newPosts.length === 0) {
  //           // If there are no new posts, return the current posts
  //           return prevPosts;
  //         }
  //       
  //         // // Get the first new post
  //         // const firstNewPost = newPosts[0];
  //       // 
  //         // // Check if the last post matches the first new post
  //         // const isDuplicate = lastPost && 
  //         //   ((lastPost.post && firstNewPost.post && lastPost.post === firstNewPost.post) ||
  //         //   (lastPost.title && firstNewPost.title && lastPost.title === firstNewPost.title));
  //       // 
  //         // // If there's a duplicate, remove the first new post
  //         // const adjustedNewPosts = isDuplicate ? newPosts.slice(1) : newPosts;
  //       
  //         // Return the updated posts
  //         return [...prevPosts, ...newPosts];
  //       });
// 
  //       // // Check if there are existing posts
  //       // setPosts(prevPosts => {
  //       //   const lastPost = prevPosts[prevPosts.length - 1]; // Last post in the current array
  // // 
  //       //   // Check if the last post matches the first new post
  //       //   const firstNewPost = newPosts[0];
  //       //   const isDuplicate = lastPost && 
  //       //     ((lastPost.post && firstNewPost.post && lastPost.post === firstNewPost.post) ||
  //       //     (lastPost.title && firstNewPost.title && lastPost.title === firstNewPost.title));
  // // 
  //       //   // If there's a duplicate, remove the first new post
  //       //   const adjustedNewPosts = isDuplicate ? newPosts.slice(1) : newPosts;
  // // 
  //       //   return [...prevPosts, ...adjustedNewPosts];
  //       // });
// 
  //       setHasMore(newPosts.length > 0);
  //       setPage(prevPage => prevPage + 1);
  //       console.log('Next page will be:', page + 1);
  //     } catch (error) {
  //       console.error('Error fetching posts:', error);
  //     } finally {
  //       setIsLoading(false);
  //       console.log('Loading finished');
  //     }
  //   }, 500); // 0.5-second delay before loading more posts
  // }, [page, setPosts]);

  // Pull to refresh function
  // const refreshPosts = useCallback(async () => {
  //   console.log('Refreshing posts...');
  //   setIsLoading(true);
// 
  //   try {
  //     const response = await axios.get(`https://www.iiicoast.com/api/get-posts/1`);
  //     const freshPosts = response.data;
  //     console.log('Fetched fresh posts:', freshPosts);
// 
  //     setPosts(freshPosts); // Replace old posts with the latest from page 1
  //     setPage(2); // Reset to page 2 for the next load
  //     setHasMore(freshPosts.length > 0);
  //   } catch (error) {
  //     console.error('Error refreshing posts:', error);
  //   } finally {
  //     setIsLoading(false);
  //     console.log('Refresh finished');
  //   }
  // }, [setPosts]);
// 
  // useEffect(() => {
  //   if (posts.length === 0) {
  //     console.log('Initial load: fetching first page of posts');
  //     fetchPosts();
  //   }
  // }, [posts.length, fetchPosts]);

  // useEffect(() => {
  //   return () => {
  //     setTimeout(() => {
  //       setPage(1);
  //       setPosts([]);
  //       setHasMore(true);
  //       setIsLoading(false);
  //     }, 225); // Adjust the delay as needed
  //   };
  // }, []);

  // After animation ends, update the state to remove the `new` flag
  const handleAnimationEnd = (post) => {
    post.new = false; // Remove the `new` flag once the animation completes
    setPosts(prevPosts => [...prevPosts]); // Trigger a re-render
  };

  return (
    <InfiniteScroll
      dataLength={posts.length}
      next={fetchPosts}
      hasMore={hasMore}
      loader={
        <SpinnerContainer>
          <BarLoader color="#888888" loading={isLoading} />
        </SpinnerContainer>
      }
      scrollThreshold={0.9}
      endMessage={<LoadingMessage></LoadingMessage>}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      refreshFunction={refreshPosts} // Function to run on pull-to-refresh
      pullDownToRefreshContent={
        <LoadingMessage>Pull down to refresh...</LoadingMessage>
      }
      releaseToRefreshContent={
        <LoadingMessage>Release to refresh...</LoadingMessage>
      }
    >
      <PostListContainer>
        {posts.map((post, index) => (
          post.new ? (
            <Incoming 
              key={`${post.id}-${post.timestamp}-${post.thread_url}-${index}`}
              onAnimationEnd={() => handleAnimationEnd(post)}
            >
              <Post post={post} />
            </Incoming>
          ) : (
            <Post key={`${post.id}-${post.timestamp}-${post.thread_url}-${index}`} post={post} />
          )
        ))}
      </PostListContainer>
    </InfiniteScroll>
  );
}

export default PostList;
