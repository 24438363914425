import React, { useState, useEffect, useContext, Suspense } from 'react';
import { AuthContext } from './AuthContext';
import { usePosts } from './PostsContext';
import { useAudioPlayer } from "./AudioPlayerContext";
// import { useFAB } from './FABContext';
import axiosInstance from './axiosConfig';
import requestPushPermission from './utils/pushNotification';
import { Route, Routes, Outlet, useLocation, useNavigate } from 'react-router-dom'; 
import GlobalStyles from './styles/GlobalStyles';
import { BarLoader } from 'react-spinners';
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { listenForMessages } from './serviceWorkerRegistration';
import {
  LoaderWrapper, 
  AppContainer, 
  PageContent,
  CryptoContent,
  MusicContent,
} from './styles/AppStyles';
import {
  SpinnerContainer, 
} from './styles/shared/SharedStyles';
import {
  Header, 
  PostList, 
  Sidebar, 
  NotificationTray, 
  FloatingActionButton, 
  Login, 
  Register,
  Loader, // Using Loader as a fallback component
  AudioPlayer,
  PlaceholderPost
} from './components';
import {
  WalletPage
} from './pages';
import SuperbPage from './pages/SuperbPage';
// import PlaceholderPost from './components/PlaceholderPost';

// console.log(notDefined);

// Lazy loaded components
const Onboarding = React.lazy(() => import('./components/auth/Onboarding'));
const Recommended = React.lazy(() => import('./components/auth/Recommended'));
const ViewThread = React.lazy(() => import('./components/feed/ViewThread'));
const ViewArticle = React.lazy(() => import('./components/feed/ViewArticle'));
const ProtectedRoute = React.lazy(() => import('./components/auth/ProtectedRoute'));
const OddHeader = React.lazy(() => import('./components/oddjobs/OddHeader'));
const OddHome = React.lazy(() => import('./components/oddjobs/OddHome'));
const OddJob = React.lazy(() => import('./components/oddjobs/OddJob'));
const OddConfirm = React.lazy(() => import('./components/oddjobs/OddConfirm'));
const OddWorkerLogin = React.lazy(() => import('./components/oddjobs/OddWorkerLogin'));
const OddWorker = React.lazy(() => import('./components/oddjobs/OddWorker'));
const OddLogin = React.lazy(() => import('./components/oddjobs/OddLogin'));
const FounderHeader = React.lazy(() => import('./components/portfolio/FounderHeader'));
// const AudioPlayer = React.lazy(() => import('./components/AudioPlayer'));

// Lazy loaded pages
const VanityPage = React.lazy(() => import('./pages/VanityPage'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'));
const DraftsPage = React.lazy(() => import('./pages/DraftsPage'));
const ViewProfilePage = React.lazy(() => import('./pages/ViewProfilePage'));
const ExplorePage = React.lazy(() => import('./pages/ExplorePage'));
const MusicPage = React.lazy(() => import('./pages/MusicPage'));
const PlaylistPage = React.lazy(() => import('./pages/PlaylistPage'));
const VideoPage = React.lazy(() => import('./pages/VideoPage'));
const ChatbotPage = React.lazy(() => import('./pages/ChatbotPage'));
const RoomsPage = React.lazy(() => import('./pages/RoomsPage'));
const MessagingPage = React.lazy(() => import('./pages/MessagingPage'));
const ConversationPage = React.lazy(() => import('./pages/ConversationPage'));
const Portfolio = React.lazy(() => import('./pages/portfolio/Portfolio'));
const LabPage = React.lazy(() => import('./pages/LabPage'));
const AppsPage = React.lazy(() => import('./pages/AppsPage'));
const StudyPage = React.lazy(() => import('./pages/StudyPage'));
const HabitsPage = React.lazy(() => import('./pages/HabitsPage'));
const CryptoPage = React.lazy(() => import('./pages/CryptoPage'));
const CryptoCoinPage = React.lazy(() => import('./pages/CryptoCoinPage'));
const TokenPage = React.lazy(() => import('./pages/TokenPage'));
// const WalletPage = React.lazy(() => import('./pages/WalletPage'));
// const DonatePage = React.lazy(() => import('./pages/DonatePage'));

// ______     ______   ______   __         ______     ______  
// /\  __ \   /\  == \ /\  == \ /\ \       /\  ___\   /\__  _\ 
// \ \  __ \  \ \  _-/ \ \  _-/ \ \ \____  \ \  __\   \/_/\ \/ 
//  \ \_\ \_\  \ \_\    \ \_\    \ \_____\  \ \_____\    \ \_\ 
//   \/_/\/_/   \/_/     \/_/     \/_____/   \/_____/     \/_/ 
// __         ______     __  __     ______     __  __     ______   ______    
// /\ \       /\  __ \   /\ \_\ \   /\  __ \   /\ \/\ \   /\__  _\ /\  ___\   
// \ \ \____  \ \  __ \  \ \____ \  \ \ \/\ \  \ \ \_\ \  \/_/\ \/ \ \___  \  
//  \ \_____\  \ \_\ \_\  \/\_____\  \ \_____\  \ \_____\    \ \_\  \/\_____\ 
//   \/_____/   \/_/\/_/   \/_____/   \/_____/   \/_____/     \/_/   \/_____/ 

// Layout for Test Page
const OddPageLayout = () => (
  // <PayPalScriptProvider options={{ "client-id": "AWoAGxpFQBcfRTlVTnlBpJKrqGS99RKhDvgy5tbMt-VqZenniHxqVWLbiWDFvp8qM-G_0FobDaUsOFo1" }}>
    <div>
      <OddHeader />
      <main>
        {/* The Outlet will render the nested routes' content */}
        <Outlet />
      </main>
    </div>
  // </PayPalScriptProvider>
);

// Layout for Test Page
const FounderLayout = () => (
    <div>
      <FounderHeader />
      <main>
        {/* The Outlet will render the nested routes' content */}
        <Outlet />
      </main>
    </div>
);

export const AxiosContext = React.createContext(null);

function App() {
  const [sidebarMinimized, setSidebarMinimized] = useState(false);
  const { isAuthenticated, loading } = useContext(AuthContext);
  const { isAudioPlayerVisible, togglePlayPause, currentSong, isPlaying, progress, handleProgressChange, closePlayer, audioRef, updateProgress, playNextSong } = useAudioPlayer();
  const { unreadCount, notifications, setIsNewPosts, setScrolledToTop, isRefreshing, handlePostSubmit } = usePosts();
  // const { isOpen } = useFAB();
  const location = useLocation();
  const navigate = useNavigate();
  const isMusicRoute = location.pathname.startsWith('/music');
  const isCryptoRoute = location.pathname.startsWith('/crypto');

  const handleNewPostClick = () => {
    // // Scroll to the top for iOS Safari Standalone Mode
    // document.documentElement.scrollTop = 0;
    // document.body.scrollTop = 0; // For older browsers
    // // window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to top smoothly
    // setScrolledToTop(true);
    setIsNewPosts(false);
    // getRecentPost(recentTid); // Fetch new posts once at the top
  };

  const handleScrollClick = () => {
    // Scroll to the top for iOS Safari Standalone Mode
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For older browsers
    // window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to top smoothly
    setScrolledToTop(true);
    // getRecentPost(recentTid); // Fetch new posts once at the top
  };

  // __  __     ______     ______     ______     ______   ______   ______     ______     ______   ______    
  // /\ \/\ \   /\  ___\   /\  ___\   /\  ___\   /\  ___\ /\  ___\ /\  ___\   /\  ___\   /\__  _\ /\  ___\   
  // \ \ \_\ \  \ \___  \  \ \  __\   \ \  __\   \ \  __\ \ \  __\ \ \  __\   \ \ \____  \/_/\ \/ \ \___  \  
  //  \ \_____\  \/\_____\  \ \_____\  \ \_____\  \ \_\    \ \_\    \ \_____\  \ \_____\    \ \_\  \/\_____\ 
  //   \/_____/   \/_____/   \/_____/   \/_____/   \/_/     \/_/     \/_____/   \/_____/     \/_/   \/_____/ 
                                                                                                          
  // // Matomo Tracking Script
  // useEffect(() => {
  //   var _paq = window._paq = window._paq || [];
  //   /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  //   _paq.push(['trackPageView']);
  //   _paq.push(['enableLinkTracking']);
  //   (function() {
  //     var u="//admin.iiicoast.com/";
  //     _paq.push(['setTrackerUrl', u+'matomo.php']);
  //     _paq.push(['setSiteId', '1']);
  //     var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  //     g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  //   })();
  // }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (isAuthenticated) {
      requestPushPermission(axiosInstance);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // register();

    // Listen for messages and navigate using React Router
    listenForMessages((url) => navigate(url));
  }, [navigate]);
 
  // const authConsoleText = "Current Authentication Status: "
  // console.log(authConsoleText + isAuthenticated)

  if (loading) {
    return (
      <LoaderWrapper><BarLoader color="#888888" loading={loading} /></LoaderWrapper>
    )
  }

  return (
    <AxiosContext.Provider value={axiosInstance}>
        <GlobalStyles />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/superb" element={
              <>
                <SuperbPage />
              </>
            } />
            {/* Layout development lab */}
            <Route path="/vanity" element={
              <>
                <VanityPage /> {/* This will take up the entire viewport */}
              </>
            } />
            <Route path="/lab" element={
              <>
                <LabPage /> {/* This will take up the entire viewport */}
              </>
            } />
            {/* Applet layouts & routes */}
            <Route path="/founder" element={<FounderLayout />}>
              <Route index element={<Portfolio />} />  
            </Route>
            <Route path="/test" element={<OddPageLayout />}>
              <Route index element={<OddHome />} />  {/* Default for /test */}
              <Route path="job" element={<OddJob />} />
              <Route path="confirm" element={<OddConfirm />} />
              <Route path="worker-login" element={<OddWorkerLogin />} />
              <Route path="worker" element={<OddWorker />} />
              {/* <Route path="worker-job" element={<OddWorkerJob />} /> */}
              <Route path="login" element={<OddLogin />} />
            </Route>
            {/* Central Application layout & routes */}
            <Route path="/*" element={
              <AppContainer>
                <Sidebar onMinimize={setSidebarMinimized} />
                {/* Messaging Convos Sidebar on Desktop */}
                { isAuthenticated && (
                  <NotificationTray unreadCount={unreadCount} notifications={notifications} />
                )}
            		{/* {isCryptoRoute && } */}
                {isCryptoRoute ? (
                  <CryptoContent $isMusicRoute={isMusicRoute} $isCryptoRoute={isCryptoRoute} $sidebarMinimized={sidebarMinimized}>
                    <Header />
                    <Routes>
                      <Route path="/crypto" element={<CryptoPage />} />
                      <Route path="/crypto/:coinId" element={<CryptoCoinPage />} />
                      <Route path="/crypto/token/:mintAddress" element={<TokenPage />} />
                      <Route path="/crypto/wallet/:walletAddress" element={<WalletPage />} />  
                    </Routes>
                  </CryptoContent>
                ) : isMusicRoute ? (
                  <MusicContent $isMusicRoute={isMusicRoute} $isCryptoRoute={isCryptoRoute} $sidebarMinimized={sidebarMinimized}>
                    <Header />
                    <Routes>
                       <Route path="/music" element={
                         <ProtectedRoute>
                           <MusicPage />
                         </ProtectedRoute>
                       } />
                       <Route path="/music/playlist/:pid" element={
                         <ProtectedRoute>
                           <PlaylistPage />
                         </ProtectedRoute>
                       } />                      
                    </Routes>
                  </MusicContent>
                ) : 
                  <PageContent $sidebarMinimized={sidebarMinimized}>
                    <Header />
                    <Routes>
                      <Route path="/" element={
                        <>
                          {isRefreshing && (
                            <SpinnerContainer>
                              <BarLoader color="#888888" loading={isRefreshing} />
                            </SpinnerContainer>
                          )}
                          <>
                            <PlaceholderPost />
                            <PostList />
                            {isAuthenticated && 
                            <FloatingActionButton 
                              // isNewPosts={isNewPosts}
                              // scrolledToTop={scrolledToTop} 
                              onSubmit={handlePostSubmit} 
                              newPostClick={handleNewPostClick} 
                              scrollClick={handleScrollClick}
                            />
                            }
                          </>
                        </>
                      } />
                      <Route path="/profile" element={
                        <ProtectedRoute>
                          <ProfilePage />
                        </ProtectedRoute>
                      } />
                      <Route path="/drafts" element={
                        <ProtectedRoute>
                          <DraftsPage />
                        </ProtectedRoute>
                      } />
                      {/* Define route for viewing other users' profiles with a username parameter */}
                      <Route path="/profile/:username" element={
                        <ProtectedRoute>
                          <ViewProfilePage />
                        </ProtectedRoute>
                      } />
                      <Route path="/explore" element={
                        <ProtectedRoute>
                          <ExplorePage />
                        </ProtectedRoute>
                      } />
                      <Route path="/messaging" element={
                        <ProtectedRoute>
                          <MessagingPage />
                        </ProtectedRoute>
                      } />
                      {/*
                      <Route path="/music" element={
                        <ProtectedRoute>
                          <MusicPage />
                        </ProtectedRoute>
                      } />
                      <Route path="/music/playlist/:pid" element={
                        <ProtectedRoute>
                          <PlaylistPage />
                        </ProtectedRoute>
                      } />
                      */}
                      <Route path="/videos" element={
                      <ProtectedRoute>
                        <VideoPage />
                      </ProtectedRoute>
                      } />
                      <Route path="/chatbot" element={<ChatbotPage />} />
                      {/*}<Route path="/crypto" element={<CryptoPage />} />
                      <Route path="/crypto/:coinId" element={<CryptoCoinPage />} />
                      <Route path="/crypto/token/:mintAddress" element={<TokenPage />} />
                      <Route path="/crypto/wallet/:walletAddress" element={<WalletPage />} />*/}
                      
                      {/* <Route path="/donate" element={<DonatePage />} /> */}
                      <Route path="/habits" element={<HabitsPage />} />
                      <Route path="/rooms" element={
                        <ProtectedRoute>
                          <RoomsPage />
                        </ProtectedRoute>
                      } />
                      <Route path="/apps" element={
                        <ProtectedRoute>
                          <AppsPage />
                        </ProtectedRoute>
                      } />
                      <Route path="/study" element={
                        // <ProtectedRoute>
                          <StudyPage />
                        // </ProtectedRoute>
                      } />
                      <Route path="/thread/:postId" element={
                        <ProtectedRoute>
                          <ViewThread  />
                        </ProtectedRoute>
                      } />
                      <Route 
                        path="/article/:articleId" 
                        element={
                          <ProtectedRoute>
                            <ViewArticle />
                          </ProtectedRoute>
                        } 
                      />
                      <Route path="/conversation/:conversationId" element={
                          <ProtectedRoute>
                              <ConversationPage />
                          </ProtectedRoute>
                      } />
                      <Route path="/onboarding" element={<Onboarding />} />
                      <Route path="/recommended" element={
                          <ProtectedRoute>
                              <Recommended />
                          </ProtectedRoute>
                      } />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                    </Routes>
                  </PageContent>
                }
                {currentSong && (
                  <AudioPlayer
                    sidebarMinimized={sidebarMinimized}
                    currentSong={currentSong}
                    isPlaying={isPlaying}
                    progress={progress}
                    togglePlayPause={togglePlayPause}
                    handleProgressChange={handleProgressChange}
                    closePlayer={closePlayer}
                    isVisible={isAudioPlayerVisible}
                  />
                )}
              </AppContainer>
            } />
          </Routes>
        </Suspense>
        {currentSong && (
        <audio
          ref={audioRef}
          src={`/api/stream-song/${currentSong.filename}`}
          onTimeUpdate={updateProgress}
          onEnded={playNextSong} 
          autoPlay
          // eslint-disable-next-line 
          playsInline // warning playInline meant for video
          controls // This is necessary for iOS to allow background playback
          style={{display: 'none'}} // Hide the native controls
        />
        )}
  </AxiosContext.Provider>
  );
}

export default App;
