import styled from "styled-components";
import {
  FaMapMarkerAlt,
  FaPhone,
  FaRegEnvelope,
  FaFax
} from "react-icons/fa";

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const MainFooter = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  color: #fff;
  font-family: 'Poppins', sans-serif;
`;

const FooterContainer = styled.div`
  width: 70%;
  display: flex;
`;

const FooterSection = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
`;

const FooterOfficeSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
`;

const FooterHeader = styled.div`
  width: 50%;
  padding: 30px;
  margin-top: 30px;
  border-bottom: 3px solid #888;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    // bottom: -3px; /* Just below the yellow border */
    left: 0;
    bottom: -3px;
    // transform: translateX(-50%);
    width: 25%;
    border-bottom: 3px solid #ffd61f;
  }
`;

const OfficeInfo = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 15px;

  svg {
    padding: 7px;
    margin-right: 5px;
    border: 1px solid #888;
  }
`;

const OfficeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 15px;
`;

const SubFooter = styled.div`
  // margin-top: 200px;
  height: 70px;
  width: 100%;
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
`;

const SubFooterContainer = styled.div`
  border-top: 1px solid rgba(255,255,255,0.2);
  font-size: 13px;
  color: rgba(255,255,255,0.7);
  width: 70%;
  display: flex;
  align-items: center;
`;

const SuperbFooter = () => {
  return (
    <>
      <MainFooter>
        {/*<SectionText>Main Footer</SectionText>*/}
        <FooterContainer>
          <FooterSection> 
          <FooterOfficeSection>
              <Center>
                <FooterHeader>Office</FooterHeader>
              </Center>
            <OfficeInfoContainer>
              <OfficeInfo>
                <FaMapMarkerAlt />
                <div>Address</div>
              </OfficeInfo>
              <OfficeInfo>
                <FaPhone />
                <div>Phone</div>
              </OfficeInfo>
              <OfficeInfo>
                <FaRegEnvelope />
                <div>Email</div>
              </OfficeInfo>
              <OfficeInfo>
                <FaFax />
                <div>Fax</div>
              </OfficeInfo>
            </OfficeInfoContainer>
          </FooterOfficeSection>
          </FooterSection>
          <FooterSection>
            <FooterHeader>Showroom Hours</FooterHeader>
            <div>Our team is ready to answer all your questions. We'd Love to hear from you. </div>
            <div>Monday to Friday</div>
            <div>8AM - 5PM</div>
            <div>Saturday</div>
            <div>by appointment only</div>
          </FooterSection>
          <FooterSection>
            <FooterHeader>Products</FooterHeader>
            <div>Granite</div>
            <div>Marble</div>
            <div>Quartzite</div>
            <div>Quartz</div>
            <div>Porcelain</div>
            <div>Sinks</div>
            <div>Solid Surface</div>
            <div>Laminate</div>
          </FooterSection>
          <FooterSection>
            <FooterHeader>Useful Links</FooterHeader>
            <div>Process</div>
            <div>FAQ</div>
            <div>Visualizer</div>
            <div>Blog</div>
            <div>Events</div>
            <div>News</div>
          </FooterSection>
        </FooterContainer>
      </MainFooter>
      <SubFooter>
        <SubFooterContainer><div>Copyright © {new Date().getFullYear()} Superb. All Rights Reserved. </div></SubFooterContainer> {/* Developed by */}
      {/*<SectionText>SubFooter</SectionText>*/}
      </SubFooter>
    </>
  );
};

export default SuperbFooter;
