import { useState } from "react";
import styled from "styled-components";
import {
  FaPlayCircle,
  FaArrowAltCircleRight,
} from "react-icons/fa";
import BusinessLocationMap from "../../components/BusinessLocationMap";
import ProjectsCarousel from "../../components/superb/ProjectsCarousel";
import { TESTIMONIAL_ONE, HOME_BLURB_ONE, HOME_BLURB_TWO, HOME_BLURB_THREE, PROJECTS_SUBTITLE } from "../../data/superb/content";
import TestimonialsCarousel from "../../components/superb/TestimonialsCarousel";
// 
const PageContainer = styled.div`
  // padding: 1rem;
  // color: white;
  font-family: 'Poppins', sans-serif;
`;

const Container = styled.div`
  // padding: 1rem;
  color: white;
`;

const MaxWidthContentContainer = styled.div`
  width: 100%;
  height: 100%;
  // margin: 3rem;
  max-width: 1300px;
  display: flex;
  // flex-direction: column;
  justify-content: left;
  // align-items: left;
  color: white;
`;


const SectionBlock = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  background-color: #838484;
`;

const ContactBlock = styled.div`
  // margin-top: 200px;
  height: 700px;
  width: 100%;
  background-color: #838484;
`;

const ContactContainer = styled.div`
  height: 100%;
  display: flex;
  // mobile media query column direction
`;

const ContactForm = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

const ContactFormContainer = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  margin: 0 auto;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormHeader = styled.div`
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 50px;
`;

const ContactMap = styled.div`
  width: 50%;
  height: 100%;
  background-color: yellow;
`;

const ContactRow = styled.p`
  // width: 100%;
  display: flex;
  // justify-content: space-between;
  gap: 1.25rem;
  margin-bottom: 25px;
`;

const ContactRowInput = styled.input`
  // width: 50%;
  background-color: #f9f9f9;
  padding: 15px;
  flex: 1;
  border: 1px solid #efefef;
  box-sizing: border-box;

  &:focus {
    border-color: #ffd61f; /* Or whatever color you want */
    outline: none; /* Optional: remove blue glow */
  }
`;

const ContactInput = styled.input`
  width: 100%;
  margin-bottom: 25px;
  background-color: #f9f9f9;
  // margin: 15px;
  padding: 15px;
  border: 1px solid #efefef;
  box-sizing: border-box;

  &:focus {
    border-color: #ffd61f; /* Or whatever color you want */
    outline: none; /* Optional: remove blue glow */
  }
`;

const ContactTextArea = styled.textarea`
  width: 100%;
  background-color: #f9f9f9;
  // margin: 15px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  padding: 15px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 30px;

  &:focus {
    border-color: #ffd61f; /* Or whatever color you want */
    outline: none; /* Optional: remove blue glow */
  }
`;

const ContactButton = styled.div`
  background-color: #ffd61f;
  color: #151719;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 50px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.1);
  }
`;

const TestimonialsBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 400px);
  width: 100%;
  background-color: #fff;
  background-image: url(https://www.iiicoast.com/backend-static/upload/background-quote.png);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 25%;
  border-top: 2px solid rgba(0,0,0,0.1);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuoteContainer = styled.div`
  max-width: 650px;
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const QuoteText = styled.div`
  font-size: 20px;
  margin-bottom: 25px;
`;

const QuoteImg = styled.img`
  height: 50px;
  width: 50px;
  margin: auto;
  margin-bottom: 10px;
`;

const QuoteAuthor = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const QuoteGoogleImg = styled.img`
  height: 100px;
  width: 100px;
  margin: auto;
`;

const MoreInfoBlock = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  // background-color: #838484;
  background-image: url(https://www.iiicoast.com/backend-static/upload/preview.jpg);
  background-position: center;
  // background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 50px;
  font-weight: 700;

  /* Dark overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 1;
  }

  /* Ensures text is above overlay */
  > * {
    position: relative;
    z-index: 2;
  }
`;

const InfoBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 400px);
  width: 100%;
  background-color: #fff;
  // color: #444;
  color: rgba(21,23,25,0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  white-space: pre-line;
  text-align: justify;
  line-height: 30px;
`;

const InfoText = styled.div`
  max-width: 1300px;
  margin-bottom: 15px;
  z-index: 2;
`;

const InfoTextThird = styled.div`
  max-width: 1300px;
  margin-bottom: 15px;
  z-index: 2;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: start;
`;

const ProjectsBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 300px);
  width: 100%;
  // background-color: #838484;
  background-color: #fff;
  padding-bottom: 50px;
`;

const ProjectsHeader = styled.div`
  // margin-top: 200px;
  height: 250px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: -100px;
`;

const ProjectsSubTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  max-width: 1300px;
  color: #474747;
`;

const IntroSectionBlock = styled.div`
  // margin-top: 200px;
  height: calc(100vh - 165px);
  width: 100%;
  background-color: dark-red;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Dark overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 0; /* Below content but above video */
  }
`;

const IntroBlurb = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 40px;
`;

const IntroTitle = styled.div`
  margin-top: -70px;
  font-size: 74px;
  font-weight: 500;
  line-height: 84px;
  letter-spacing: -2px;
  z-index: 2;
  // white-space: nowrap;
`;

const IntroSubTitle = styled.div`
  margin-top: 10px;
  width: 804px;
  font-size: 44px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: -2px;
  z-index: 2;
`;

const IntroButton = styled.div`
  height: auto;
  width: fit-content;
  background-color: #fff;
  text-transform: uppercase;
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 27px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 15px;
  padding: 9px 38px;
  margin-top: 20px;
  z-index: 2;
  color: black;
  transition: all 0.3s ease;
  cursor: pointer;

  svg {
    margin-top: -1px;
    margin-left: 3px;
  }

  &:hover {
    background-color: #ffcc00;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const StoneSectionBlock = styled.div`
  position: relative;
  // margin-top: 200px;
  //height: 450px;
  height: calc(100vh - 120px);
  width: 100%;
  background-color: #fff;
  background-image: url(https://www.iiicoast.com/backend-static/upload/peak_mountainbg.png);
  // background-position: bottom;
  // wide screen media query
  background-position-y: 125%;
  background-position-x: center;
  background-repeat: no-repeat;
  // background-repeat: repeat-x;
  
  background-size: 100% auto;

  display: flex;
  justify-content: center;

  /* Gradient Overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, white 100%);
    z-index: 1;
  }
`;

const StoneSectionContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  height: 100%;
  padding 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StoneText = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const StoneTitle = styled.div`
  font-size: 30px;
  color: black;
  font-weight: 700;
`;

const StoneSubTitle = styled.div`
  color: #ffd61f;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  svg {
    margin-left: 3px;
    margin-top: -1px;
  }
`;

const StoneVisual = styled.div`
  padding: 10px;
`;

const SectionText = styled.div`
  z-index: 2;
`;

const YellowButton = styled.div`
  height: auto;
  width: fit-content;
  background-color: #ffd61f;
  text-transform: uppercase;
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 27px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 15px;
  padding: 9px 38px;
  margin-top: 20px;
  z-index: 2;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #3a3a3a;
    color: #ffd61f;
  }
`;

const SuperbPage = () => {
  // Component functional body

  return (
    <PageContainer>
      <IntroSectionBlock>
        <VideoBackground autoPlay loop muted playsInline>
          <source src="https://www.iiicoast.com/backend-static/video/background-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </VideoBackground>
        <MaxWidthContentContainer>
          <IntroBlurb>
            <IntroTitle>Here we can!</IntroTitle>
            <IntroSubTitle>
              Design countertops with robotic technology,
              <br></br>
              any top, any where.
            </IntroSubTitle>
            <IntroButton>
              Play Video
              <FaPlayCircle />
            </IntroButton>
          </IntroBlurb>
        </MaxWidthContentContainer>
      </IntroSectionBlock>
      <StoneSectionBlock>
        <StoneSectionContainer>
          <StoneText>
            <StoneTitle>
              See Our Products
            </StoneTitle>
            <StoneSubTitle>
              See All Stones 
              <FaArrowAltCircleRight />
            </StoneSubTitle>
          </StoneText>
          <StoneVisual>
            Stone Images Here
          </StoneVisual>
        </StoneSectionContainer>
      </StoneSectionBlock>
      <ProjectsHeader>
        <SectionText>Projects</SectionText>
        <ProjectsSubTitle>{PROJECTS_SUBTITLE}</ProjectsSubTitle>
      </ProjectsHeader>
      <ProjectsBlock>
        {/*<SectionText>Projects Component Here</SectionText>*/}
        <ProjectsCarousel />
      </ProjectsBlock>
      <InfoBlock>
        <InfoText>{HOME_BLURB_ONE}</InfoText>
        <InfoText>{HOME_BLURB_TWO}</InfoText>
        <InfoTextThird><div>{HOME_BLURB_THREE}</div></InfoTextThird>
      </InfoBlock>
      <TestimonialsBlock>
          {/*<QuoteContainer>
          <QuoteGoogleImg src="https://www.iiicoast.com/backend-static/upload/google-review-icon.png" />
          <QuoteText>{TESTIMONIAL_ONE}</QuoteText>
          <QuoteImg src="https://www.iiicoast.com/backend-static/upload/yellow_qota.png" />
          <QuoteAuthor>Sarah Padrones</QuoteAuthor>
        </QuoteContainer>  */}
        <TestimonialsCarousel />
      </TestimonialsBlock>
      <MoreInfoBlock>
        <SectionText>How does it Work?</SectionText>
        <YellowButton>See All Steps</YellowButton>
      </MoreInfoBlock>
      <ContactBlock>
        <ContactContainer>
          <ContactForm>
            <ContactFormContainer>
            <Center>
              <FormHeader>Contact Us</FormHeader>
            </Center>
            <ContactRow>
              <ContactRowInput placeholder="NAME" />
              <ContactRowInput placeholder="PHONE" />
            </ContactRow>
            <ContactInput placeholder="EMAIL" />
            <ContactTextArea placeholder="YOUR MESSAGE" rows="5" />
            <ContactButton>Send Message</ContactButton>
            </ContactFormContainer>
         </ContactForm>
          <ContactMap>
            <BusinessLocationMap />
          </ContactMap>
        </ContactContainer>
      </ContactBlock>
    </PageContainer>
  );
};

export default SuperbPage;
