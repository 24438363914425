import styled from "styled-components";
// import { FaMapMarkedAlt } from "react-icons/fa";
import { PiHeadset, PiMapPinArea } from "react-icons/pi";
import NavItem from "./NavItem";

const Header = styled.div`
  height: 100px;
  width: 100%;
  // position: fixed;
  // top: 0px;
  // background-color: #121212;
  background-color: #fff;
  display: flex;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
`;

const SubHeader = styled.div`
  height: 65px;
  width: 100%;
  position: sticky;
  top: 0px;
  background-color: #3a3a3a;
  display: flex;
  // gap: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  z-index: 3;
`;

const MaxWidthContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const HeaderLogo = styled.img`
  // width: 200px;
  padding-left: 17px;
  height: 90px;
`;

const HeaderContactSection = styled.div`
  // padding: 10px;
  display: flex;
  gap: 30px;
  height: 100%;
  padding-right: 15px;
`;

const HeaderContact = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 16px;
  transition: all 0.3s ease;

  svg {
    font-size: 40px;
    color: #ffd61f;
    transition: all 0.3s ease;
  }

  &:hover svg {
    color: #1a1a1a; // or whatever hover color you want
  }
`;

const HeaderContactText = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;

const NavSection = styled.div`
  display: flex;
  gap: 40px;
  height: 100%;
  align-items: center;
  padding-left: 30px;
  font-size: 15px;
`;

// const NavItem = styled.div`
//   height: 100%;
//   display: flex;
//   align-items: center;
// `;

// const NavItem = styled.div`
//   position: relative;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   cursor: pointer;

//   &::before {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     width: 100%;
//     height: 3px;
//     background-color: #ffd61f;
//     transform: translateX(-50%) scaleX(0);
//     transform-origin: center;
//     transition: transform 0.3s ease;
//   }

//   &:hover::before {
//     transform: translateX(-50%) scaleX(1);
//   }
// `;

const NavItemActive = styled.div`
  border-bottom: 3px solid #ffd61f;
  // negative margin-bottom centers nav active
  // margin-bottom: -3px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
`;

const NavButton = styled.div`
  height: 100%;
  background-color: #ffd61f;
  width: 205px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;



const SuberbHeader = () => {
  return (
    <>
      <Header>
        <MaxWidthContainer>
          <LogoContainer>
            <HeaderLogo src="https://www.iiicoast.com/backend-static/upload/logo_retina.png" />
          </LogoContainer>
          <HeaderContactSection>
            <HeaderContact>
              <PiHeadset />
              <HeaderContactText>
                <span>
                  630-475-8644
                </span>
                <span>
                  info@superbsurfaces.com
                </span>
              </HeaderContactText>
            </HeaderContact>
            <HeaderContact>
              <PiMapPinArea />
              <HeaderContactText>
                <span>
                  1160 Greenleaf Ave
                </span>
                <span>
                  Elk Grove Village, IL 60007
                </span>
              </HeaderContactText>
            </HeaderContact>
          </HeaderContactSection>
        </MaxWidthContainer>
      </Header>
      <SubHeader>
        <MaxWidthContainer>
        <NavSection>
          {/*<NavItemActive>Home</NavItemActive>*/}
          <NavItem label="Home" />
          <NavItem label="About Us" />
          <NavItem label="Materials" dropdownItems={[
            { label: 'Granite', href: '/services/design' },
            { label: 'Marble', href: '/services/dev' },
            { label: 'Quartzite', href: '/' },
            { label: 'Quartz', href: '/' },
            { label: 'Product Offer', href: '/' },
          ]} />
          <NavItem label="Services" />
          <NavItem label="Projects" />
          <NavItem label="How It Works" />
          <NavItem label="Blog" dropdownItems={[
            { label: 'Events', href: '/' },
            { label: 'News', href: '/' },
          ]}/>
          <NavItem label="FAQ" />
          <NavItem label="Contact" />
          {/* Blog (dropdown) - FAQ - Contact */}
        </NavSection>
        <NavButton>
          Free Consultation
        </NavButton>
        </MaxWidthContainer>
      </SubHeader>
    </>
  );
};

export default SuberbHeader;
