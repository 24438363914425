import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaInfoCircle, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const ToastContainer = styled.div`
  z-index: 2000;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  animation: ${({ $isClosing }) => 
    $isClosing 
      ? css`${slideOut} 0.3s ease-in forwards`
      : css`${slideIn} 0.3s ease-out`
  };

  ${({ $type }) => {
    switch ($type) {
      case 'success':
        return css`
          background-color: #051b11;
          border: 1px solid #082b1b;
          color: #75b798;
        `;
      case 'warning':
        return css`
          background-color: #332701;
          border: 1px solid #5f4802;
          color: #e7c55d;
        `;
      case 'danger':
        return css`
          background-color: #2c0b0e;
          border: 1px solid #4c2229;
          color: #ea868f;
        `;
      default: // info
        return css`
          background-color: #031633;
          border: 1px solid #052e6b;
          color: #6ea8fe;
        `;
    }
  }}
`;

const Icon = styled.span`
  margin-right: 10px;
  font-size: 1.2em;
  display: flex; // This helps with alignment
  align-items: center; // This ensures the icon itself is vertically centered
`;

const Toast = ({ message, type = 'info', onClose }) => {
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
    const timer = setTimeout(() => {
      setIsClosing(true);
    }, 2700); // Start closing animation slightly before removal

      return () => clearTimeout(timer);
    }, []);  

    useEffect(() => {
      if (isClosing) {
        const closeTimer = setTimeout(onClose, 300); // Remove after closing animation
        return () => clearTimeout(closeTimer);
      }
    }, [isClosing, onClose]);

    const getIcon = () => {
      switch (type) {
        case 'success':
          return <FaCheckCircle />;
        case 'warning':
        case 'danger':
          return <FaExclamationTriangle />;
        default:
          return <FaInfoCircle />;
      }
    };  

    return (
        <ToastContainer $type={type} $isClosing={isClosing}>
            <Icon>{getIcon()}</Icon>
            {message}
        </ToastContainer>
    );
};

export default Toast;