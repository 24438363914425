// MediaStyles.js
import styled from 'styled-components';

export const MediaTabContainer = styled.div`
  width: 100%;
`;

export const MediaTitle = styled.p`
  color: #fff;
  margin: 0;
  font-size: 14px;
`;

export const MediaSectionTitle = styled.h5`
  color: #fff;
`

export const MediaResults = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  overflow-x: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const MediaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const PosterImage = styled.img`
  width: 200px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100px;
    height: 150px;
  }
`;