// src/utils/pushNotification.js
// import { AxiosContext } from '../App';
// import { useContext } from 'react';
// import axios from 'axios';

const requestPushPermission = async (axiosInstance) => {
  // const axiosInstance = useContext(AxiosContext);

  try {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      console.log('Permission for push notifications was denied.');
      return;
    }
    
    // Now that permission is granted, subscribe to push notifications
    const registration = await navigator.serviceWorker.ready;

    // Check for an existing subscription
    const existingSubscription = await registration.pushManager.getSubscription();
    if (existingSubscription) {
      console.log('Unsubscribing from existing push subscription...');
      const isUnsubscribed = await existingSubscription.unsubscribe();
      if (isUnsubscribed) {
        console.log('Successfully unsubscribed from existing subscription.');
        // Now remove the subscription on the backend (server) using axiosInstance
        try {
          const response = await axiosInstance.post('/v2/remove-subscription', {
            subscription: JSON.stringify(existingSubscription), // Send the subscription data
          });
        
          if (response.status === 200) {
            console.log('Successfully removed subscription from the server.');
          } else {
            throw new Error('Failed to remove subscription from the server.');
          }
        } catch (error) {
          console.error('Error removing subscription from server:', error);
        }
      } else {
        console.error('Failed to unsubscribe.');
        return;
      }
    }

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: 'BEtj96b7S3DDg3_8-90lDtk5_CnRid_GCIQ93mm62WNSPYjieRXvHSq8JSF2sSRErOwLvtsox4iUyUYSYJ3GWk0', // This is generated in the backend
    });

    // Send the subscription object to your backend to save it
    await axiosInstance.post('/v2/subscribe', { subscription });
    console.log('User subscribed to push notifications.');
  } catch (error) {
    console.error('Error subscribing to push notifications:', error);
  }
};

export default requestPushPermission;
