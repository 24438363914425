import React, { useState, useEffect, useContext, useRef } from 'react';
import { useAudioPlayer } from "../AudioPlayerContext";
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';//, useLocation
import { AxiosContext } from '../App';
import styled from 'styled-components';
import { FaArrowUp } from "react-icons/fa";
import { BarLoader } from 'react-spinners';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const ComponentContainer = styled.div`
  background-color: #000;
`

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #000;
  // height: calc(100vh - 220px);
	overflow-y: auto;
  overflow-x: hidden;
  height: ${props => props.$isAudioPlayerVisible ? 'calc(100vh - 290px)' : 'calc(100vh - 220px)'};

  @media (max-width: 768px) {
    height: calc(100vh - 280px);
  }

  @media (display-mode: standalone) {
    height: calc(100vh - 200px);
  }
`;

// const Header = styled.h2`
//   text-align: center;
//   color: #000;
//   margin-bottom: 20px;
// 
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  display: flex;
  margin-bottom: 10px;
  ${props => props.$isPartner 
    ? 'justify-content: flex-start;'  /* Partner message on the left */
    : 'justify-content: flex-end;'}   /* User message on the right */
`;

const ProfilePic = styled.img`
  width: 30px;
  height: 30px;
  margin-top: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

const MessageBubble = styled.div`
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 20px;
  ${props => props.$isPartner 
    ? 'background-color: #2e2e32; color: #fff; border-bottom-left-radius: 0;'
    : 'background-color: #007aff; color: #fff; border-bottom-right-radius: 0;'
  }

  /* Ensure text wrapping */
  word-wrap: break-word; /* For older browsers */
  overflow-wrap: anywhere; /* Modern CSS */
  white-space: normal; /* Allows text to wrap */
`;

const MessageText = styled.p`
  margin: 0;
`;

const Timestamp = styled.small`
  display: block;
  margin-top: 5px;
  color: ${props => props.$isPartner ? '#8e8e93' : '#b8e2ff'};
  font-size: 0.7em;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #212121;
`;

const MessageInput = styled.input`
  font-size: 16px;
  background-color: #000;
  color: #fff;
  border: none;
  flex: 1;
  padding: 5px 10px;
  outline: none;
`;

const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 35px; /* Adjust as needed for the size of the circle */
  height: 35px; /* Ensure the width and height are the same */
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #4ea4ff; /* Shared hover color */
  }
`;

const ConversationPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [socket, setSocket] = useState(null);
    // const [isConnected, setIsConnected] = useState(false);
    const { conversationId } = useParams();
    // const location = useLocation();
    const axiosInstance = useContext(AxiosContext);
    const [conversationData, setConversationData] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const { isAudioPlayerVisible } = useAudioPlayer();
    const containerRef = useRef(null);

    useEffect(() => {
      // document.cookie = "foo=bar";
      const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      };
  
      const csrfToken = getCookie('csrf_access_token');
      console.log(document.cookie);

      const newSocket = io('https://www.iiicoast.com', {
        transports: ['websocket'],
        withCredentials: true, // This allows cookies to be sent with the request
        auth: {
          token: csrfToken
        }
        // namespace: '/chat'
      });
  
      newSocket.on('connect', () => {
        console.log('Connected to server');
        // setIsConnected(true);
        // console.log(isConnected)
      });
  
      newSocket.on('disconnect', () => {
        console.log('Disconnected from server');
        // setIsConnected(false);
        // console.log(isConnected)
      });
  
      newSocket.on('chat_message', (data) => {
        // console.log(conversationData)
        setConversationData(prevData => ({
            ...prevData,
            messages: [
                ...prevData.messages,
                {
                    id: data.id,  // Unique message ID
                    sender_id: data.sender_id,
                    profile_picture_url: data.profile_picture_url,
                    content: data.message,
                    timestamp: data.timestamp
                }
            ]
        }));
    });

      newSocket.on('user_joined', function(data) {
        console.log(`${data.username} has joined the chat`);
    });
  // 
      // newSocket.on('status', (data) => {
      //   setMessages(prev => [...prev, data.msg]);
      // });
  
      setSocket(newSocket);

      newSocket.emit('join', { conversation_id: conversationId });

      return () => newSocket.close();
    }, [conversationId]);

    useEffect(() => {
        const fetchConversationData = async () => {
          setIsLoading(true);
            try {
                const response = await axiosInstance.post('/v2/get-conversation-data', {
                    conversation_id: conversationId
                });
                setConversationData(response.data);
                // console.log(conversationData)
            } catch (error) {
                console.error('Error fetching conversation data:', error);
            } finally {
              // Adding a delay before setting loading to false
              setTimeout(() => setIsLoading(false), 250);
            }
        };

        fetchConversationData();
    }, [conversationId, axiosInstance]);

    // Scroll to bottom after data is updated
    useEffect(() => {
      const scrollToBottom = () => {
          if (containerRef.current) {
              containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }
      };

      if (conversationData) {
        // Wait for the conversationData to be rendered before scrolling
        setTimeout(scrollToBottom, 300);
      }
  }, [conversationData]);

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
    
    const sendMessage = () => {
      console.log('Sending message:', inputValue);
      if (socket && inputValue) {//  && username && room
        socket.emit('message', { message: inputValue, conversation_id: conversationId });// username, room, 
        setInputValue('');
      }
    };

    if (isLoading || !conversationData) {
        return (
          <SpinnerContainer>
            <BarLoader color="#888888" loading={isLoading} />
          </SpinnerContainer>
        );
    }

    return (
        <ComponentContainer>
          <Container $isAudioPlayerVisible={isAudioPlayerVisible} ref={containerRef}>
              {/*<Header>Conversation with {conversationData.partner.username}</Header>*/}
              <MessageContainer>
                  {conversationData.messages.map((message) => {
                      const isPartner = message.sender_id === conversationData.partner.id;
                      return (
                          <Message key={message.id} $isPartner={isPartner}>
                              {isPartner && (
                                  <ProfilePic 
                                      src={`https://www.iiicoast.com/backend-static/${message.profile_picture_url}`} 
                                      alt="Profile" 
                                  />
                              )}
                              <MessageBubble $isPartner={isPartner}>
                                  <MessageText>{message.content}</MessageText>
                                  <Timestamp $isPartner={isPartner}>{message.timestamp}</Timestamp>
                              </MessageBubble>
                          </Message>
                      );
                  })}
              </MessageContainer>
          </Container>
          <InputContainer>
            <MessageInput
              type="text"
              placeholder="Enter a message"
              value={inputValue}
              onChange={handleInputChange}
            />
            <SendButton onClick={sendMessage}><FaArrowUp /></SendButton>
          </InputContainer>
        </ComponentContainer>
    );
};

export default ConversationPage;