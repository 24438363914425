import React, {useContext} from 'react';
import { AxiosContext } from '../App';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom'; // Import Link from react-router-dom Link,
// import { useToast } from '../ToastContext';


// const CashtagLinkContainer = styled.div`
//   // padding: 20px;
// `;

const StyledCashtag = styled.span`
  color: #1da1f2;
  text-decoration: none;
  cursor: pointer;
  // background: none;
  // border: none;
  // font-size: 1rem;
  // line-height: 1.5;
  // margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;

function TagLink({ symbol, tag, children }) {
  const isCashtag = symbol === '$';
  const isHashtag = symbol === '#';
  // console.log(symbol)

  const axiosInstance = useContext(AxiosContext);
  const navigate = useNavigate();
  // const { showToast } = useToast();
  const location = useLocation();
  
  const handleClick = async (e) => {
    e.preventDefault();
    
    if (isHashtag) {
      // console.log("isHashtag", tag);
      navigate(`/explore?search=#${tag}`);
    }
    if (isCashtag) {
      try {
      const response = await axiosInstance.get(`/api/symbol/${tag}`);
      if (response.data.success) {
        navigate(`/crypto/${response.data.coinId}`);
      } else {
        // If we're already on the explore page, just update the search
        if (location.pathname === '/explore') {
          // Assuming you have a way to update the parent's search query
          window.dispatchEvent(new CustomEvent('updateExploreSearch', {
            detail: { query: `$${tag}` }
          }));
        } else {
          // Navigate to explore page with the search query
          navigate(`/explore?search=$${tag}`);
        }
      }
      } catch (error) {
        // Navigate to explore page with the search query on error
        navigate(`/explore?search=$${tag}`);
      }
    } 
  };

  // const handleClick = async (e) => {
  //   e.preventDefault();
    
  //   try {
  //     const response = await axiosInstance.get(`/api/symbol/${symbol}`); // .substring(1)
  //     if (response.data.success) {
  //       navigate(`/crypto/${response.data.coinId}`);
  //     }
  //   } catch (error) {
  //     showToast("No coin page found.", "danger");
  //   }
  // };

  return (
    <StyledCashtag onClick={handleClick}>
      {children}
    </StyledCashtag>
  );
}

export default TagLink;
