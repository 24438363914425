import styled from 'styled-components';

// Styled Components
export const PageContainer = styled.div`
  // background-color: #121212;
  color: #f0f0f0;
  // padding: 24px;
  border: 1px solid #222;
  min-height: 100vh;
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ChartContainer = styled.div`
  width: 33%;
  padding: 15px;
  margin-bottom: 10px;
`;

export const WalletHeader = styled.div`
  // margin-bottom: 8px;
  padding: 1rem 2.75rem;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #222;
`;

export const TransactionsHeader = styled.h2`
  // border-top: 1px solid #222;
  color: #fff;
  font-size: 1.4rem;
  padding-left: 2.75rem;
`;

export const WalletSection = styled.div`
  border-top: 1px solid #222;
  padding: 1rem 0;
`;

export const MidSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    // height: auto;
  }
`;

export const LeftMidSection = styled.div`
  width: 50%;
  // border-top: 1px solid #222;
  border-right: 1px solid #222;
  padding: 1rem 0;
`;

export const WalletPicture = styled.img`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin-right: 1rem;
`;

export const AddressText = styled.h3`
  font-size: 20px;
  // margin-bottom: 8px;
  color: #ffffff;
  word-break: break-all;
`;

export const TransactionsContainer = styled.div`
  background-color: #070707;
  // border-radius: 12px;
  padding: 20px;
  // margin: 15px;
  border: 1px solid #222;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const TransactionItem = styled.div`
  border-bottom: 1px solid #323232;
  padding: 16px 0;
  &:last-child {
    border-bottom: none;
  }
`;

export const TransactionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const Label = styled.span`
  color: #a0a0a0;
  font-size: 12px;
`;

export const Value = styled.span`
  color: #ffffff;
  font-size: 14px;
  word-break: break-all;
`;

export const SignatureLink = styled.a`
  color: #4da1ff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const LoadMoreButton = styled.button`
  // background-color: #4CAF50;
  background-color: #000;
  color: white;
  // border: none;
  border: 2px solid #7FB685;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 10px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #7FB685;
    border: 2px solid transparent;
    color: black;
    // font-weight: 600;
  }
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

export const Muted = styled.span`
  opacity: 50%;
`;
