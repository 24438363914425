import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { usePosts } from '../../PostsContext';
import styled from 'styled-components';
import { 
  FaHome, 
  FaUser, 
  FaSearch, 
  FaRegComments, 
  FaMusic, 
  FaDoorOpen, 
  FaTimes, 
  FaBars, 
  FaUserPlus, 
  FaDoorClosed, 
  FaVideo, 
  FaChevronRight, 
  FaSchool, 
  FaUsers,
  // FaDonate,
  FaBitcoin 
} from 'react-icons/fa'; //, FaChevronDown, FaCog, FaChevronLeft, FaChevronRight, FaTools, FaClipboardCheck
// , FaRocket, FaTh, FaUsers
import useWindowSize from '../../hooks/useWindowSize';
import { NavLink, Link } from 'react-router-dom';
// import axios from 'axios';

const SidebarContainerBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ $minimized, $isMobile }) => $isMobile ? ($minimized ? '0' : '100%') : ($minimized ? '40px' : '240px')};
  height: ${({ $isMobile }) => $isMobile ? '100%' : '100vh'};
  background-color: #070707;
  color: white;
  padding: ${({ $isMobile }) => $isMobile ? '60px 25px 20px' : '20px 10px'};
  transition: all 0.3s ease;
  z-index: 1005;
  overflow-x: hidden;

  @media (max-width: 768px) {
    transform: ${({ $minimized }) => $minimized ? 'translateX(-100%)' : 'translateX(0)'};
    width: 100%;
  }
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  // width: ${({ $minimized, $isMobile }) => $isMobile ? ($minimized ? '0' : '100%') : ($minimized ? '40px' : '240px')};
  height: ${({ $isMobile }) => $isMobile ? '100%' : '95vh'};
  background-color: transparent;
  color: white;
  padding: ${({ $isMobile }) => $isMobile ? '60px 25px 20px' : '20px 10px'};
  transition: all 0.3s ease;
  z-index: 1005;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pushes content to top and bottom */

  @media (max-width: 768px) {
    transform: ${({ $minimized }) => $minimized ? 'translateX(-100%)' : 'translateX(0)'};
    width: 100%;
  }
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px; /* Space between nav items */
`;

const HousekeepingLinks = styled.div`
  // color: #333;
  color: #888;
  font-size: 12px;
  margin-top: auto; /* Pushes the housekeeping links to the bottom */
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between housekeeping links */
  opacity: ${({ $minimized }) => $minimized ? '0' : '100%'};
  transition: opacity 0.3s ease 0.3s;

  @media (max-width: 768px) {
    display: none;
  }
`;

const HLWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const SidebarFooter = styled.div`
  // position: absolute;
  // bottom: 0;
  // height: ${({ $isMobile }) => $isMobile ? '100%' : '100vh'};
  // text-decoration: none;
  opacity: ${({ $minimized }) => $minimized ? '0' : '100%'};
  transition: opacity 0.1s ease;
`;

// const FooterLink = styled(Link)`
//   text-decoration: none;
//   color: #888;
// `;

const ExpandButton = styled.button`
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: ${({ $minimized }) => $minimized ? 'rgba(0,0,0,0)' : '#070707'}; // #000000; // #070707
  color: white;
  border: none;
  padding: 10px;
  margin-left: 7px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  z-index: 1006;

  @media (max-width: 768px) {
    display: block;
  }

  /* Style for the icons */
  svg {
    padding-top: 2px;
    width: 24px; /* Adjust as needed Added to fix oval with width/height on bg circle may need additional adjustments*/ 
    height: 24px; /* Adjust as needed */
  }
`;



const SidebarLink = styled(NavLink)`
  background: none;
  border: none;
  color: #888888;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 20px;
  text-decoration: none;
  transition: color 0.2s ease;
  // opacity: ${({ $minimized }) => $minimized ? '0' : '100%'};
  
  &.active {
    color: #ffffff;
    font-weight: bold;
  }

  // &:hover {
  //   color: #ffffff;
  // }

  svg {
    transition: none;
  }

  span {
    font-size: 18px;
    margin-left: 10px;
    display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
  }
`;

const SidebarLinkText = styled(NavLink)`
  background: none;
  border: none;
  color: #888888;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 10px;
  text-decoration: none;
  transition: opacity 0.2s ease;
  opacity: ${({ $minimized }) => $minimized ? '0' : '100%'};
  
  &.active {
    color: #ffffff;
    font-weight: bold;
  }

  // &:hover {
  //   color: #ffffff;
  // }

  svg {
    transition: none;
  }

  span {
    font-size: 18px;
    margin-left: 10px;
    display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
  }
`;

// const SidebarLinkWrapper = styled.div`
//   display: flex;
//   text-decoration: none;
//   transition: color 0.2s ease;
// 
//   &:hover {
//     color: #ffffff; /* Shared hover color */
//   }
// 
//   &:hover ${SidebarLink}, &:hover ${SidebarLinkText} {
//     color: #ffffff; /* Apply hover effect to both the icon and text */
//   }
// `;

const SidebarButton = styled(Link)`
  background: none;
  border: none;
  color: #888888;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 20px;
  text-decoration: none;
  transition: color 0.2s ease;
  
  // &:hover {
  //   color: #ffffff;
  // }

  span {
    font-size: 18px;
    margin-left: 10px;
    display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
  }
`;

const SidebarButtonMore = styled(Link)`
  background: none;
  border: none;
  color: #888888;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 20px;
  text-decoration: none;
  transition: color 0.2s ease, transform 0.3s ease;
  
  // &:hover {
  //   color: #ffffff;
  // }

  span {
    font-size: 18px;
    margin-left: 10px;
    display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
  }

  /* Rotate the button dynamically */
  svg {
    transition: transform 0.3s ease;
    transform: ${({ $expanded }) => ($expanded ? 'rotate(90deg)' : 'rotate(0deg)')};
  }
`;

const SidebarButtonText = styled(Link)`
  background: none;
  border: none;
  color: #888888;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 10px;
  text-decoration: none;
  transition: opacity 0.2s ease;
  opacity: ${({ $minimized }) => $minimized ? '0' : '100%'};
  
  // &:hover {
  //   color: #ffffff;
  // }

  span {
    font-size: 18px;
    margin-left: 10px;
    display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
  }
`;

const SidebarLinkWrapper = styled.div`
  display: flex;
  text-decoration: none;
  transition: color 0.2s ease;
  width: fit-content; /* Ensure wrapper size is only as big as its content */

  &:hover {
    color: #ffffff; /* Shared hover color */
  }

  /* Combine all targets under one hover rule */
  &:hover ${SidebarLink},
  &:hover ${SidebarLinkText},
  &:hover ${SidebarButton},
  &:hover ${SidebarButtonText},
  &:hover ${SidebarButtonMore} {
    color: #ffffff; /* Shared hover effect */
  }
`;

// const SidebarButton2 = styled.button`
//   background: none;
//   border: none;
//   color: #888888;
//   font-size: 1.5rem;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   margin-left: 5px;
//   margin-bottom: 20px;
//   transition: color 0.2s ease;
// 
//   &:hover {
//     color: #ffffff;  // Full white on hover
//   }
// 
//   span {
//     font-size: 18px;
//     margin-left: 10px;
//     display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
//   }
// `;

const Sidebar = ({ onMinimize }) => {
    const { isAuthenticated, logout } = useContext(AuthContext); //, uid
    // const [authenticatedUid, setAuthenticatedUid] = useState('');
    const [minimized, setMinimized] = useState(false);
    const [moreAppsMinimized, setMoreAppsMinimized] = useState(true);
    const { width } = useWindowSize();
    const isMobile = width <= 768;
    const { refreshPosts } = usePosts();
  
    const handleMinimize = () => {
      setMinimized(!minimized);
      onMinimize(!minimized);
    };

    const handleMoreAppsMinimize = () => {
      setMoreAppsMinimized(!moreAppsMinimized);
      // onMinimize(!minimized);
    };

    // // Automatically populate the username if the user is returning
    // useEffect(() => {
    //   if (uid) {
    //     setAuthenticatedUid(uid);
    //   }
    // }, [uid]);

    // Ensure the sidebar is minimized on mobile when the component mounts
    useEffect(() => {
      if (isMobile) {
        setMinimized(true);
      }
    }, [isMobile]);
    
    // Combined click handler for the Logout button
    // const handleLogoutClick = () => {
    //   if (isMobile) {
    //     setMinimized(true); // Minimize sidebar on mobile
    //   }
    //   onLogout(); // Perform logout action
    // };

    const handleResetAndNavigate = () => {
      if (isMobile) {
        handleMinimize(); // Call minimize function if on mobile
      }
      refreshPosts();
      // Scroll to top
      window.scrollTo(0, 0);
    };

    const mobileMenuMinimize = () => {
      if (isMobile) {
        handleMinimize(); // Call minimize function if on mobile
      }
    };

    const handleLogoutClick = () => {
      if (isMobile) {
        handleMinimize(); // Call minimize function if on mobile
      }
      logout(); // Call the logout function passed from parent
    };

    // console.log(uid)

    return (
      <>
        <ExpandButton $minimized={minimized} onClick={handleMinimize}>
          {minimized ? <FaBars /> : <FaTimes />}
        </ExpandButton>
        <SidebarContainerBackground $minimized={minimized} $isMobile={isMobile}></SidebarContainerBackground>
        <SidebarContainer $minimized={minimized} $isMobile={isMobile}>
          <NavLinks>
          {!isMobile && (
            <SidebarButton onClick={handleMinimize}>
              {minimized ? <FaBars /> : <FaTimes />}
            </SidebarButton>
          )}
          <SidebarLinkWrapper>
            <SidebarLink to="/" $minimized={minimized} onClick={handleResetAndNavigate}>
            <FaHome />
            </SidebarLink>
            <SidebarLinkText to="/" $minimized={minimized} onClick={handleResetAndNavigate}>
            <span>Home</span>
            </SidebarLinkText>
          </SidebarLinkWrapper>
          {isAuthenticated && (
            <>
              <SidebarLinkWrapper>
                <SidebarLink to="/crypto" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <FaBitcoin />
                </SidebarLink>
                <SidebarLinkText to="/crypto" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <span>Crypto</span>
                </SidebarLinkText>
                </SidebarLinkWrapper>
              <SidebarLinkWrapper>
                <SidebarLink to="/explore" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <FaSearch />
                </SidebarLink>
                <SidebarLinkText to="/explore" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <span>Explore</span>
                </SidebarLinkText>
                </SidebarLinkWrapper>
              <SidebarLinkWrapper>
                <SidebarLink to="/profile" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <FaUser />
                </SidebarLink>
                <SidebarLinkText to="/profile" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <span>Profile</span>
                </SidebarLinkText>
              </SidebarLinkWrapper>
              <SidebarLinkWrapper>
                <SidebarLink to="/messaging" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <FaRegComments />
                </SidebarLink>
                <SidebarLinkText to="/messaging" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <span>Messaging</span>
                </SidebarLinkText>
              </SidebarLinkWrapper>
              <SidebarLinkWrapper>
                <SidebarLink to="/music" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <FaMusic />
                </SidebarLink>
                <SidebarLinkText to="/music" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <span>Music</span>
                </SidebarLinkText>
              </SidebarLinkWrapper>
              {/* <SidebarLinkWrapper>
                  <SidebarLink to="/rooms" $minimized={minimized} onClick={mobileMenuMinimize}>
                    <FaUsers />
                  </SidebarLink>
                  <SidebarLinkText to="/rooms" $minimized={minimized} onClick={mobileMenuMinimize}>
                    <span>Rooms</span>
                  </SidebarLinkText>
                </SidebarLinkWrapper> */}
            {/* Used to have Rooms here more dev needed */}
            </>
          )}
          <>
          </>
        {isAuthenticated && (
          <>
            <SidebarLinkWrapper>
              <SidebarButtonMore $expanded={!moreAppsMinimized} $minimized={minimized} onClick={handleMoreAppsMinimize}>
                <FaChevronRight />
              </SidebarButtonMore>
              <SidebarButtonText $minimized={minimized} onClick={handleMoreAppsMinimize}>
                <span>More</span>
              </SidebarButtonText>
            </SidebarLinkWrapper>
            {!moreAppsMinimized && (
            <>
            <SidebarLinkWrapper>
              <SidebarLink to="/videos" $minimized={minimized} onClick={mobileMenuMinimize}>
                <FaVideo />
              </SidebarLink>
              <SidebarLinkText to="/videos" $minimized={minimized} onClick={mobileMenuMinimize}>
                <span>Videos</span>
              </SidebarLinkText>
            </SidebarLinkWrapper>
              
            {/* {authenticatedUid && ( //  === 283)} */}
              <>
                {/* <SidebarLinkWrapper>
                <SidebarLink to="/music" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <FaMusic />
                </SidebarLink>
                <SidebarLinkText to="/music" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <span>Music</span>
                </SidebarLinkText>
              </SidebarLinkWrapper> */}
              {/* <SidebarLinkWrapper>
                <SidebarLink to="/habits" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <FaClipboardCheck />
                </SidebarLink>
                <SidebarLinkText to="/habits" $minimized={minimized} onClick={mobileMenuMinimize}>
                  <span>Habits</span>
                </SidebarLinkText>
              </SidebarLinkWrapper> */}
              <SidebarLinkWrapper>
                  <SidebarLink to="/rooms" $minimized={minimized} onClick={mobileMenuMinimize}>
                    <FaUsers />
                  </SidebarLink>
                  <SidebarLinkText to="/rooms" $minimized={minimized} onClick={mobileMenuMinimize}>
                    <span>Rooms</span>
                  </SidebarLinkText>
                </SidebarLinkWrapper>
              <SidebarLinkWrapper>
              <SidebarLink to="/study" $minimized={minimized} onClick={mobileMenuMinimize}>
                <FaSchool />
              </SidebarLink>
              <SidebarLinkText to="/study" $minimized={minimized} onClick={mobileMenuMinimize}>
                <span>Study</span>
              </SidebarLinkText>
              </SidebarLinkWrapper>
                {/* {isMobile &&
                <SidebarLinkWrapper>
                  <SidebarLink to="/donate" $minimized={minimized} onClick={mobileMenuMinimize}>
                    <FaDonate />
                  </SidebarLink>
                  <SidebarLinkText to="/donate" $minimized={minimized} onClick={mobileMenuMinimize} reloadDocument>
                    <span>Donate</span>
                  </SidebarLinkText>
                </SidebarLinkWrapper>
                } */}
              </>
            {/* <SidebarLinkWrapper>
              <SidebarLink to="/chatbot" $minimized={minimized} onClick={mobileMenuMinimize}>
                <FaRocket />
              </SidebarLink>
              <SidebarLinkText to="/chatbot" $minimized={minimized} onClick={mobileMenuMinimize}>
                <span>Chatbot</span>
              </SidebarLinkText>
            </SidebarLinkWrapper> */}
            </>
            )}
            {/* 
            <SidebarLinkWrapper>
              <SidebarLink to="/sockettest" $minimized={minimized} onClick={mobileMenuMinimize}>
                <FaTools />
              </SidebarLink>
              <SidebarLinkText to="/sockettest" $minimized={minimized} onClick={mobileMenuMinimize}>
                <span>Lab</span>
              </SidebarLinkText>
            </SidebarLinkWrapper> 
            */}
            <SidebarLinkWrapper>
              <SidebarButton $minimized={minimized} onClick={handleLogoutClick}>
                <FaDoorOpen />
              </SidebarButton>
              <SidebarButtonText $minimized={minimized} onClick={handleLogoutClick}>
                <span>Logout</span>
              </SidebarButtonText>
            </SidebarLinkWrapper>
          </>
        )}
        {!isAuthenticated && (
          <>
          <SidebarLinkWrapper>
            <SidebarLink to="/register" $minimized={minimized} onClick={mobileMenuMinimize}>
              <FaUserPlus />
            </SidebarLink>
            <SidebarLinkText to="/register" $minimized={minimized} onClick={mobileMenuMinimize}>
              <span>Register</span>
            </SidebarLinkText>
          </SidebarLinkWrapper>
          <SidebarLinkWrapper>
            <SidebarLink to="/login" $minimized={minimized} onClick={mobileMenuMinimize}>
              <FaDoorClosed />
            </SidebarLink>
            <SidebarLinkText to="/login" $minimized={minimized} onClick={mobileMenuMinimize}>
              <span>Login</span>
            </SidebarLinkText>
          </SidebarLinkWrapper>
        </>
      )}
      </NavLinks>
        
        <HousekeepingLinks $minimized={minimized}>
          {/* <HLWrapper>
          <FooterLink to="/donate" reloadDocument><SidebarFooter $minimized={minimized}>Donate</SidebarFooter></FooterLink> */}
          {/* <SidebarFooter> - </SidebarFooter> */}
          {/* <FooterLink to="/founder"><SidebarFooter $minimized={minimized}>Contact Us</SidebarFooter></FooterLink> */}
            {/* <SidebarFooter>Terms of Service</SidebarFooter> 
            <SidebarFooter>Privacy Policy</SidebarFooter> */}
          {/* </HLWrapper> */}
          <HLWrapper>
            {/* <SidebarFooter>Cookie Policy</SidebarFooter> */}
              {/* <SidebarFooter>Roadmap</SidebarFooter>*/}
              <SidebarFooter $minimized={minimized}>© 2025 IIICOAST</SidebarFooter> 
          </HLWrapper>
        </HousekeepingLinks>
        </SidebarContainer>
      </>
    );
  };
  
  export default Sidebar;