// HabitModal.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Link } from 'react-router-dom';

// const ModalOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: rgba(0, 0, 0, 0.7);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const ModalContent = styled.div`
//   background: rgba(21, 21, 21, 0.75);
//   backdrop-filter: blur(10px);
//   padding: 20px;
//   border-radius: 10px;
//   width: 300px;
// `;

// const Input = styled.input`
//   width: 100%;
//   padding: 10px;
//   margin-bottom: 10px;
//   background: rgba(255, 255, 255, 0.1);
//   border: none;
//   color: #ffffff;
//   border-radius: 5px;
// `;

// const ColorOptions = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 10px;
// `;

// const ColorOption = styled.div`
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
//   background: ${props => props.color};
//   cursor: pointer;
//   border: ${props => props.selected ? '2px solid white' : 'none'};
// `;

// const Button = styled.button`
//   width: 100%;
//   padding: 10px;
//   background: #4CAF50;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// `;

const RSCWrapper = styled.div`
    padding: 15px;
`

const FollowCard = styled.div`
  // padding: 20px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid #333;
  background: #070707;
`;

const FollowCardHeader = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
`;

const SearchContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const UserContainer = styled.div`
  // border-bottom: 1px solid #222;
  padding: 3px 0;
`;

const UserLink = styled(Link)`
  color: #fff;
  text-decoration: none;

  img {
    vertical-align: middle;
    margin-right: 8px;
  }
`;

const ProfilePicture = styled.img`
  padding: 5px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const Container = styled.div`
  // padding: 20px;
`;

const RightSidebarContent = () => {
    const [recommendedUsers, setRecommendedUsers] = useState([]);

    useEffect(() => {
        fetchRecommendedUsers();
    }, []);

    const fetchRecommendedUsers = () => {
      axios.get('/api/get-recommended-users')
        .then(response => {
          setRecommendedUsers(response.data);
        })
        .catch(error => {
          console.error('Error fetching recommended users:', error);
        });
    };
//   const [habitName, setHabitName] = useState('');
//   const [selectedColor, setSelectedColor] = useState('#4CAF50');
//   const colors = ['#4CAF50', '#2196F3', '#F44336', '#FFC107', '#9C27B0'];

//   if (!isOpen) return null;

//   const handleAdd = () => {
//     if (habitName.trim()) {
//       onAdd(habitName, selectedColor);
//       setHabitName('');
//     }
//   };

  return (
    <FollowCard>
        <RSCWrapper>
        <FollowCardHeader>Who to Follow</FollowCardHeader>
        {/* <p>Sebastien</p> */}
        <SearchContainer>
          <Container>
            {recommendedUsers.map(user => (
              <UserContainer key={user.uid}>
                <UserLink to={`/profile/${user.username}`}>
                  <ProfilePicture src={`https://www.iiicoast.com/${user.profile_picture_url}`} alt={user.username} />
                  {user.username}
                  {/*<TweetCount>{user.tweet_count} tweets</TweetCount>*/}
                </UserLink>
              </UserContainer>
            ))}
          </Container>
        </SearchContainer>
        </RSCWrapper>
    </FollowCard>
  );
};

export default RightSidebarContent;