import React, { createContext, useContext, useState, useRef, useEffect, useCallback } from "react";

const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
  const [isAudioPlayerVisible, setIsAudioPlayerVisible] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [songQueue, setSongQueue] = useState([]);
  const audioRef = useRef(null);

  

  
  const playSong = (song, queue = []) => {
    setIsAudioPlayerVisible(true);
    setTimeout(() => {
      setCurrentSong(song);
      setIsPlaying(true);
      setSongQueue(queue);

      // if ('mediaSession' in navigator) {
      //   navigator.mediaSession.metadata = new MediaMetadata({
      //     title: song.title,
      //     artist: song.artist,
      //     album: song.album,
      //     artwork: [
      //       { src: song.thumbnail_url, sizes: '512x512', type: 'image/png' }
      //     ]
      //   });
      // }
      // ... other cleanup
    }, 150); // Match this to your animation duration
  };

  const playNextSong = useCallback(() => {
    const currentIndex = songQueue.findIndex(song => song.sid === currentSong?.sid);
    if (currentIndex >= 0 && currentIndex < songQueue.length - 1) {
      const nextSong = songQueue[currentIndex + 1];
      setCurrentSong(nextSong);
      setIsPlaying(true);

      // if ('mediaSession' in navigator) {
      //   navigator.mediaSession.metadata = new MediaMetadata({
      //     title: nextSong.title,
      //     artist: nextSong.artist,
      //     album: nextSong.album,
      //     artwork: [
      //       { src: nextSong.thumbnail_url, sizes: '512x512', type: 'image/png' }
      //     ]
      //   });
      // }
    } else {
      setIsPlaying(false);
    }
  }, [currentSong?.sid, songQueue]);

  const playPreviousSong = useCallback(() => {
    const currentIndex = songQueue.findIndex(song => song.sid === currentSong?.sid);
    if (currentIndex > 0) {
      const previousSong = songQueue[currentIndex - 1];
      setCurrentSong(previousSong);
      setIsPlaying(true);
      // if (audioRef.current) {
      //   audioRef.current.play();
      // }
    } else {
      setIsPlaying(false);
    }
  }, [currentSong?.sid, songQueue]);

  const togglePlayPause = useCallback(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying]);

  const handleProgressChange = (e) => {
    if (audioRef.current) {
      const newTime = (audioRef.current.duration / 100) * e.target.value;
      audioRef.current.currentTime = newTime;
      setProgress(e.target.value);
    }
  };

  const updateProgress = () => {
    if (audioRef.current) {
      const progressPercent = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(progressPercent);
    }
  };

  const closePlayer = () => {
    setIsAudioPlayerVisible(false);
    setTimeout(() => {
      setCurrentSong(null);
      setIsPlaying(false);
      setProgress(0);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      // ... other cleanup
    }, 300); // Match this to your animation duration
  };

  const updateMediaSessionMetadata = useCallback((song) => {
    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = new window.MediaMetadata({
        title: song.title,
        artist: song.artist,
        album: song.album || "Unknown Album",
        artwork: [
          { src: song.thumbnail_url || "/backend-static/upload/blankart.jpg", sizes: "512x512", type: "image/jpeg" },
        ],
      });

      navigator.mediaSession.setActionHandler("play", togglePlayPause); //() => playSong(song)
      navigator.mediaSession.setActionHandler("pause", togglePlayPause);
      navigator.mediaSession.setActionHandler("nexttrack", playNextSong);
      navigator.mediaSession.setActionHandler("previoustrack", playPreviousSong);
    }
  }, [playNextSong, playPreviousSong, togglePlayPause]);

  // Automatically show the audio player when there's a current song
  useEffect(() => {
    if (currentSong) {
      setIsAudioPlayerVisible(true);
      updateMediaSessionMetadata(currentSong);
    }
  }, [currentSong, updateMediaSessionMetadata]);

  return (
    <AudioPlayerContext.Provider
      value={{
        isAudioPlayerVisible,
        setIsAudioPlayerVisible,
        currentSong,
        setCurrentSong,
        isPlaying,
        setIsPlaying,
        progress,
        setProgress,
        audioRef,
        playSong,
        togglePlayPause,
        handleProgressChange,
        updateProgress,
        closePlayer,
        playNextSong,
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => useContext(AudioPlayerContext);
