// Transaction.js
import React from 'react';
import { TransactionItem, TransactionGrid, Label, Value, SignatureLink } from '../styles/CryptoStyles'; // Adjust imports accordingly
import { formatTimestamp, truncateAddress } from '../utils/cryptoUtils'; // Adjust utilities if needed

const Transaction = ({ transaction }) => {
  return (
    <TransactionItem key={transaction.signature}>
      <TransactionGrid>
        <div>
          <Label>Signature</Label>
          <div>
            <SignatureLink
              href={`https://solscan.io/tx/${transaction.signature}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {truncateAddress(transaction.signature)}
            </SignatureLink>
          </div>
        </div>
        <div>
          <Label>Date</Label>
          <div>
            <Value>{formatTimestamp(transaction.blockTime)}</Value>
          </div>
        </div>
        <div>
          <Label>Status</Label>
          <div>
            <Value style={{ color: transaction.err ? '#f44336' : '#4CAF50' }}>
              {transaction.err ? 'Failed' : 'Success'}
            </Value>
          </div>
        </div>
        <div>
          <Label>Block</Label>
          <div>
            <Value>{transaction.slot}</Value>
          </div>
        </div>
      </TransactionGrid>
    </TransactionItem>
  );
};

export default Transaction;

