import React from 'react';
import { useToast } from '../ToastContext';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


const TokenListContainer = styled.div`
  padding: 0 10px 0;
`;

const TokenIcon = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

const TokenContainer = styled.div`
  display: flex;
  padding: 0 10px 0;
  justify-content: space-between;
  align-items: center;
`;

const TokenInfo = styled.div`
  margin-left: 5px;
`;

const InfoGroup = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const CoinAddress = styled.div`
  color: #777;
  cursor: pointer;
`;

const TokenList = ({ tokens }) => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  
  const handleCopy = (text) => {
    // const walletAddress = "your-wallet-address-here"; // Replace with actual wallet address
    
    navigator.clipboard.writeText(text)
      .then(() => {
        showToast("Coin address copied.", "success");
      })
      .catch((err) => {
        showToast("Failed to copy address. Try again.", "danger");
        console.error("Clipboard copy failed: ", err);
      });
  };

  const handleTokenClick = (mintAddress) => {
    navigate(`/crypto/token/${mintAddress}`);
  };

  return (
    <TokenListContainer className="token-list">
      {tokens.map((token, index) => {
        const { metadata, mint_address } = token;
        const logo = metadata?.external_metadata?.image;
        const symbol = metadata?.data?.symbol;
        const mintAddress = mint_address;
        const abbreviatedMintAddress = mintAddress ? `${mintAddress.slice(0, 3)}...${mintAddress.slice(-4)}` : '';

        // Skip tokens without an image
        if (!logo) {
          return null;
        }

        return (
          <TokenContainer 
            key={mint_address} 
            className="token-item"
          >
            <InfoGroup onClick={() => handleTokenClick(mint_address)}>
              <div className="token-logo">
                <TokenIcon src={logo} alt={`${symbol} logo`} />
              </div>
              <TokenInfo className="token-info">
                <p>{symbol}</p>
              </TokenInfo>
            </InfoGroup>
            <CoinAddress onClick={() => handleCopy(mint_address)}>{abbreviatedMintAddress}</CoinAddress>
          </TokenContainer>
        );
      })}
    </TokenListContainer>
  );
};

export default TokenList;