import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { createChart } from 'lightweight-charts';

const PageContainer = styled.div`
  padding: 20px;
  color: white;
  // width: 100%;
`;

const CoinInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const CoinImage = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

const CoinName = styled.h1`
  margin: 0;
`;

const PriceInfo = styled.div`
  margin-left: 20px;
`;

const PriceChange = styled.span`
  color: ${props => props.$isPositive ? '#4caf50' : '#f44336'};
  margin-left: 10px;
`;

const ChartContainer = styled.div`
  height: 400px;
`;

const ChartControls = styled.div`
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
`;

const ChartButton = styled.button`
  background: ${props => props.$active ? '#4caf50' : '#333'};
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: ${props => props.$active ? '#45a049' : '#444'};
  }
`;

const CryptoCoinPage = () => {
  const { coinId } = useParams();
  const location = useLocation();
  const [chartData, setChartData] = useState(null);
  const [ohlcData, setOhlcData] = useState(null);
  const [coinData, setCoinData] = useState(location.state?.coin || null);
  const [chartType, setChartType] = useState('candlestick'); // 'line' or 'candlestick'
  const chartContainerRef = useRef();
  const chartRef = useRef(null)

  // Fetch coin data if not available from location state
  useEffect(() => {
    const fetchCoinData = async () => {
      if (!coinData) {
        try {
          const response = await fetch(`/api/coin/${coinId}`);
          if (!response.ok) {
            throw new Error('Failed to fetch coin data');
          }
          const data = await response.json();
          setCoinData(data);
        } catch (error) {
          console.error('Error fetching coin data:', error);
        }
      }
    };

    fetchCoinData();
  }, [coinId, coinData]);

  // Fetch chart data effect
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        // Fetch both line and OHLC data
        const [lineDataResponse, ohlcDataResponse] = await Promise.all([
          fetch(`/api/crypto-data?${new URLSearchParams({
            endpoint: `/coins/${coinId}/market_chart`,
            vs_currency: 'usd',
            days: '365'
          })}`),
          fetch(`/api/crypto-data?${new URLSearchParams({
            endpoint: `/coins/${coinId}/ohlc`,
            vs_currency: 'usd',
            days: '365'
          })}`)
        ]);

        if (!lineDataResponse.ok || !ohlcDataResponse.ok) {
          throw new Error('Failed to fetch chart data');
        }

        const [lineData, ohlcData] = await Promise.all([
          lineDataResponse.json(),
          ohlcDataResponse.json()
        ]);

        setChartData(lineData);
        setOhlcData(ohlcData);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData();
  }, [coinId]);

  // Chart creation effect
  useEffect(() => {
    if (!chartContainerRef.current || (!chartData && !ohlcData)) return;

    // Remove existing chart if it exists
    if (chartRef.current) {
      chartRef.current.remove();
    }

    // Create new chart
    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { color: '#000' },
        textColor: '#fff',
        attributionLogo: false,
      },
      width: chartContainerRef.current.offsetWidth,
      height: 400,
      grid: {
        vertLines: { color: '#2B2B43' },
        horzLines: { color: '#2B2B43' },
      },
    });

    chartRef.current = chart;

    if (chartType === 'line' && chartData?.prices) {
      const lineSeries = chart.addLineSeries();
      lineSeries.setData(
        chartData.prices.map(([time, price]) => ({
          time: time / 1000,
          value: price
        }))
      );
    } else if (chartType === 'candlestick' && ohlcData) {
      const candlestickSeries = chart.addCandlestickSeries({
        upColor: '#064d00', // 4caf50
        downColor: '#f87171', // f44336
        borderDownColor: '#f87171', // ff4976
        borderUpColor: '#16a34a', // 4bffb5
        // borderVisible: false,
        wickUpColor: '#16a34a', // 4caf50
        wickDownColor: '#f87171', // f44336
      });
      
      candlestickSeries.setData(
        ohlcData.map(([time, open, high, low, close]) => ({
          time: time / 1000,
          open,
          high,
          low,
          close,
        }))
      );
    }

    const handleResize = () => {
      chart.applyOptions({
        width: chartContainerRef.current.offsetWidth
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartData, ohlcData, chartType]);

  if (!coinData) {
    return <PageContainer>Loading...</PageContainer>;
  }

  return (
    <PageContainer>
      <CoinInfo>
        <CoinImage src={coinData.image} alt={coinData.name} />
          <CoinName>{coinData.name}</CoinName>
          <PriceInfo>
            ${Number(coinData.current_price).toLocaleString()}
            <PriceChange $isPositive={coinData.price_change_percentage_24h > 0}>
              {coinData.price_change_percentage_24h > 0 ? '↑' : '↓'}
              {Math.abs(coinData.price_change_percentage_24h).toFixed(2)}%
            </PriceChange>
          </PriceInfo>
      </CoinInfo>
      <ChartControls>
        <ChartButton 
          onClick={() => setChartType('line')}
          $active={chartType === 'line'}
        >
          Line Chart
        </ChartButton>
        <ChartButton 
          onClick={() => setChartType('candlestick')}
          $active={chartType === 'candlestick'}
        >
          Candlestick Chart
        </ChartButton>
      </ChartControls>
      <ChartContainer ref={chartContainerRef} />
    </PageContainer>
  );
};

export default CryptoCoinPage;