import React, { useState, useEffect, useRef } from 'react';
// import useWindowSize from '../hooks/useWindowSize';
// import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import AnimatedTitle from '../../components/portfolio/AnimatedTitle';
import { SiJavascript, SiReact, SiNodedotjs, SiPython, SiMysql, SiPytorch, SiTensorflow, SiJupyter } from 'react-icons/si';
// SiDocker, SiMariadb, SiAnaconda, SiArrowDown 
// import { FaArrowDown } from 'react-icons/fa'; //, FaExternalLinkAlt
// FaEnvelope 
import { LiaFlagUsaSolid } from "react-icons/lia";
// import { IoMdPhonePortrait } from "react-icons/io";
// import { MdComputer } from "react-icons/md";
// import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import axios from 'axios';
import {
  FooterWrapper,
  Footer,
  FooterLeft,
  FooterRight,
  FooterTitle,
  FooterSubTitle,
  SubtTitleWrapper,
  FooterIcon,
  PortfolioContainer,
  SplashSection,
  ScrollIndicator,
  AnimatedArrow,
  SectionTextContainer,
  SectionTextMidContainer,
  SectionTextSmall,
  SectionText,
  SectionTextMid,
  SectionTextAlt,
  SectionImageContainer,
  SectionVideoContainer,
  SectionImage,
  SectionImageBackground,
  SectionVideoBackground,
  IntroSection,
  SkillsSection,
  ProjectsSection,
  FooterSection,
  ProjectCard,
  ContactCard,
  ContactTitle,
  ContactForm,
  SkillsBackground,
  SkillsForeground,
  SkillsContainer,
  SkillIcon,
  Tooltip,
  StyledVideo,
  ShinyButton,
  ButtonOverlay,
  ButtonText,
  SubTitle,
  SubTitleMuted,
  Input,
  TextAreaInput,
  ProjectWrapper,
  ProjectHeader,
  CardWrapper,
  LLMCard,
  AutoCard,
  ThreeDCard,
  ProjectText,
  CardSubTitle,
} from './PortfolioStyles'; // Move styled components to a separate file


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // (or in the top-level file in your React app)

const Portfolio = () => {
  const skills = [
    // Python, SQL, HTML, JavaScript, Microsoft Visual Studio Code, MySQL, MariaDB, Google Analytics, PyTorch, TensorFlow,
    // Anaconda, Jupyter Notebook, GitHub
    { icon: <SiJavascript />, rotation: Math.random() * 20 - 10, description: "JavaScript" },
    { icon: <SiNodedotjs />, rotation: Math.random() * 20 - 10, description: "Node.js" },
    { icon: <SiReact />, rotation: Math.random() * 20 - 10, description: "React" },
    { icon: <SiPython />, rotation: Math.random() * 20 - 10, description: "Python" },
    { icon: <SiMysql />, rotation: Math.random() * 20 - 10, description: "MySQL" },
    // { icon: <SiMariadb />, rotation: Math.random() * 20 - 10, description: "MariaDB" },
    // { icon: <SiHtml5 />, rotation: Math.random() * 20 - 10, description: "HTML5" },
    // { icon: <SiCss3 />, rotation: Math.random() * 20 - 10, description: "CSS3" },
    { icon: <SiPytorch />, rotation: Math.random() * 20 - 10, description: "PyTorch" },
    { icon: <SiTensorflow />, rotation: Math.random() * 20 - 10, description: "TensorFlow" },
    // { icon: <SiAnaconda />, rotation: Math.random() * 20 - 10, description: "Anaconda" },
    { icon: <SiJupyter />, rotation: Math.random() * 20 - 10, description: "Jupyter" },
    // { icon: <SiDocker />, rotation: Math.random() * 20 - 10, description: "Docker" },
    // { icon: <SiAmazonwebservices />, rotation: Math.random() * 20 - 10, description: "AWS" }
  ];

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState(''); // For handling response
  // const [mobileView, setMobileView] = useState(false);

  const sectionRefs = useRef([]);
  const containerRef = useRef(null);
  // const { width } = useWindowSize();
  // const isMobile = width <= 768;
  const navigate = useNavigate();

  // const handleMobileView = () => {
  //   setMobileView((prevState) => !prevState);
  // };

  useEffect(() => {
    const sections = sectionRefs.current;

    // Set up animations for each section
    sections.forEach((section, index) => {
      // const elements = section.querySelectorAll('h1, h2, div, li');
      const image = section.querySelector('.section-image');
      const imageContainer = section.querySelector('.section-image-container');
      const text = section.querySelectorAll('.section-text');
      const textAlt = section.querySelectorAll('.section-text-alt');
      const skillIcons = section.querySelectorAll('.skill-icon');
      // const projectCard = section.querySelectorAll('.project-card');
      
      // Scroll-into-view animations (scrolling down)

      gsap.fromTo(
        skillIcons,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: section,
            start: 'top 10%',
            end: 'center 50%',
            scrub: 1,
            toggleActions: 'play none none reverse',
          },
        }
      );


      gsap.fromTo(
        image,
        { scale: 0.8 }, // Initial state when coming into view
        {
          scale: 1,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'top 50%', // Start when top of section reaches the middle
            end: 'center 50%', // End when center of section reaches the middle
            // markers: true,
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
    
      gsap.fromTo(
        imageContainer,
        { rotate: '-10deg', clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)' }, // Initial state when coming into view
        {
          rotate: '0deg',
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'top 50%',
            end: 'center 50%',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
    
      gsap.fromTo(
        text,
        { x: '-100%', opacity: 0 }, // Initial state when coming into view
        {
          x: '0%',
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'top 50%',
            end: 'center 85%',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
    
      gsap.fromTo(
        textAlt,
        { x: '-200%', opacity: 0 }, // Initial state when coming into view
        {
          x: '0%',
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'top 50%',
            end: 'center 85%',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
      
      // // Scroll-out-of-view animations (scrolling up)
      // gsap.fromTo(
      //   projectCard,
      //   { opacity: 0, y: -50 }, // Fully visible state
      //   {
      //     opacity: 1,
      //     y: 50, // Move elements upwards and fade them out
      //     duration: 1,
      //     scrollTrigger: {
      //       trigger: section,
      //       start: 'top 50%', // When bottom reaches middle of the screen
      //       end: 'center 85%', // When the section has fully scrolled out
      //       scrub: true,
      //       toggleActions: 'play none none reverse', // Animate both on scroll-up and scroll-down
      //     },
      //   }
      // );

      // Scroll-out-of-view animations (scrolling up)
      // gsap.fromTo(
      //   elements,
      //   { opacity: 1, y: 0 }, // Fully visible state
      //   {
      //     opacity: 0,
      //     y: -50, // Move elements upwards and fade them out
      //     duration: 1,
      //     scrollTrigger: {
      //       trigger: section,
      //       start: 'bottom 50%', // When bottom reaches middle of the screen
      //       end: 'top 0%', // When the section has fully scrolled out
      //       scrub: true,
      //       toggleActions: 'play none none reverse', // Animate both on scroll-up and scroll-down
      //     },
      //   }
      // );
    
      // Example for reverse behavior on image when scrolling out upwards
      gsap.fromTo(
        image,
        { scale: 1 }, // State when fully visible
        {
          scale: 0.8, // Shrink when scrolling out upwards
          scrollTrigger: {
            trigger: section,
            start: 'bottom 50%', // Starts when bottom is in middle of the viewport
            end: 'top top', // Ends when section is fully scrolled out
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
      gsap.fromTo(
        imageContainer,
        { rotate: '0deg', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' }, // Initial state when coming into view
        {
          rotate: '10deg',
          clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)',
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'bottom 90%',
            end: 'top top',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
      gsap.fromTo(
        text,
        { x: '0%', opacity: 1 }, // Initial state when coming into view
        {
          x: '-100%',
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'bottom 65%',
            end: 'top top',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
    
      gsap.fromTo(
        textAlt,
        { x: '0%', opacity: 1 }, // Initial state when coming into view
        {
          x: '-200%',
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'bottom 65%',
            end: 'top top',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
      gsap.fromTo(
        skillIcons,
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          y: 50,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: section,
            start: 'bottom 65%',
            end: 'top 25%',
            scrub: 1,
            toggleActions: 'play none none reverse',
          },
        }
      );

      
    });
    

    let snapScrollTrigger = ScrollTrigger.create({
      snap: {
        snapTo: (progress) => {
          const sectionIndex = Math.round(progress * (sections.length - 1));
          return sectionIndex / (sections.length - 1);
        },
        duration: { min: 0.5, max: 0.75 }, // Shortened duration for quicker response
        ease: "power4.inOut", // Changed to a gentler easing function
        delay: 0.25, // Small delay to allow for natural scrolling
      },
      trigger: containerRef.current,
      start: 'top top',
      end: 'bottom bottom',
      scrub: 0.5,
    });

    return () => {
      // Clean up
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      if (snapScrollTrigger) snapScrollTrigger.kill();
    };
  }, []);

    const handleArrowClick = () => {
      // Scroll to IntroSection
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: sectionRefs.current[1], offsetY: 0 },
        ease: "power4.inOut"
      });
    };
    
    const handleProjectButtonClick = () => {
      navigate('/');
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setSubmitStatus('sending');
      
      if (!contactName && !contactEmail && !contactMessage) {
        setSubmitStatus('error')
        return
      }

      try {
        const response = await axios.post('/v2/contact', {
          name: contactName,
          email: contactEmail,
          message: contactMessage
        });
  
        if (response.status === 200) {
          setSubmitStatus('success');
          setContactName('');
          setContactEmail('');
          setContactMessage('');
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setSubmitStatus('error');
      }
    };

    return (
      <>
      {/* <Header>
        <HeaderLeft>
          <HeaderTitle>Sebastien Phillips</HeaderTitle>
          <HeaderSubTitle>Full-Stack Engineer</HeaderSubTitle>
        </HeaderLeft>
        <HeaderRight>
          <HeaderLink href='https://www.linkedin.com/in/sebastienphillips/'>
            Linkedin<FaExternalLinkAlt />
          </HeaderLink>
          <HeaderLink href='https://drive.google.com/file/d/1T6ltCCP-H0ccBAy3l-1YRC_ljH8P0Rxj/view?usp=sharing'>
            Resume
            <FaExternalLinkAlt />
          </HeaderLink>
        </HeaderRight>
      </Header> */}
      <PortfolioContainer ref={containerRef}>
        
        <SplashSection ref={(el) => (sectionRefs.current[0] = el)}>
          <AnimatedTitle title="Sebastien Phillips " cols={36} n={756} />
          <AnimatedTitle title="Designer Engineer "  cols={41} n={82} />
          <ScrollIndicator onClick={handleArrowClick}><AnimatedArrow /></ScrollIndicator>
        </SplashSection>

        <IntroSection ref={(el) => (sectionRefs.current[1] = el)}>
          <SectionImageBackground>
            <SectionImageContainer className="section-image-container">
              <SectionImage 
                className="section-image" 
                src="https://www.iiicoast.com/backend-static/founder/img-1.jpg" 
                alt="Section 1 Image" 
              />
            </SectionImageContainer>
            <SectionTextContainer>
              {/*
              <SectionTextAlt className="section-text-alt">
              👋🏾 // ✨
              </SectionTextAlt>
              */}
              <SectionText className="section-text">
              Full-stack engineer with
              </SectionText>
              <SectionTextAlt className="section-text-alt">
                a passion for creating
              </SectionTextAlt>
              <SectionText className="section-text">
                beautiful, high-
              </SectionText>
              <SectionTextAlt className="section-text-alt">
                performance web
              </SectionTextAlt>
              <SectionText className="section-text">
                applications.
              </SectionText>
            </SectionTextContainer>
          </SectionImageBackground>
          <SkillsBackground >
            <SkillsForeground>
              <SectionTextSmall className="skill-icon">
                Skills
              </SectionTextSmall>
            <SkillsContainer>
              {skills.map((skill, index) => (
                  <SkillIcon className="skill-icon" key={index} rotation={skill.rotation}>
                      {skill.icon}
                      <Tooltip>{skill.description}</Tooltip>
                  </SkillIcon>
              ))}
            </SkillsContainer>
            </SkillsForeground>
          </SkillsBackground>
        </IntroSection>
        <SkillsSection ref={(el) => (sectionRefs.current[2] = el)}>
          <ProjectCard className='project-card'>
            {/* $mobileView={mobileView} 
             <ProjectViewToggle $mobileView={mobileView} onClick={handleMobileView}>
            {mobileView ? (
              <>
                <MdComputer />
              </>
            ) : (
              <>
                <IoMdPhonePortrait />
              </>
            )}
          </ProjectViewToggle>
            */}
          <SectionTextMidContainer>
              {/*
              <SectionTextAlt className="section-text-alt">
              👋🏾 // ✨
              </SectionTextAlt>
              */}
              <SectionTextMid className="section-text">
              Capstone Project
              </SectionTextMid>
              <SubTitle>Social Media Application<SubTitleMuted> - lampr version 3.2</SubTitleMuted></SubTitle>
              {/* 
              <SectionTextAlt className="section-text-alt">
                a passion for creating
              </SectionTextAlt>
              <SectionText className="section-text">
                beautiful, high-
              </SectionText>
              <SectionTextAlt className="section-text-alt">
                performance web
              </SectionTextAlt>
              <SectionText className="section-text">
                applications.
              </SectionText>
               */}
              
            </SectionTextMidContainer>
        <SectionVideoBackground>
            <SectionVideoContainer className="section-image-container">
              <StyledVideo className="skill-icon" autoPlay muted loop>
                <source src="https://www.iiicoast.com/backend-static/upload/UIrecording2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </StyledVideo>
            </SectionVideoContainer>
          </SectionVideoBackground>
          {/*
          <SkillsBackground >
            <SkillsForeground>
              <SectionTextSmall className="skill-icon">
                Skills
              </SectionTextSmall>
            <SkillsContainer>
              {skills.map((skill, index) => (
                  <SkillIcon className="skill-icon" key={index} rotation={skill.rotation}>
                      {skill.icon}
                      <Tooltip>{skill.description}</Tooltip>
                  </SkillIcon>
              ))}
            </SkillsContainer>
            </SkillsForeground>
          </SkillsBackground>
          */}
          <ShinyButton
            initial={{ "--x": "100%" }}
            animate={{ "--x": "-100%" }}
            whileTap={{ scale: 0.8 }}
            transition={{
              "--x": {
                repeat: Infinity,
                repeatType: "loop",
                repeatDelay: 1,
                type: "spring",
                stiffness: 20,
                damping: 15,
                mass: 2,
              }
            }} // transition is now only applied to --x
            onClick={handleProjectButtonClick}
          >
          <ButtonText>
            View Project
          </ButtonText>
          <ButtonOverlay className='linear-overlay'/>
          </ShinyButton>
          </ProjectCard>
        </SkillsSection>

        <ProjectsSection ref={(el) => (sectionRefs.current[3] = el)}>
          <ProjectWrapper>
            <ProjectHeader>More Projects</ProjectHeader>
            <CardWrapper>
              <LLMCard>
                <ProjectText>LLM Content Generation</ProjectText>
                <CardSubTitle>PyTorch, GPT2 & LLama</CardSubTitle>
              </LLMCard>
              <AutoCard>
                <ProjectText>Automation</ProjectText>
                <CardSubTitle>Selenium & AHK</CardSubTitle>
              </AutoCard>
              <ThreeDCard>
                <ProjectText>3D Design</ProjectText>
                <CardSubTitle>Unreal Engine, Blender & Three.js</CardSubTitle>
              </ThreeDCard>
            </CardWrapper>
          </ProjectWrapper>
          {/*
           <ProjectCard className='project-card'>
          <HeaderText>
            <Title>Project Title</Title>
            <SubTitle>Project Subtitle</SubTitle>
          </HeaderText>
          <StyledVideo2 src="https://www.iiicoast.com/backend-static/upload/UIrecording.mp4" controls />
          <ShinyButton2>Learn More</ShinyButton2>
        </ProjectCard>
          */}
        {/*
        <Title>Projects</Title>
          <ProjectGrid>
            <ProjectCardTemp>
              <ProjectImage src="https://picsum.photos/400/300?random=1" alt="Project 1" />
              <h3>E-commerce Platform</h3>
              <p>A full-stack e-commerce solution with React and Node.js</p>
            </ProjectCardTemp>
            <ProjectCardTemp>
              <ProjectImage src="https://picsum.photos/400/300?random=2" alt="Project 2" />
              <h3>Task Management App</h3>
              <p>A real-time task management application using Socket.io</p>
            </ProjectCardTemp>
          </ProjectGrid>
        */}
        </ProjectsSection>

        <FooterSection ref={(el) => (sectionRefs.current[4] = el)}>
          <ContactCard>  
            <ContactTitle>🤙🏾 Get in Touch</ContactTitle>
            <SubtTitleWrapper>
              {/*
              <ContactIcons>
                <p>Email: john.doe@example.com</p>
                <p>GitHub: github.com/johndoe</p>
                <p>LinkedIn: linkedin.com/in/johndoe</p>
                <MailIcon>
                  <FaEnvelope />
                </MailIcon>
              </ContactIcons>
              */}
              <ContactForm onSubmit={handleSubmit}>
                <Input 
                  id="name" 
                  type="text" 
                  value={contactName} 
                  onChange={(e) => setContactName(e.target.value)} 
                  placeholder="Name" 
                />
                <Input 
                  id="email" 
                  type="text" 
                  value={contactEmail} 
                  onChange={(e) => setContactEmail(e.target.value)} 
                  placeholder="Email" 
                />
                <TextAreaInput 
                  id="message" 
                  type="text" 
                  value={contactMessage} 
                  onChange={(e) => setContactMessage(e.target.value)} 
                  placeholder="Message" 
                />
                <ShinyButton
                  type="submit"
                  initial={{ "--x": "100%" }}
                  animate={{ "--x": "-100%" }}
                  whileTap={{ scale: 0.8 }}
                  transition={{
                    "--x": {
                      repeat: Infinity,
                      repeatType: "loop",
                      repeatDelay: 1,
                      type: "spring",
                      stiffness: 20,
                      damping: 15,
                      mass: 2,
                    }
                  }} // transition is now only applied to --x
                  // onClick={handleProjectButtonClick}
                >
                  <ButtonText>
                    {submitStatus === 'sending' ? 'Sending...' : 'Submit'}
                  </ButtonText>
                  <ButtonOverlay className='linear-overlay'/>
                </ShinyButton>
                {submitStatus === 'success' && <p>Message sent successfully!</p>}
                {submitStatus === 'error' && <p>Error sending message. Please try again.</p>}
              </ContactForm>
            </SubtTitleWrapper>
          </ContactCard>
          <Footer>
              <FooterLeft>
                <FooterTitle>© 2024 Sebastien Phillips. All Rights Reserved.</FooterTitle>
                <FooterSubTitle><FooterWrapper>Made in <FooterIcon><LiaFlagUsaSolid /></FooterIcon></FooterWrapper></FooterSubTitle>
              </FooterLeft>
              <FooterRight>Last updated by Sebastien on November 17, 2024, 5:53 AM CST</FooterRight>
            </Footer>
        </FooterSection>
        
      </PortfolioContainer>
    </>
    );
  };
  
export default Portfolio;
