import React, { useMemo } from 'react';
// import styled from 'styled-components';
import TagLink from '../TagLink';
import styled from 'styled-components';

const ContentStyle = styled.div`
  margin: 0;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5;
  word-break: break-word;
`;

// Utility function to parse cashtags
// const parseCashtags = (content) => {
//   // const regex = /\$([A-Za-z][A-Za-z0-9]*)\b/g;
//   const regex = /[$#]([A-Za-z][A-Za-z0-9]*)\b/g;
//   let lastIndex = 0;
//   const parts = [];
//   let match;

//   while ((match = regex.exec(content)) !== null) {
//     // Add text before cashtag
//     if (match.index > lastIndex) {
//       parts.push(content.substring(lastIndex, match.index));
//     }
    
//     // Add cashtag as object
//     parts.push({
//       type: 'cashtag',
//       symbol: match[1],
//       original: match[0]
//     });
    
//     lastIndex = regex.lastIndex;
//   }

//   // Add remaining text
//   if (lastIndex < content.length) {
//     parts.push(content.substring(lastIndex));
//   }

//   return parts;
// };

const parseTags = (content) => {
  const regex = /[$#]([A-Za-z][A-Za-z0-9]*)\b/g;
  let lastIndex = 0;
  const parts = [];
  let match;

  while ((match = regex.exec(content)) !== null) {
    // Add text before tag
    if (match.index > lastIndex) {
      parts.push(content.substring(lastIndex, match.index));
    }

    // Add tag as object with its symbol ($ or #)
    parts.push({
      type: 'tag',
      symbol: match[0][0], // Capture the symbol ($ or #)
      tag: match[1],       // Capture the tag (A-Za-z0-9)
      original: match[0],  // Original matched string
    });

    lastIndex = regex.lastIndex;
  }

  // Add remaining text
  if (lastIndex < content?.length) {
    parts.push(content.substring(lastIndex));
  }

  return parts;
};



const PostContent = ({ children }) => {
  // const parsedContent = useMemo(() => parseCashtags(children), [children]);
  const parsedContent = useMemo(() => parseTags(children), [children]);
  
  return (
    <ContentStyle className="post-content">
      {parsedContent.map((part, index) => {
        if (typeof part === 'string') {
          return part;
        }
        return (
          <TagLink key={index} symbol={part.symbol} tag={part.tag}>
            {part.original}
          </TagLink>
        );
      })}
    </ContentStyle>
  );
};

export default PostContent;
