import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import axiosInstance from './axiosConfig';
import { useToast } from './ToastContext';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showToast } = useToast();

  // useEffect(() => {
  //   checkAuthentication();
  // }, []);

  const checkAuthentication = useCallback(async () => {
    try {
      const return_response = await axios.get('/auth/returning-user');
      if (return_response.data.username) {
        setUser(return_response.data.username);
      } else {
        setUser(null);
      }
      // Make a request to a protected endpoint to verify authentication
      const response = await axiosInstance.post(
        '/auth/protected',
        {} // Empty data object as the body is not necessary for this request
      );

      if (response.status === 200) {
        // If response status is 200, user is authenticated
        // console.log(response.data.logged_in_as)
        setUid(response.data.logged_in_as)
        setIsAuthenticated(true);
      } else {
        // If response status is not 200, user is not authenticated
        setIsAuthenticated(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data.msg;
        if (errorMessage === "Token has expired") {
          // Handle token expiration
          setIsAuthenticated(false);
          // setUser(null);
          // setUid(null);
          showToast("Your session has expired. Please log in again.", "info");
        } else {
          // Handle other 401 cases (e.g., no token)
          console.error('Error checking authentication:', error);
          setIsAuthenticated(false);
        }
      } else {
        console.error('Error checking authentication:', error);
        setIsAuthenticated(false);
      }
    } finally {
      setLoading(false);
    }
  }, [showToast]);

  const login = async (username, password) => {
    try {
      const response = await axios.post('/auth/login', { username, password });
      console.log(response)
      setUid(response.data.logged_in_as)
      setIsAuthenticated(true);
      // setUser(response.data.user);
      return; // response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post('/auth/logout', {}, { withCredentials: true });
      setIsAuthenticated(false);
      // setUser(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  useEffect(() => {
    // Initial authentication check
    checkAuthentication();

    // Add a visibility change listener
    const handleVisibilityChange = async () => {
      // if (document.visibilityState === 'visible') {
      //   checkAuthentication();
      // }
      if (document.visibilityState === 'visible') {
        // const wasAuthenticated = isAuthenticated; // Save the current authentication state
        await checkAuthentication(); // Check authentication status
        // if (!isAuthenticated) { // && wasAuthenticated
        //   // Notify user only if they were authenticated before and now are not
        //   showToast("You've been logged out", "info");
        // }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the listener on unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isAuthenticated, showToast, checkAuthentication]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, uid, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};