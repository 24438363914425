import React from 'react';
import styled from 'styled-components';
import { BarLoader } from 'react-spinners';
import { SpinnerContainer } from '../styles/shared/SharedStyles';

const LoaderContainer = styled.div`
  padding: 20px;
`;

function Loader() {
  const loading = true
  
  return (
    <LoaderContainer>
      <SpinnerContainer>
        <BarLoader loading={loading} />
      </SpinnerContainer>
    </LoaderContainer>
  );
}

export default Loader;