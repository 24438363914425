// SharedStyles.js
import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export const TabHeader = styled.div`
  display: flex;
  align-items: bottom;
  // justify-content: center;
  color: #fff;
  border-bottom: 1px solid #212121;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
`;

export const Tab = styled.span`
  padding: 5px 15px 5px;
  vertical-align: middle;
  color: ${props => props.$active ? '#fff' : '#999'};
  line-height: 40px;
  border-bottom: ${props => props.$active ? '3px solid #fff' : 'none'};
  font-weight: ${props => props.$active ? '600' : 'normal'};
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover {
    color: #fff;
  }
`;