import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaAngleUp, FaAngleDown, FaSearch } from "react-icons/fa";
import TokenList from '../components/TokenList';
import useWindowSize from '../hooks/useWindowSize';


const Container = styled.div`
  // padding: 20px;
  color: white;
  width: 100%;
  max-width: 100%;
`;

// const PaddingContainer = styled.div`
//   // margin: 20px;
//   // color: white;
// `;

const CardContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: stretch; /* Default behavior */
  // height: 250px;
  // margin-bottom: 15px;
  // gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const SubCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.3333%;
  // height: 100%;
  // width: 33.3vw;
  flex: 1; /* Equal width distribution */
  // gap: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%
  }
`;

const SubCard = styled.div`
  width: 100%;
  height: 100%;
  background: #070707;
  // flex: 1;
  // border-radius: 20px;
  border: 1px solid #222;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%
  }
`;

const Card = styled.div`
  width: 33.3333%;
  // width: 33.3vw;
  flex: 1; /* Equal width distribution */
  background: #070707;
  // border-radius: 20px;
  border: 1px solid #222;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%
  }
`;

const CardText = styled.div`
  padding: 12px 20px 6px;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.h3`

`;

const CardSubTitle = styled.div`
  opacity: 0.5;
  margin-bottom: 15px;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling for the table */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on mobile */
  margin: 0 auto; /* Centers the wrapper in the viewport */
`;

const CoinTable = styled.table`
  width: 100%;
  border-collapse: inherit;
  border-spacing: 0; /* Ensures no space between cells */
  border: 1px solid #222;
  background: #070707;
  // border-radius: 20px;
  overflow-x: auto; /* Allows horizontal scrolling on smaller screens */
`;

const CoinRow = styled.tr`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid #333;
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
`;

const TableHeader = styled.th`
  //padding-left: 24px; /* Adds additional left padding */
  text-align: left;
  padding: 16px 24px 12px;
  // position: sticky;
  // left: 0; /* Sticks the first column */
  // z-index: 2; /* Keeps the header above the content */

  /* Ensure first column sticks */
  &:first-child {
    position: sticky;
    left: 0;
    background: #070707; /* Ensure consistency with header background */
    border-radius: 20px;
    z-index: 1;
  }
`;

const TableCell = styled.td`
  // padding: 12px;
  padding: 16px 24px 16px;
  vertical-align: middle;
  background: transparent; /* No background until scrolled */

  /* Ensure first column sticks */
  &:first-child {
    position: sticky;
    left: 0;
    // background-color: rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    z-index: 1;
  }

  /* Optional: On hover of first column, ensure blur effect remains */
  &:first-child:hover {
    backdrop-filter: blur(10px); /* Stronger blur on hover if needed */
    -webkit-backdrop-filter: blur(20px);
  }
`;

const CellContent = styled.div`
  display: flex;
  align-items: center; /* Centers the image and text vertically */
  gap: 10px; /* Adds spacing between the image and text */
`;

const CoinImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const CoinName = styled.span`
  // margin-bottom: 30px;
  // padding: 15px;
`;

const PriceChange = styled.span`
  display: flex;
  align-items: center;
  color: ${props => props.$isPositive ? '#0c9c84' : '#f43444'};
`;

const Ticker = styled.div`
  // padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #070707;
  height: 40px;
  // width: 91.4vw;
  width: 100%;
  // border: 1px solid #222;
  box-sizing: border-box;
  // margin-bottom: 6px;
  // overflow-x: hidden;

`;

// const InputContainer = styled.div`
//   // display: flex;
//   display: ${props => (props.$isMobile) ? 'none' : 'flex'};
//   align-items: center;
//   // margin-top: 10px;
//   // margin-bottom: 10px;
//   // padding: 5px;
//   // border-radius: 25px;
//   border: 1px solid #212121;
//   width: 33.3333%;
//   height: 100%;
// `;

const MessageInput = styled.input`
  font-size: 16px;
  background-color: transparent;
  color: #fff;
  border: none;
  flex: 1;
  padding: 5px 10px;
  outline: none;
`;

const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 35px; /* Adjust as needed for the size of the circle */
  height: 100%; /* Ensure the width and height are the same */
  // border-radius: 50%;
  background-color: #0c9c84;
  color: white;
  // padding: 5px 10px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #55baa9; /* Shared hover color */
  }
`;

// const TickerGroupContainer = styled`
//   width: 100px;
// `;

// const TickerGroup = styled.div`
//   display: flex;
//   // padding: 10px;
//   gap: 10px:
//   width: 66.666%;
//   margin-left: 15px;
//   // overflow-x: auto; /* Enables horizontal scrolling for the table */
// `;

const TickerInfo = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 15px;
  margin-right: 10px;
  // white-space: nowrap
`;

// const TickerContent = styled.div`
//   display: flex;
//   white-space: nowrap;
//   width: 850px;

//   @media (max-width: 768px) {
//     width: 350px;
//   }
//   @media (min-width: 768px) and (max-width: 1200px) {
//     width: 550px;
//   }

//   @media (min-width: 768px) and (max-width: 1400px) {
//     width: 650px;
//   }
// `;

// const TickerInfoTest = styled.div`
//   flex: 2;
//   overflow: hidden;
//   white-space: nowrap;
//   background: #333;
//   display: flex;
//   align-items: center;
//   padding: 0 10px;
// `;

// const TickerSignal = styled.div`

// `;

const WalletList = styled.div`
  background-color: #070707;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const WalletItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #323232;
  color: #f0f0f0;
  text-decoration: none;
  
  &:hover {
    background-color: #2a2a2a;
  }
  
  &:last-child {
    border-bottom: none;
  }
`;

const WalletAddress = styled.span`
  font-family: 'Courier New', monospace;
  word-break: break-all;
`;

const InfoCluster = styled.div`
  display: flex;
  flex-direction: column;
`;

const TickerMain = styled.div`
  height: 100%;
  width: 66.6667%;
  // width: 33.3vw;
  flex: 2; /* Equal width distribution */
  display: flex;
  align-items: center;
  background: #070707;
  // border-radius: 20px;
  border: 1px solid #222;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%
  }
`;

const TickerSearch = styled.div`
  height: 100%;
  width: 33.3333%;
  // width: 33.3vw;
  flex: 1; /* Equal width distribution */
  // display: flex;
  display: ${props => (props.$isMobile) ? 'none' : 'flex'};
  align-items: center;
  background: #070707;
  // border-radius: 20px;
  border: 1px solid #222;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%
  }
`;

const formatMarketCap = (marketCap) => {
  if (marketCap >= 1e12) return `$${(marketCap / 1e12).toFixed(1)}T`;
  if (marketCap >= 1e9) return `$${(marketCap / 1e9).toFixed(1)}B`;
  if (marketCap >= 1e6) return `$${(marketCap / 1e6).toFixed(1)}M`;
  return `$${(marketCap / 1e3).toFixed(1)}K`;
};

const CryptoPage = () => {
  const [coins, setCoins] = useState([]);
  const [tokens, setTokens] = useState([]);
  // const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const walletAddresses = [
    'ACaz8DSuKiLeXQfPhfk7xTmTXS3d4KoipaPBWN5S9e6K', // Replace with your addresses
    'HABhDh9zrzf8mA4SBo1yro8M6AirH2hZdLNPpuvMH6iA',
    'mainyjZ4kGiQM9KvmxjdnzvpGz8sDU5chCYYzjBdLKx',
    // Add more addresses as needed
  ]

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const response = await fetch('/api/crypto-data?endpoint=/coins/markets&vs_currency=usd');
        const data = await response.json();
        setCoins(data);
        // setCoins(data.slice(0, 12));
      } catch (error) {
        console.error('Error fetching coins:', error);
      }
    };

    fetchCoins();
  }, []);

  useEffect(() => {
    const fetchTokens = async () => {
      try {
          const response = await fetch("/api/get_new_tokens");
          const data = await response.json();
          setTokens(data);
          // console.log(tokens)
      } catch (error) {
          console.error("Error fetching tokens:", error);
      } finally {
          // setLoading(false);
      }
    };
    // if (!tokens) {
      fetchTokens();
    // }
  }, []); // tokens


  const handleCoinClick = (coin) => {
    navigate(`/crypto/${coin.id}`, { state: { coin } });
  };

  return (
    <Container>
      {/*<Ticker>
         <TickerContent>
          <TickerInfoTest>
              24H TXN
              <PriceChange $isPositive={true}>
                34,983,829
              </PriceChange>
            </TickerInfoTest>
            <TickerInfoTest>
              24H VOL
              <PriceChange $isPositive={true}>
                $19.31B
              </PriceChange>
            </TickerInfoTest>
            <TickerInfoTest>
              MARKET
              <PriceChange $isPositive={true}>
                Greed
              </PriceChange>
            </TickerInfoTest>
            <TickerInfoTest>
              ETH GAS
              <PriceChange $isPositive={true}>
                2.5279 Gwei
              </PriceChange>
            </TickerInfoTest>
            <TickerInfoTest>
              SOL GAS
              <PriceChange $isPositive={true}>
                0.002 SOL
              </PriceChange>
            </TickerInfoTest>
            <TickerInfoTest>
              NETWORKS
              <PriceChange $isPositive={true}>
                205
              </PriceChange>
            </TickerInfoTest>
            <TickerInfoTest>
              DEXES
              <PriceChange $isPositive={true}>
                1,432
              </PriceChange>
            </TickerInfoTest>
            <TickerInfoTest>
              POOLS
              <PriceChange $isPositive={true}>
                6,365,484
              </PriceChange>
            </TickerInfoTest>
            <TickerInfoTest>
              TOKENS
              <PriceChange $isPositive={true}>
                5,563,575
              </PriceChange>
            </TickerInfoTest>
        </TickerContent> */}
        {/* <TickerGroupContainer> */}
          {/* <TickerGroup>
            <TickerInfo>
              24H TXN
              <PriceChange $isPositive={true}>
                34,983,829
              </PriceChange>
            </TickerInfo> */}
            {/* <TickerInfo>
              24H VOL
              <PriceChange $isPositive={true}>
                $19.31B
              </PriceChange>
            </TickerInfo>
            <TickerInfo>
              MARKET
              <PriceChange $isPositive={true}>
                Greed
              </PriceChange>
            </TickerInfo>
            <TickerInfo>
              ETH GAS
              <PriceChange $isPositive={true}>
                2.5279 Gwei
              </PriceChange>
            </TickerInfo>
            <TickerInfo>
              SOL GAS
              <PriceChange $isPositive={true}>
                0.002 SOL
              </PriceChange>
            </TickerInfo> */}
            {/* <TickerInfo>
              NETWORKS
              <PriceChange $isPositive={true}>
                205
              </PriceChange>
            </TickerInfo>
            <TickerInfo>
              DEXES
              <PriceChange $isPositive={true}>
                1,432
              </PriceChange>
            </TickerInfo>
            <TickerInfo>
              POOLS
              <PriceChange $isPositive={true}>
                6,365,484
              </PriceChange>
            </TickerInfo>
            <TickerInfo>
              TOKENS
              <PriceChange $isPositive={true}>
                5,563,575
              </PriceChange>
            </TickerInfo> */}
          {/* </TickerGroup> */}
        {/* </TickerGroupContainer> 
        <InputContainer $isMobile={isMobile}>
          <MessageInput
            type="text"
            placeholder="Enter coin name, ticker or address"
            // value={inputValue}
            // onChange={handleInputChange}
          />
          <SendButton
            // onClick={sendMessage}
          >
            <FaSearch />
          </SendButton>
        </InputContainer>
      </Ticker>*/}
      {/* <h2>Cryptocurrency Market</h2> */}
      <InfoCluster>
        <Ticker>
          <TickerMain>
            <TickerInfo>
              24H TXN
              <PriceChange $isPositive={true}>
                {/* <FaAngleUp /> */}
                34,983,829
              </PriceChange>
            </TickerInfo>
          </TickerMain>
          <TickerSearch $isMobile={isMobile}>
            <MessageInput
              type="text"
              placeholder="Enter coin name, ticker or address"
              // value={inputValue}
              // onChange={handleInputChange}
            />
            <SendButton
              // onClick={sendMessage}
            >
              <FaSearch />
            </SendButton>
          </TickerSearch>
          
        </Ticker>
        <CardContainer>
          <SubCardContainer>
            <SubCard>
              <CardText>
                <CardHeader>
                  $3,398,373,730,754
                </CardHeader>
                <CardSubTitle>
                  Market Cap
                </CardSubTitle>
              </CardText>
            </SubCard>
            <SubCard>
              <CardText>
                <CardHeader>
                  $246,666,925,414
                </CardHeader>
                <CardSubTitle>
                  24h Trading Volume
                </CardSubTitle>
              </CardText>
            </SubCard>
          </SubCardContainer>
          <Card>
            <CardText>🔥 Trending</CardText>
            <TokenList tokens={tokens} />
          </Card>
          <Card>
            {/* 🚀 Top Gainers */}
            <CardText>👀 Wallets</CardText>
            <WalletList>
              {walletAddresses.map(address => (
                <WalletItem key={address} to={`/crypto/wallet/${address}`}>
                  <WalletAddress>{address}</WalletAddress>
                </WalletItem>
              ))}
            </WalletList>
          </Card>
        </CardContainer>
      </InfoCluster>
      
      <TableWrapper>
      <CoinTable>
          <thead>
            <tr>
              <TableHeader>Coin</TableHeader>
              <TableHeader>Price</TableHeader>
              <TableHeader>24h Change</TableHeader>
              <TableHeader>Market Cap</TableHeader>
            </tr>
          </thead>
          <tbody>
            {coins.map(coin => (
              <CoinRow key={coin.id} onClick={() => handleCoinClick(coin)}>
                <TableCell>
                  <CellContent>
                    <CoinImage src={coin.image} alt={coin.name} />
                    <CoinName>{coin.name} ({coin.symbol.toUpperCase()})</CoinName>
                  </CellContent>
                </TableCell>
                <TableCell>${coin.current_price.toLocaleString()}</TableCell>
                <TableCell>
                  <PriceChange $isPositive={coin.price_change_percentage_24h > 0}>
                    {coin.price_change_percentage_24h > 0 ? <FaAngleUp /> : <FaAngleDown />}
                    {Math.abs(coin.price_change_percentage_24h).toFixed(2)}%
                  </PriceChange>
                </TableCell>
                <TableCell>{formatMarketCap(coin.market_cap)}</TableCell>
              </CoinRow>
            ))}
          </tbody>
      </CoinTable>
      </TableWrapper>
    </Container>
  );
};

export default CryptoPage;