import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { Link } from 'react-router-dom'; // useNavigate, 

const pages = [
  {
    title: "Welcome",
    blurb: "The world's best media app. It's free and secure.",
    logo: "🌎",
  },
  {
    title: "Real News",
    blurb: "We aggregate news, so you can read ad-free.",
    logo: "📰",
  },
  {
    title: "Your Music",
    blurb: "Upload music to the cloud.",
    logo: "🎧",
  },
  {
    title: "Secure Messaging",
    blurb: "Encrypted messaging, privacy is our priority.",
    logo: "🔒",
  },
  {
    title: "Real-Time Chat",
    blurb: "Video call friends and make new ones!",
    logo: "⚡️",
  },
  {
    title: "Get Started",
    blurb: "Join thousands of users today!",
    logo: "🚀",
  },
];

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  color: white;
  overflow: hidden;
  position: relative;
  padding: 0 1rem;  // Add padding to avoid text being cut off on small screens
`;

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  padding: 1rem 0;
`;

const Logo = styled(motion.div)`
  font-size: 4rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 6rem;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Blurb = styled.p`
  font-size: 1rem;
  max-width: 300px;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const shine = keyframes`
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background: linear-gradient(to right, rgb(13, 104, 210), rgb(40, 133, 242), rgb(13, 104, 210));
  background-size: 200%;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  animation: ${shine} 7s linear infinite;
  margin-top: 1rem;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
    font-size: 1.2rem;
  }
`;

const Indicators = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Indicator = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.$active ? '#007bff' : '#555')};
  cursor: pointer;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  ${(props) => props.direction === 'left' && 'left: 0.5rem;'}
  ${(props) => props.direction === 'right' && 'right: 0.5rem;'}
`;

function Onboarding() {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    trackMouse: true,
  });

  const handleNext = () => {
    setCurrentIndex((prev) => (prev < pages.length - 1 ? prev + 1 : prev));
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <Wrapper>
    <Container>
      <Container {...swipeHandlers}>
        <SlideContainer>
          <Logo
            key={currentIndex}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {pages[currentIndex].logo}
          </Logo>
          <Title>{pages[currentIndex].title}</Title>
          <Blurb>{pages[currentIndex].blurb}</Blurb>
        </SlideContainer>
      </Container>

      <Indicators>
        {pages.map((_, index) => (
          <Indicator
            key={index}
            $active={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </Indicators>

      <Link to="/register"> 
        <Button>
        Start Posting
        </Button>
      </Link>

      {/* Desktop Arrows */}
      <ArrowButton direction="left" onClick={handlePrev} style={{ display: currentIndex === 0 ? 'none' : 'block' }}>
        &lt;
      </ArrowButton>
      <ArrowButton direction="right" onClick={handleNext} style={{ display: currentIndex === pages.length - 1 ? 'none' : 'block' }}>
        &gt;
      </ArrowButton>
    </Container>
    </Wrapper>
  );
}

export default Onboarding;
