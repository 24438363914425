// MusicStyles.js
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  svg {
    color:rgb(0, 0, 0);
  }
`

export const TabContainer = styled.div`
  background-color: #27272a;
  border-radius: 8px;
  padding: 4px;
  display: inline-flex;
  // margin-bottom: 24px;
`;

export const Tab = styled.button`
  background-color: ${props => props.$active ? '#09090b' : 'transparent'};
  color: ${props => props.$active ? '#ffffff' : '#a1a1aa'};
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  // font-weight: 500;
  font-weight: ${props => props.$active ? '600' : '500'};
  transition: all 0.2s ease;

  &:hover {
    color: ${props => props.$active ? '#ffffff' : '#d4d4d8'};
  }

  &:focus {
    outline: none;
  }
`;

export const SongList = styled.div`
  // height: calc(100vh - 25vh);
  height: 50%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 20px;
  grid-auto-rows: min-content;
  overflow-y: hidden;
  overflow-x: hidden;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    height: calc(100vh - 32.5vh);
    display: block;
  }
`;

export const SongItem = styled.div`
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  //  text-align: left;
    margin-bottom: 15px;
  }
`;

export const SongThumbnail = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 5px;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 60px;
    height: 60px;
    padding-bottom: 0;
    float: left;
    margin-right: 15px;
  }
`;

export const ThumbnailImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SongInfo = styled.div`
  padding: 10px 0;

  // @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  //   padding: 0;
  // }
`;

export const SongTitle = styled.h3`
  margin: 0;
  font-size: 16px;
`;

export const SongArtist = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  color: #888;
`;

export const Section = styled.section`
  margin-bottom: 40px;
`;

export const PlaylistGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 20px;
  grid-auto-rows: min-content;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const PlaylistItem = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.03);
  }
`;

export const PlaylistThumbnail = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
`;

export const PlaylistName = styled.h3`
  margin: 10px 0 5px;
  font-size: 16px;
`;

export const SongCount = styled.p`
  margin: 0;
  font-size: 14px;
  color: #888;
`;

export const PlaylistCover = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

export const PlaylistInfo = styled.div``;

export const PlaylistTitle = styled.h1`
  margin: 0 0 10px;
  font-size: 36px;
`;

export const PlaylistDescription = styled.p`
  margin: 0 0 5px;
  font-size: 16px;
  color: #666;
`;

export const PlaylistStats = styled.p`
  margin: 0;
  font-size: 14px;
  color: #888;
`;